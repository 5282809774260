export default [
  {
    id: 'barba-bode',
    name: 'Barba-de-bode',
    nameLibras: 'https://youtu.be/5iIOUVi8aAY',
    thumb: require('../../entities/pampa/barba-bode_thumb.jpg'),
    images: [{
      id: 'barba-bode-1',
      description: 'Fotografia colorida de duas barbas-de-bode no canteiro de um jardim. A barba-de-bode é uma espécie de capim que cresce em tufos arredondados de cerca de 40 centímetros de altura. Suas folhas são bem finas e compridas com a base verde clara e as pontas rosadas.  As barbas-de-bode estão no centro da fotografia, uma em frente à outra, plantadas num canteiro de terra escura. Ao fundo, há alguns arbustos e um gramado verdinho.',
      src: require('../../entities/pampa/barba-bode_thumb.jpg')
    }],
    content: [
      {
        libras: 'https://youtu.be/sH86DE7DnWc',
        text: 'A barba-de-bode (nome científico: <i>Aristida jubata</i>) é da família das gramas e parece um mato alto, que pode atingir 40 centímetros de altura. Por ser da família das gramas, a barba-de-bode não possui nem flor nem fruto. Essa planta cresce em forma de tufos e consegue habitar diferentes lugares e temperaturas. Por isso, pode ser considerada invasora, pois pode ocupar o lugar de outra planta, já que nasce e se desenvolve muito facilmente. Apesar disso, ela é considerada uma planta boa para decorações urbanas e artesanatos indígenas, além de possuir algumas propriedades medicinais.'
      }
    ]
  },
  {
    id: 'algarrobo',
    name: 'Algarrobo',
    nameLibras: 'https://youtu.be/xP_Xb3Zfwj0',
    thumb: require('../../entities/pampa/algarrobo_thumb.jpg'),
    images: [{
      id: 'algarrobo-1',
      description: 'Fotografia colorida de galhos de um algarrobo cheio de frutos. Os galhos do algarrobo são irregulares e se ramificam em galhos mais finos. Suas folhas são verdes, pequenas e ovaladas e estão enfileiradas lado a lado. Seus frutos estão no centro da fotografia e pendem dos galhos. Eles são vagens compridas e achatadas de cerca de 6 centímetros de comprimento, com algumas partes marrom escuras e outras amarelas.',
      src: require('../../entities/pampa/algarrobo_thumb.jpg')
    }],
    content: [
      {
        libras: 'https://youtu.be/jXeM1lVNt5A',
        text: 'O algarrobo  (nome científico: <i>Prosopis algarobilla</i>)  é uma árvore da família do feijão e das ervilhas. Ela possui frutos em formato de vagens alongadas com uma polpa adocicada, que fica marrom quando amadurece, liberando assim cerca de 20 a 30 sementes que se espalham pelo chão. As flores dessa árvore são muitas e pequenas, amareladas e unidas como um cacho. A planta cresce em solos próximos de lagos, rios e chegam até mais ou menos 6 metros de altura. Sua madeira pode ser usada na construção civil e mobiliário. De sua casca, pode ser retirada tinta para dar cor à lã, algodão ou seda. Seus frutos são comidas para o gado e outros animais que vivem no bioma.'
      }
    ]
  }
]
