export default
{
  id: 'tale-cerrado',
  name: 'Conto do Cerrado',
  nameLibras: 'https://youtu.be/BnCKf50_k7c',
  images: [],
  audioSrc: require('../../../sounds/tales/cerrado.mp3'),
  videoSrc: 'https://www.youtube.com/embed/jMh7mJygvYI',
  content: [
    {
      code: 'ce-01',
      libras: 'https://youtu.be/BnohEuJNShg',
      text: '- Cheguei ao cerrado. Por onde começo?<br><br>- Iara, para a semente encontrar, um medo deve ajudar a superar.<br><br>Olhei ao redor e vi algo brilhando dentro de uma flor de ipê-amarelo. Ela caiu com o vento bem perto de mim. <br><br>- Nossa, será que é a semente? Mas eu não tenho medo de flores...'
    },
    {
      code: 'ce-02',
      libras: 'https://vimeo.com/76287360',
      text: 'Peguei a flor do chão e olhei dentro dela. Vi que, na verdade, não tinha semente lá. O brilho era uma gota de água com o reflexo do sol.<br><br>- Poxa, não é o que estou procurando. Mas essa flor é muito bonita! Nunca tinha visto uma árvore toda amarela.<br><br>Coloquei a flor no meu cabelo para enfeitar e continuei procurando algo brilhante. Foi quando vi atrás do arbusto uma cauda laranja balançando.'
    },
    {
      audioDescription: 'teste',
      style: {
        maxHeight: '600px',
        maxWidth: '600px'
      },
      image: 'https://iaraapp.sirv.com/iara/contos/cerrado/iara-ipe.png?w=600&h=600&format=webp'
    },
    {
      code: 'ce-03',
      libras: 'https://youtu.be/VoJXYr_vQwY',
      text: '- Oi? Tem alguém aí?<br><br>Fui andando para chegar mais perto, e então vi a cauda se movendo para trás de outro arbusto mais longe de mim.<br><br>- Ei! Por que você está fugindo de mim? Não precisa ter medo...<br><br>- Por que não deveria ter medo de você? Humanos só entram aqui na floresta para destruir minha casa...<br><br>- Eu nunca te faria mal. Fui ensinada a sempre respeitar a todos.<br><br>O animal continuava atrás do arbusto, sem se mexer. Achei melhor não me aproximar mais para não assustá-la. Sentei-me onde estava e comecei a conversar com ela.'
    },
    {
      code: 'ce-04',
      libras: 'https://youtu.be/XunNmLeEFpk',
      text: '- Bom, para tentar ganhar sua confiança, vou falar um pouco de mim. Sou a Iara, moro na Amazônia, e estou aqui bem longe da minha casa para procurar as sementes mágicas que dão poder a uma árvore especial da minha aldeia. Ela foi cortada por alguns homens da cidade.<br><br>- Nossa! Eles também invadem lá?<br><br>- Sim. Eles desmatam nossa terra, pegam nossos alimentos e não nos respeitam.<br><br>- E você não tem medo deles?<br><br>- Não, pois sei que nem todos são iguais. Eu não sou igual a eles por exemplo, apesar de ser humana também.<br><br>- Me desculpe por te julgar antes de te conhecer.<br><br>- Está tudo bem!'
    },
    {
      code: 'ce-05',
      libras: 'https://youtu.be/fKeTVkrz4FA',
      text: 'Então, ela saiu devagarinho de trás do arbusto e se mostrou uma linda lobo-guará.<br><br>- Nossa! Como você é bonita! Seu pelo é tão brilhante!<br><br>- Obrigada. Mas recentemente tem algo nele que está me incomodando.<br><br>- Quer que eu dê uma olhada para você?<br><br>A loba, com seu jeito tímido, deixou. Olhei o pelo dela mais de perto e vi algo brilhando.<br><br>- Achei! É a semente que estou procurando!<br><br>Peguei a semente entre os pelos dela com muito cuidado para não machucar a loba e mostrei para ela.<br><br>- Puxa, que bonito! E o incômodo passou! Fico feliz por você ter encontrado o que estava procurando.<br><br>- Eu que estou feliz por fazer uma nova amiga.<br><br>'
    },
    {
      audioDescription: 'teste',
      style: {
        maxHeight: '600px',
        maxWidth: '600px'
      },
      image: 'https://iaraapp.sirv.com/iara/contos/cerrado/iara-lobo.png?w=600&h=600&format=webp'
    }
  ]
}
