import React from 'react'
import styled from 'styled-components'
import { Colors } from '../styles'
import { connect } from 'react-redux'
import { changePlayerVideo } from '../actions/LibrasPlayerActions'
import { AccessibilityType } from '../services/accessibilityType'

function LibrasAccessible ({ accessibility }) {
  const isDeaf = accessibility.type === AccessibilityType.libras

  if (!isDeaf) return null

  return <Icon src={require('./../assets/specialResources/libras-hand.png')} />
}

const mapStateToProps = state => ({
  accessibility: state.AccessibilityReducer
})

export default connect(mapStateToProps, { changePlayerVideo })(LibrasAccessible)

const Icon = styled.img`
  height: 30px;
  box-shadow: 0px 10px 20px -8px rgba(0,0,0,0.75);
  border-style: groove;
  border-radius: 50%;
  color: ${Colors.white};
  background: ${Colors.libras};
  padding: 7px;
  cursor: ${props => props.clickable ? 'pointer' : 'normal'};
  position: fixed;
  bottom: 15px;
  left: 15px;
  z-index: 10000;
`
