export default
{
  id: 'tale-caatinga',
  name: 'Conto da Caatinga',
  nameLibras: 'https://youtu.be/cQmSfY1tNCw',
  images: [],
  audioSrc: require('../../../sounds/tales/caatinga.mp3'),
  videoSrc: 'https://www.youtube.com/embed/H84IkGXdZl4',
  content: [
    {
      code: 'caa-01',
      libras: 'https://youtu.be/YiVb3Y3kTjw',
      text: '- E agora?<br><br>- Iara, para a semente encontrar, você deverá cavar.'
    },
    {
      code: 'caa-02',
      libras: 'https://youtu.be/RZjyojdAoRY',
      text: 'Olhei em volta e vi que quase tudo ao meu redor eram terra e plantas pequenas, não sabia por onde começar. Eu estava com muito calor, então procurei um cantinho na sombra para me sentar e pensar onde eu ia procurar. A única sombra que achei foi a de um mandacaru alto e cheio de espinhos afiados. Sentei-me embaixo do cacto, tomando cuidado para não me furar.'
    },
    {
      audioDescription: 'teste',
      style: {
        maxHeight: '600px',
        maxWidth: '600px'
      },
      image: 'https://iaraapp.sirv.com/iara/contos/caatinga/iara-cacto.png?w=600&h=600&format=webp'
    },
    {
      code: 'caa-03',
      libras: 'https://youtu.be/DvzNIaD_Y2s',
      text: 'Dei uma olhada no chão e vi um buraco na terra.<br><br>- Quem será que cavou aqui?<br><br>Curiosa, me aproximei mais e vi um leve brilho vindo de lá.<br><br>- Só pode ser a semente!<br><br>Animada, comecei a cavar com as minhas mãos, mas elas começaram a doer. Foi quando um tatu-peba surgiu onde eu estava cavando, me dando um susto.'
    },
    {
      code: 'caa-04',
      libras: 'https://youtu.be/BK5G1o0GPA0',
      text: '- O que está fazendo destruindo minha casa?<br><br>- Desculpa, senhora tatu. Não sabia que tinha alguém morando aqui.<br><br>- Eu moro, cavo túneis aqui e esta é a minha casa. O que você está tentando fazer? Acho que você não mora embaixo da terra como eu...<br><br>- Não moro, é que eu estou procurando uma semente que pode restaurar a magia do meu povo. Acho que a vi aqui embaixo e comecei a cavar para ver se encontro.<br><br>- Ora, nesse caso eu te ajudo! Minhas unhas são próprias para isso, eu nasci para cavar. Conheço essas terras muito bem, e sei o caminho para não destruirmos minha casa.'
    },
    {
      audioDescription: 'teste',
      style: {
        maxHeight: '600px',
        maxWidth: '600px'
      },
      image: 'https://iaraapp.sirv.com/iara/contos/caatinga/iara-tatu.png?w=600&h=600&format=webp'
    },
    {
      code: 'caa-05',
      libras: 'https://youtu.be/hNY0QejmXoU',
      text: 'Começamos então a cavar juntas. Com a tatu me mostrando onde cavar, fizemos um túnel bem grande. Lá no fundo, achamos a semente que eu procurava.<br><br>-  Muito obrigada, senhora tatu!<br><br>- De nada, de nada querida… E viu… Já que eu te ajudei, será que você poderia me ajudar também?<br><br>- Claro! O que posso fazer por você?<br><br>- Lá do outro lado da estrada tem folhas muito gostosas que eu adoraria comer! Mas depois que os homens da cidade construíram essa estrada, ficou muito perigoso para ir até lá. Quando eu tentei atravessar, um carro me acertou, e machuquei muito minha patinha. Desde então, não consigo andar como antes. Você pode me levar até lá?'
    },
    {
      code: 'caa-16',
      libras: 'https://youtu.be/awSN55gNVu8',
      text: 'Olhei para os lados e não paravam de passar carros muito rápidos. Não era uma boa ideia atravessar daquela forma, precisava pensar em outro jeito. Comecei a procurar algo que poderia nos ajudar. Vi que um pouco mais para o lado havia uma ponte. Andei até lá com a senhora Tatu, mas quando chegamos lá, ela disse:<br><br>- Os degraus são muito altos para mim, eu não consigo subir.<br><br>- Não tem problema, eu te carrego!<br><br>A senhora tatu se encolheu em forma de bola e eu a peguei no colo para atravessar a ponte. Esperei-a pegar a comida e voltamos em segurança para a casa dela.<br><br>- Muito obrigada, menina!<br><br>- De nada! Agora estou mais perto de poder restaurar a magia do meu povo.'
    }
  ]
}
