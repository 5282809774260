export default
{
  id: 'tale-amazonia',
  name: 'Conto da Amazônia',
  nameLibras: 'https://youtu.be/MOGkXbkLmbc',
  images: [],
  audioSrc: require('../../../sounds/tales/amazonia.mp3'),
  videoSrc: 'https://www.youtube.com/embed/30JQ4iM1a8s',
  content: [
    {
      code: 'am-01',
      libras: 'https://www.youtube.com/watch?v=FbJz5cP-K3Q',
      text: '- A Amazônia é enorme! Por onde começo a procurar?<br><br>- Iara, para a semente encontrar, na água deve entrar.'
    },
    {
      code: 'am-02',
      libras: 'https://www.youtube.com/watch?v=yT0Vm496iok',
      text: 'Escutei a voz falando comigo. Olhei para os lados e vi um rio com muitas vitórias-régias por cima da água. Comecei a pular em cima delas para procurar a semente. Assim, fui me afastando cada vez mais da beira do rio, até que vi algo brilhante no fundo da água.<br><br>- Será que é a semente?'
    },
    {
      audioDescription: 'teste',
      style: {
        maxHeight: '600px',
        maxWidth: '600px'
      },
      image: 'https://iaraapp.sirv.com/iara/contos/amazonia/iara-vitoria-regia.png?w=600&h=600&format=webp'
    },
    {
      code: 'am-03',
      libras: 'https://www.youtube.com/watch?v=wwxDfs0edtE',
      text: 'Mergulhei no rio, mas o brilho vinha muito do fundo, e não consegui alcançar. Olhei para os lados buscando algo que pudesse me ajudar. Foi quando escutei o chamado do boto-cor-de-rosa, e então nadei até ele.  Ele estava preso em uma rede de pesca, com uma cara de assustado. Seu corpo se debatia para tentar se soltar, mas não conseguia. Procurando uma maneira de ajudar, vi que tinha uma pedra segurando a rede, e nadei o mais rápido que pude para tirar ela de cima. O boto conseguiu se soltar e juntos fomos até a superfície do rio e conseguimos finalmente respirar.'
    },
    {
      code: 'am-04',
      libras: 'https://www.youtube.com/watch?v=A1ihXtnsjaY',
      text: '- O que aconteceu com você lá embaixo?<br><br>- Eu estava indo encontrar a minha família do outro lado do rio e fiquei preso. Muito obrigado por me ajudar!<br><br>- De nada! Mas posso te fazer uma perguntinha?<br><br>- Claro.<br><br>- Por que você só respirou aqui fora da água? Não consegue respirar como os peixes?<br><br>- Não, eu preciso sair da água para respirar, assim como você.<br><br>'
    },
    {
      code: 'am-05',
      libras: 'https://www.youtube.com/watch?v=2rItyCJEZoM',
      text: '- Não sabia disso! Mas você consegue prender a respiração por muuuito tempo, né? Eu não consigo...<br><br>- Sim. Mas se você não consegue, por que estava nadando tão fundo? É perigoso...<br><br>- Eu estou numa missão e preciso encontrar várias sementes mágicas ao redor do Brasil para salvar a minha comunidade. Achei que tivesse visto uma bem lá no fundo. Já sei! Você me ajuda a ver se é ela mesmo? Ouvi dizer que os botos são bons mergulhadores, é verdade?'
    },
    {
      code: 'am-06',
      libras: 'https://www.youtube.com/watch?v=QwMJYjHgF70',
      text: '- Nós somos sim, e claro que eu te ajudo! Segura em mim que eu te levo até lá.<br><br>Fomos nadando juntos até a semente brilhante e, com a ajuda do boto, consegui pegar ela.<br><br>Subimos para a superfície novamente.<br><br>- Muito obrigada pela ajuda! Eu não teria conseguido sem você.<br><br>- Imagina! Foi bom te conhecer! Espero que você encontre todas as sementes na sua missão e consiga salvar o seu povo.<br><br>- E eu espero que você encontre sua família.<br><br>Nos despedimos e fui embora com a sensação de missão cumprida e com um novo amigo.'
    },
    {
      audioDescription: 'dummy image',
      style: {
        maxHeight: '600px',
        maxWidth: '600px'
      },
      image: 'https://iaraapp.sirv.com/iara/contos/amazonia/iara-boto.png?w=600&h=600&format=webp'
    }
  ]
}
