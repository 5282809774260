export default [
  {
    id: 'prea',
    name: 'Preá',
    nameLibras: 'https://youtu.be/K6YF3CSUVJA',
    thumb: require('../../entities/caatinga/prea_thumb.jpg'),
    images: [{
      id: 'boto-1',
      description: 'Fotografia colorida de um preá em meio à mata. O preá é um animal que se parece com um ratinho. Seu o corpo é arredondado e mede cerca de 30 centímetros de comprimento. Seus pelos são marrons e macios. Ele tem orelhas arredondadas, olhos ovalados pretos e nariz redondo também preto. O preá está no centro da foto, virado para a direita, sobre um chão coberto de vegetação rasteira bem verdinha.',
      src: require('../../entities/caatinga/prea_thumb.jpg')
    }],
    audioSrc: require('../../sounds/prea.mp3'),
    content: [
      {
        libras: 'https://youtu.be/vqmRUjeWHmM',
        text: 'O preá (nome científico: <i>Cavia aperea</i>) é parecido com um ratinho e ele vive cerca de 3 anos. Seu tamanho é de aproximadamente 30 centímetros, e ele pertence à família das capivaras. Eles vivem em bando entre moitas e próximo dos riachos, e conseguem nadar e correr muito bem, evitando o encontro com seus predadores, como cobras, cães e felinos.'
      },
      {
        libras: 'https://youtu.be/RRDUKBzeSe0',
        text: 'Seu pelo é grosso e denso, geralmente castanho. Assim como o rato, o preá é um roedor que possui dois dentes grandes para frente que o ajudam a se alimentar de capim, grãos e folhas.'
      },
      {
        libras: 'https://youtu.be/KW2wOHfrVPc',
        text: 'Os preás se comunicam por assobios. São raros de serem encontrados, mas não correm risco de extinção por enquanto.'
      }
    ]
  },
  {
    id: 'veado',
    name: 'Veado-catingueiro',
    nameLibras: 'https://youtu.be/luyTk7TJJMk',
    thumb: require('../../entities/caatinga/veado_thumb.jpg'),
    images: [{
      id: 'veado-1',
      description: 'Fotografia colorida de um veado-catingueiro numa campina. O veado é um animal que lembra uma rena de estatura menor e mais esguia. Ele tem cerca de 1 metro de comprimento e suas patas terminam em cascos. Seu corpo é coberto por pelos beges e macios. Suas orelhas são grandes e arredondadas e seus olhos e nariz, também arredondados, tem a cor preta. No alto da sua cabeça, há um par de pequenos chifres. O veado está no centro da fotografia, em meio a um campo coberto de gramas altas e secas.',
      src: require('../../entities/caatinga/veado_thumb.jpg')
    }],
    audioSrc: require('../../sounds/veado.mp3'),
    content: [
      {
        libras: 'https://youtu.be/UqRJGBwQBqY',
        text: 'O veado-catingueiro (nome científico: <i>Mazama gouazoubira</i>) é um animal com hábitos diurnos. Ou seja, assim como nós, ele fica acordado de dia, e dorme durante a noite. É terrestre e vive sozinho ou em pares em vegetações baixas onde podem se abrigar e se alimentar de frutos, gramas, arbustos e flores. Podem viver em média durante 20 anos.'
      },
      {
        libras: 'https://youtu.be/BTMCXFqt_fw',
        text: 'O veado-catingueiro mede cerca de 1 metro de comprimento e seu pelo varia da cor avermelhada para acinzentada, com partes brancas na barriga e nas orelhas. Além disso, possui uma mancha branca acima dos olhos, que é característico da espécie. O veado também possui dois chifres que variam de 5 até 15 centímetros.'
      },
      {
        libras: 'https://youtu.be/FBS7YAXozz0',
        text: 'É uma espécie ameaçada de extinção devido à caça e também à febre aftosa. A febre aftosa é uma doença que os veados contraem quando ficam próximos dos gados doentes das fazendas. Essa doença faz com que eles sintam febre e tenham aftas na boca e nos seus cascos dos pés.'
      },
      {
        libras: 'https://youtu.be/B3No5acsIpM',
        text: 'Você sabia que os veados são muito inteligentes? Eles são capazes de enganar e despistar seus predadores. Isso porque, quando são perseguidos por eles, passam propositalmente no meio de animais domésticos como gados e galinhas, misturando seu cheiro com esses animais e confundindo seus predadores.'
      }
    ]
  },
  {
    id: 'tatu-peba',
    name: 'Tatu-peba',
    nameLibras: 'https://youtu.be/AAj-59KIeTc',
    thumb: require('../../entities/caatinga/tatu-peba_thumb.jpg'),
    images: [{
      id: 'tatu-peba-1',
      description: 'Fotografia colorida de um tatu-peba cavando um buraco. O tatu-peba é um animal que lembra um rato grande com o corpo protegido por uma carapaça. Essa carapaça é formada por várias plaquinhas e tem alguns poucos pelos compridos e ásperos. O tatu mede cerca de meio metro de comprimento e é amarronzado. Ele tem a cabeça pequena, orelhas finas e redondas, olhos bem pequenos e pretos e focinho alongado. Suas patas curtas têm unhas compridas e sua cauda pontuda e também comprida sai debaixo da carapaça. O tatu está no centro da fotografia, voltado para a esquerda, no meio de um buraco raso na terra avermelhada.',
      src: require('../../entities/caatinga/tatu-peba_thumb.jpg')
    }],
    audioSrc: require('../../sounds/tatu-peba.mp3'),
    content: [
      {
        libras: 'https://youtu.be/YSSaAMSNksM',
        text: 'O tatu-peba (nome científico: <i>Euphractus sexcinctus</i>) geralmente anda sozinho durante o dia e em regiões com mato baixo. Quando precisam, cavam buracos na terra para se esconder dos predadores. Ele tem coloração marrom e uma carapaça flexível com poucos pelos. Sua cabeça é em forma de cone (como uma casquinha de sorvete) e possui 5 dedos em cada pata, como nós. Ele come insetos e folhas, e alguns deles foram até vistos comendo carne de animais mortos. Ele possui cerca de meio metro de comprimento e vive cerca de 20 anos.'
      },
      {
        libras: 'https://youtu.be/LB5027GZ1B8',
        text: 'O tatu-peba é um animal que, por enquanto, não está em risco de extinção e, por isso, é comum de ser visto. Mesmo assim, com o desmatamento, o tatu precisa atravessar estradas para ir de um lado até o outro da mata e, por isso, muitas vezes é atropelado e caçado, sendo essas as principais causas da morte desses animais.'
      }
    ]
  }
]
