import finalQuiz from '../assets/quiz/final'
import amazonia from '../assets/quiz/amazonia'
import caatinga from '../assets/quiz/caatinga'
import cerrado from '../assets/quiz/cerrado'
import mataAtlantica from '../assets/quiz/mataAtlantica'
import pampa from '../assets/quiz/pampa'
import pantanal from '../assets/quiz/pantanal'

import AsyncStorage from '@callstack/async-storage'

const map = {
  final: finalQuiz,
  amazonia,
  caatinga,
  cerrado,
  'mata-atlantica': mataAtlantica,
  pampa,
  pantanal
}

export function getQuizzes () {
  return map
}

export function getQuizById (quizId) {
  const quiz = map[quizId]

  if (!quiz) {
    throw new Error('Quiz não encontrado: ' + quizId)
  }

  return quiz
}

const QuizStatus = {
  done: 'done'
}

export function getQuestionById (quizData, questionId) {
  return quizData.questions.find(({ id }) => id === questionId)
}

export function getAlternativeById (question, alternativeId) {
  return question.alternatives.find(({ id }) => id === alternativeId)
}

export const saveQuizQuestion = (quizId, questionId) => {
  return AsyncStorage.setItem(`${quizId}:${questionId}`, QuizStatus.done)
}

export const isQuestionDone = (quizId, questionId) => {
  return AsyncStorage.getItem(`${quizId}:${questionId}`).then(result => {
    return result
  })
}

export const isLastQuestion = (quizId, questionId) => {
  return questionId === getQuizById(quizId).questions.length
}

export const isAlternativeOneOfTheCorrectOnes = (correctAlternatives, alternativeId) => {
  return correctAlternatives.includes(alternativeId)
}

export const isQuizCompleted = async (quizId) => {
  const questionIds = getQuizById(quizId).questions.map(question => question.id)
  const questionsStatus = await Promise.all(questionIds.map(id => isQuestionDone(quizId, id)))
  const isCompleted = questionsStatus.every(status => status)
  return isCompleted
}
