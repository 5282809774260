export default {
  amazonia: {
    animals: require('./amazonia/animals').default,
    plants: require('./amazonia/plants').default
  },
  caatinga: {
    animals: require('./caatinga/animals').default,
    plants: require('./caatinga/plants').default
  },
  cerrado: {
    animals: require('./cerrado/animals').default,
    plants: require('./cerrado/plants').default
  },
  'mata-atlantica': {
    animals: require('./mata-atlantica/animals').default,
    plants: require('./mata-atlantica/plants').default
  },
  pantanal: {
    animals: require('./pantanal/animals').default,
    plants: require('./pantanal/plants').default
  },
  pampa: {
    animals: require('./pampa/animals').default,
    plants: require('./pampa/plants').default
  }
}
