import * as wrongAnimation from '../assets/lottie/wrong.json'
import * as correctAnimation from '../assets/lottie/correct.json'
import * as selectAnimation from '../assets/lottie/select.json'
import * as congratsAnimation from '../assets/lottie/congrats.json'
import * as sadAnimation from '../assets/lottie/sad.json'

export const Animations = {
  congrats: {
    size: 200,
    margin: 0,
    loop: true,
    src: congratsAnimation.default
  },
  correct: {
    size: 200,
    margin: 0,
    loop: false,
    src: correctAnimation.default
  },
  wrong: {
    size: 200,
    margin: 0,
    loop: false,
    src: wrongAnimation.default
  },
  mustSelect: {
    size: 200,
    margin: 0,
    loop: false,
    src: selectAnimation.default
  },
  sad: {
    size: 200,
    margin: 0,
    loop: true,
    src: sadAnimation.default
  }
}
