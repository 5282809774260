// README> QUESTION IDS MUST BE SEQUENTIALS

export default {
  id: 'caatinga',
  questions: [
    {
      id: 1,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/byALDx3o7ro',
      text: 'Sobre o bioma da Caatinga, escolha a alternativa correta:',
      correctAnswer: [1],
      alternatives: [
        {
          id: 1,
          libras: 'https://youtu.be/ycrrcdD5Hd8',
          text: 'Na Caatinga tem muitas plantas adaptadas ao clima seco'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/3L_-v1ROKa8',
            text: 'A Caatinga e o deserto não são iguais porque o clima é diferente, sendo que na Caatinga chove um pouco mais.'
          },
          libras: 'https://youtu.be/DyZoGjs0sSc',
          text: 'A Caatinga é igual ao deserto africano'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/I-Lsu1ru3Ck',
            text: 'Na Caatinga, as árvores são baixas e os troncos tortos.'
          },
          libras: 'https://youtu.be/v8LCC5RNj4g',
          text: 'Na Caatinga as árvores são muito altas com troncos retos'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/SiootCjldN4',
            text: 'A Caatinga fica na região Nordeste do Brasil.'
          },
          libras: 'https://youtu.be/kKUYDAiSqkE',
          text: 'A Caatinga fica na Região Sul do Brasil'
        }
      ]
    },
    {
      id: 2,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/-nyomM3rlA4',
      text: 'Sobre os animais da Caatinga, escolha a alternativa correta:',
      correctAnswer: [1],
      alternatives: [
        {
          id: 1,
          libras: 'https://youtu.be/Fg2SQWkDeD4',
          text: 'Todos os animais da Caatinga (tatu-peba, veado-catingueiro e preá) comem folhas'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/LA5hJ12VWhc',
            text: 'O tatu-peba já foi visto comendo carne de animais mortos.'
          },
          libras: 'https://youtu.be/L_F7Vpq3OZo',
          text: 'O veado-catingueiro já foi visto comendo carne de animais mortos'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/FxpN8gV2Mow',
            text: 'Os preás é que são da família dos roedores.'
          },
          libras: 'https://youtu.be/2bmdQMhLqcc',
          text: 'O tatu-peba é da família dos roedores'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/6qGkacbrmB8',
            text: 'As cobras que se alimentam dos preás.'
          },
          libras: 'https://youtu.be/Hqenqkwx5Jo',
          text: 'O preá se alimenta de cobras'
        }
      ]
    },
    {
      id: 3,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/O3k1i1qpOwU',
      text: 'Sobre as plantas da Caatinga, escolha a alternativa correta:',
      correctAnswer: [4],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/p5m310GKBQk',
            text: 'Ambas as plantas possuem espinhos, sendo essa uma adaptação ao clima seco.'
          },
          libras: 'https://youtu.be/IySIqCdxlT8',
          text: 'O juazeiro e o mandacaru não possuem espinhos'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/0FnJ2IAmkao',
            text: 'A flor do mandacaru floresce uma única vez ao ano e geralmente dura apenas um dia.'
          },
          libras: 'https://youtu.be/1cMlkVwRoxY',
          text: 'A flor do mandacaru é muito bonita e permanece florida na primavera toda'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/Np6DUvZyu-A',
            text: 'O juazeiro consegue manter as folhas verdes mesmo no período de seca, por causa das raízes profundas que absorvem água do solo.'
          },
          libras: 'https://youtu.be/h919TskQaYo',
          text: 'Na estação seca, o juazeiro perde suas folhas'
        },
        {
          id: 4,
          libras: 'https://youtu.be/Qbsc0SXpZBQ',
          text: 'Os espinhos são estruturas que impedem a perda de água, uma adaptação para a seca da Caatinga'
        }
      ]
    }
  ]
}
