
import preservacao from '../assets/data/infos/preservacao'
import bioma from '../assets/data/infos/o-que-e-um-bioma'
import taleAmazonia from '../assets/data/infos/contos/tale-amazonia'
import taleCaatinga from '../assets/data/infos/contos/tale-caatinga'
import taleCerrado from '../assets/data/infos/contos/tale-cerrado'
import taleInicial from '../assets/data/infos/contos/tale-inicial'
import taleFinal from '../assets/data/infos/contos/tale-final'
import taleMataAtlantica from '../assets/data/infos/contos/tale-mataAtlantica'
import talePampa from '../assets/data/infos/contos/tale-pampa'
import talePantanal from '../assets/data/infos/contos/tale-pantanal'

const info = [
  preservacao,
  bioma,
  taleAmazonia,
  taleCaatinga,
  taleCerrado,
  taleInicial,
  taleFinal,
  taleMataAtlantica,
  talePampa,
  talePantanal
]

export function getInfoById (infoId) {
  return info.find(({ id }) => id === infoId)
}
