import React, { useState, useEffect } from 'react'
import { RoundedButton, Popup, Card, Accessible } from '../../components'
import { Container, Version, Logo, getAccessibleCardImageStyle, CardList, PopupTitle, PopupText, PlayButton, getAccessibleCardStyle, PopupRowContainer } from './styles'
import { version } from '../../../package.json'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { setConfiguration, Col } from 'react-grid-system'
import { MdAccessibility } from 'react-icons/md'
import { TiInfoLarge } from 'react-icons/ti'
import { BsFillPlayFill } from 'react-icons/bs'
import { AccessibilityType, saveAccessibilityType } from '../../services/accessibilityType'
import { connect } from 'react-redux'
import { changeAccessibilityType } from '../../actions/AccessibilityActions'
import { useHistory } from 'react-router-dom'

function Home ({ changeAccessibilityType, accessibility }) {
  const [buttonTabIndex, setTabIndex] = useState(1)
  const [popupVisible, setPopupVisible] = useState(false)
  const windowDimensions = useWindowDimensions()
  const [shouldRedirectToPlay, redirectToPlay] = useState(false)
  setConfiguration({ gutterWidth: 25 })

  useEffect(() => {
    saveAccessibilityType(accessibility.type).then(() => {
      if (shouldRedirectToPlay) {
        history.push('/biomas')
      }
    })
  }, [accessibility.type])

  function renderPopup () {
    return (
      <Popup
        style={{ paddingBottom: 0 }}
        width='90%'
        visible={popupVisible}
      >
        <Accessible
          libras='https://youtu.be/FJwf-DNlaAc'
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <PopupTitle>Selecione o recurso necessário para acessar o conteúdo</PopupTitle>
        </Accessible>
        <PopupText>Sua experiência será de acordo com o que você deseja</PopupText>
        <PopupRowContainer windowDimensions={windowDimensions}>
          <CardList>
            <Col sm={12} md={4}>
              <Card
                buttonDescription='Selecionar audiodescrição'
                cardStyle={getAccessibleCardStyle()}
                onClick={() => handleAccessibilityChoice(AccessibilityType.audioDescription)}
                thumb={require('../../assets/specialResources/headphone.png')}
                title='Audiodescrição' bottom accessible={false}
                imageStyle={getAccessibleCardImageStyle(windowDimensions)}
              />
            </Col>
            <Col sm={12} md={4}>
              <Card
                buttonDescription='Selecionar LIBRAS'
                cardStyle={getAccessibleCardStyle()}
                onClick={() => handleAccessibilityChoice(AccessibilityType.libras)}
                thumb={require('../../assets/specialResources/libras.png')}
                title='Libras' bottom accessible={false}
                imageStyle={getAccessibleCardImageStyle(windowDimensions)}
              />
            </Col>
            <Col sm={12} md={4}>
              <Card
                buttonDescription='Selecionar texto e áudio'
                cardStyle={getAccessibleCardStyle()}
                thumb={require('../../assets/specialResources/default.png')}
                onClick={() => handleAccessibilityChoice(AccessibilityType.none)}
                title='Texto e áudio' bottom accessible={false}
                imageStyle={getAccessibleCardImageStyle(windowDimensions)}
              />
            </Col>
          </CardList>
        </PopupRowContainer>
      </Popup>
    )
  }

  const history = useHistory()

  function handleAccessibilityChoice (type) {
    closePopup()
    changeAccessibilityType(type)
  }

  function play () {
    if (accessibility.type === null) { // not checking for undefined
      openPopup()
      redirectToPlay(true)
    } else {
      history.push('/biomas')
    }
  }

  function openPopup () {
    setPopupVisible(true)
    setTabIndex(-1)
  }

  function closePopup () {
    setPopupVisible(false)
    setTabIndex(1)
  }

  return (
    <>
      {renderPopup()}
      <Container>
        <Logo draggable='false' alt='Logo' src={require('./../../assets/iara/iara.png')} />
        <PlayButton
          description='Iniciar exploração'
          tabindex={buttonTabIndex.toString()}
          onClick={() => play()}
        >
          <BsFillPlayFill />
        </PlayButton>
        <RoundedButton
          description='Selecionar acessibilidade'
          tabindex={buttonTabIndex.toString()}
          onClick={() => openPopup()}
        >
          <MdAccessibility />
        </RoundedButton>
        <RoundedButton
          description='Conhecer mais sobre o projeto'
          tabindex={buttonTabIndex.toString()}
          onClick={() => { window.open('https://iara.app/sobre-nos', 'blank') }}
        >
          <TiInfoLarge />
        </RoundedButton>
        <Version>Versão {version}</Version>
        <Version>Using {accessibility.type || 'FIRST_TIME'} as accessibility</Version>
      </Container>
    </>
  )
}

const mapStateToProps = state => ({
  accessibility: state.AccessibilityReducer
})

export default connect(mapStateToProps, { changeAccessibilityType })(Home)
