import React from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { Card, Header } from '../../components'
import { CardList } from './styles'

import { getBiomeById, getEntitiesByType } from '../../services'
import { Row, Col } from 'react-grid-system'

const mapTypeToFile = {
  plantas: 'plants',
  animais: 'animals'
}

export default function BiomeEntities (props) {
  const history = useHistory()
  const params = useParams()
  const biomeId = params.biome.toString()
  const type = params.type.toString()

  if (!['plantas', 'animais'].includes(type)) {
    history.goBack()
    window.alert('Ops!')
    return null
  }

  const entities = getEntitiesByType(mapTypeToFile[type], biomeId)

  if (!entities) {
    history.goBack()
    window.alert('Esse bioma não tem dados de ' + type + ' ainda!')
    return null
  }

  const biome = getBiomeById(biomeId)
  if (!biome) {
    history.replace('/biomas')
    window.alert('Esse bioma ainda não tem nenhum dado!')
    return null
  }
  const { name } = biome

  function renderCard (entity) {
    const url = `/bioma/${biomeId}/${type}/${entity.id}`
    return (
      <Col
        sm={12} md={6} lg={4}
        key={entity.id}
      >
        <Card
          bottom
          thumb={entity.thumb}
          redirectButtonUrl={url}
          title={entity.name}
        />
      </Col>
    )
  }

  return (
    <>
      <Header>{name}</Header>
      <CardList fluid>
        <Row>
          {entities.map(entity => renderCard(entity))}
        </Row>
      </CardList>
    </>
  )
}
