import React, { useState } from 'react'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { Colors, Fonts } from '../styles'
import { RiCloseLine } from 'react-icons/ri'

export function Tooltip ({ visible = false, onClick, mobileMessage, desktopMessage, buttonText }) {
  const windowDimensions = useWindowDimensions()
  const [isDismissed, setDismissed] = useState(false)
  if (!visible) return null

  function dismiss () {
    setDismissed(true)
  }

  return (
    <Container dismissed={isDismissed} windowDimensions={windowDimensions}>
      <Description windowDimensions={windowDimensions}>
        <CloseButton onClick={() => dismiss()} />
        {isMobile ? (
          mobileMessage
        ) : (
          desktopMessage
        )}
      </Description>
      <InstallButton
        onClick={onClick}
      >
        {buttonText}
      </InstallButton>
    </Container>
  )
}

const CloseButton = styled(RiCloseLine)`
  font-size: 30px;
  line-height: 50px;
  margin-bottom: -9px;
  margin-right: 10px;
  opacity: 0.5;
  color: ${Colors.lightBlue};
  cursor: pointer;
  padding: 0;
`

const Description = styled.span`
  line-height: 50px;
  height: 50px;
  padding-left: 10px;
  font-size: ${({ windowDimensions }) => windowDimensions.width <= 1000 ? '14px' : '16px'};
  float: left;
  color: ${Colors.white};
  font-family: ${Fonts.regular};
  & > b {
    font-family: ${Fonts.regular};
  }
`

const InstallButton = styled.button`
  text-align: center;
  font-size: 14px;
  padding: 0;
  margin: 0;
  transition: 0.5s;
  cursor: pointer;
  margin-right: 10px;
  text-align: center;
  background: transparent;
  border-radius: 0;
  line-height: 50px;
  float: right;
  color: ${Colors.lightBlue};
  font-family: ${Fonts.regular};
  &:hover{
    filter: brightness(110%);
    transform: scale(1.2);
  }
`

const Container = styled.footer`
  display: ${({ dismissed }) => dismissed ? 'none' : ''};
  z-index: 200;
  bottom: 10px;
  border-radius: 10px;
  height: 50px;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
  padding: 0;
  background: red;
  margin: 0 auto;
  margin-left: 50%;
  transform: translateX(-50%);
  position: fixed;
  width: ${({ windowDimensions }) => windowDimensions.width <= 1000 ? '95vw' : '40vw'};
  background-color: ${Colors.black};
`
