import styled from 'styled-components'
import { Fonts, Colors } from '../../styles'
import { RoundedButton } from '../../components'
import { Row, Container as RowContainer } from 'react-grid-system'

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;

`

export const Version = styled.p`
  font-family: ${Fonts.regular};
  font-size: 12px;
  opacity: 0.3;
  color: blue;
`

export const Logo = styled.img`
  height: 250px;
  margin-bottom:30px;
`

export const PlayButton = styled(RoundedButton)`
  display: block;
  background: ${Colors.blue};
  border: 10px solid #fff;
  border-style:  solid;
  color: #fff;
  margin: 0 auto;
  height: 120px;
  width: 120px;
  padding: 15px 20px;
  font-size: 70px;
`

export const CardList = styled(Row)`
`

export const getAccessibleCardImageStyle = () => ({
  minHeight: 350,
  maxHeight: 350,
  zIndex: 0,
  objectFit: 'cover',
  display: 'inline-block',
  borderTopLeftRadius: 20,
  minWidth: '100%',
  maxWidth: '100%',
  marginBottom: 30
})

export const getAccessibleCardStyle = () => ({
  minWidth: 'fit-content',
  borderRadius: 20,
  borderTopLeftRadius: 20,
  margin: 0,
  minHeight: 350
})

export const PopupTitle = styled.h1`
  font-size: 25px;
  margin: 0 10px;
  padding: 15px 15px;
  width: fit-content;
`

export const PopupText = styled.p`
  font-size: 17px;
  padding: 10px;
  opacity: 0.6;
  padding-bottom: 30px;
`

export const PopupRowContainer = styled(RowContainer)`
  margin-bottom: ${({ windowDimensions }) => windowDimensions.width <= 1024 ? '80px' : ''};
`
