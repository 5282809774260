import AsyncStorage from '@callstack/async-storage'

export const AccessibilityType = {
  audioDescription: 'audioDescription',
  libras: 'libras',
  none: 'none'
}

export const getCurrentAccessibilityType = () => {
  return AsyncStorage.getItem('accessibilityType')
}

export const saveAccessibilityType = type => {
  return AsyncStorage.setItem('accessibilityType', type)
}
