import React, { useEffect } from 'react'
import { getCurrentAccessibilityType, AccessibilityType } from '../services/accessibilityType'
import { connect } from 'react-redux'
import { changeAccessibilityType } from '../actions/AccessibilityActions'
import { createGlobalStyle } from 'styled-components'
import { Colors } from '../styles'

const AudioDescriptionStyle = createGlobalStyle`
  *:focus {
    outline: 5px solid ${Colors.blue};
  }
`

const NoOutlineStyle = createGlobalStyle`
  *:focus {
    outline: none;
  }
`

function Accessibility ({ changeAccessibilityType, accessibility }) {
  useEffect(() => {
    getCurrentAccessibilityType().then(type => changeAccessibilityType(type))
  }, [])

  return accessibility.type === AccessibilityType.audioDescription ? <AudioDescriptionStyle /> : <NoOutlineStyle />
}

const mapStateToProps = state => ({
  accessibility: state.AccessibilityReducer
})

export default connect(mapStateToProps, { changeAccessibilityType })(Accessibility)
