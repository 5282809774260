export default
{
  id: 'preservacao',
  name: 'Preservação',
  nameLibras: 'https://youtu.be/TDPCJ-1QC2s',
  thumb: require('../../others/bandeira-brasil-thumbnail.webp'),
  images: [{
    id: 'preservacao',
    description: 'protegendo a natureza',
    src: 'https://naccusew.sirv.com/iara/outros/preservacao.png?w=1280&h=950&format=webp'
  }],
  content: [
    {
      libras: 'https://youtu.be/1wAwnOYRkAs',
      text: 'Agora que vimos um pouco sobre a natureza que existe em cada bioma, devemos ter em mente que é muito importante cuidar do meio ambiente, coisa que não andamos fazendo nos últimos anos. Podemos ver isso quando nos noticiários aparece a Amazônia pegando fogo, animais morrendo e árvores sendo cortadas, por exemplo.'
    },
    {
      libras: 'https://youtu.be/5zOo_OC4gpo',
      text: 'O planeta terra é a casa de todos, de nós seres humanos e de todos os bichinhos e plantas. Vivemos todos juntos e dependemos um dos outros para sobreviver, por isso, é importante cuidar da natureza.'
    },
    {
      libras: 'https://youtu.be/BFKmHF6pA5w',
      text: 'O que podemos fazer para ajudar a cuidar do nosso planeta?'
    },
    {
      libras: 'https://youtu.be/bvyzxaANkUU',
      text: '<li>Recicle: separe os materiais feitos de plástico, metal, papel e vidro.</li> <li>Economize água.</li> <li>Jogue lixo no lixo, nunca no chão.</li> <li>Economize energia.</li> <li>Evite usar sacolas plásticas.</li> <li>Não tire as plantas e os animais de seu ambiente natural.</li> <li>Compre apenas o que você realmente precisa.</li> <li>Plante uma árvore.</li> <li>Quando você puder, evite andar de carro.</li> <li>Evite comprar produtos feitos a partir de peles de animais.</li>'
    },
    {
      libras: 'https://youtu.be/ciyZom0dE7M',
      text: 'Se você seguir estes 10 passos simples, você estará ajudando o planeta terra e vivendo em harmonia com todos os seres vivos.'
    },
    {
      libras: 'https://youtu.be/ACAlFNu9830',
      text: '<span style="font-size: 30px;font-weight:bold;">Atenção!</span>'
    },
    {
      libras: 'https://youtu.be/TRP0iv4uuIk',
      text: 'Os animais que apresentamos são da natureza e devemos respeitá-los e admirá-los. Não é legal tirar eles do ambiente em que vivem e temos que tomar cuidado ao nos aproximarmos deles, pois alguns podem ficar com medo de você e, ao tentarem se defender, podem tentar te machucar.'
    },
    {
      libras: 'https://youtu.be/eUc96QVM5Mk',
      text: 'Algumas plantas apresentadas no aplicativo podem ser consumidas pelo ser humano, como o caju, a jabuticaba e o açaí. Mas mesmo assim, fique atento para possíveis alergias. As outras plantas que estão no aplicativo não são consumidas pelo ser humano, pois pertencem somente à alimentação dos animais.'
    }
  ]
}
