export default [
  {
    id: 'piuva',
    name: 'Piúva',
    nameLibras: 'https://youtu.be/Qt87qCuySwg',
    thumb: require('../../entities/pantanal/piuva.webp'),
    images: [{
      id: 'piuva-1',
      description: 'Fotografia colorida de uma piúva. A piúva é uma árvore de grande porte da família dos ipês que pode chegar a cerca de 30 metros de altura. Seu tronco é fino, comprido e tem coloração clara. Sua copa é formada por muitas flores rosas. A piúva está no centro da fotografia, cercada por árvores mais baixas de copas verdes. Suas flores contrastam com o céu de azul intenso e sem nuvens de um dia ensolarado.',
      src: require('../../entities/pantanal/piuva.webp')
    }],
    content: [
      {
        libras: 'https://youtu.be/79K07JHvIkE',
        text: 'A piúva (nome científico: <i>Handroanthus impetiginosus</i>) é muito comum na região do Pantanal. Ela também é conhecida como ipê-roxo, por causa das suas lindas flores da cor rosa e roxo, e pertence à mesma família do ipê-amarelo, o mesmo que ocorre no bioma cerrado. As flores nascem entre os meses de junho e agosto, deixando o Pantanal mais bonito e colorido. Elas duram poucos dias e fornecem alimento para muitos insetos, como abelhas e vespas, que são importantes polinizadores. O tronco é grosso e muito resistente aos ataques de insetos que se alimentam de madeira.'
      },
      {
        libras: 'https://youtu.be/H27abrWSVdY',
        text: 'A piúva é uma árvore muito alta, alcançando até 30 metros de altura. Seu fruto é do formato de uma vagem que possui tricomas (equivalente a pelinhos), e dentro dele tem sementes com abas que permitem que elas consigam ser levadas pelo vento até achar um lugar bom para nascer, crescer e florescer.'
      }
    ]
  },
  {
    id: 'palmeira-acuri',
    name: 'Palmeira Acuri',
    nameLibras: 'https://youtu.be/r4ubRM20x84',
    thumb: require('../../entities/pantanal/palmeira-acuri_thumb.jpg'),
    images: [{
      id: 'palmeira-acuri-1',
      description: 'Fotografia colorida de uma palmeira acuri carregada de frutos. A palmeira acuri é uma árvore de médio porte que pode chegar a 8 metros de altura. Seu tronco é bem largo e coberto por cascas grossas que lembram grandes escamas. Ele é marrom claro, com algumas manchas verdes e brancas. Os frutos, chamados acuris, são verdes e ovais e tem 10 centímetros de comprimento. Eles crescem em pencas que lembram cachos de uvas grandes e pendem em direção ao chão. Na fotografia, a palmeira acuri está à direita e há dois cachos repletos de frutos, um em frente ao tronco e outro mais à esquerda. Ao fundo, está outra palmeira.',
      src: require('../../entities/pantanal/palmeira-acuri_thumb.jpg')
    }],
    content: [
      {
        libras: 'https://youtu.be/vGp8p2iWYSU',
        text: 'O acuri (nome científico: <i>Scheelea phalerata</i>) é uma palmeira típica do Pantanal, pois ocorre em bastante quantidade. É uma árvore que pode chegar até 10 metros de altura, seu tronco é muito grosso e suas folhas podem chegar até 5 metros de comprimento.'
      },
      {
        libras: 'https://youtu.be/dic7HhRn4xw',
        text: 'As flores da palmeira acuri são amarelas e nascem em cachos. O fruto dessa árvore é o acuri, que também nasce em cachos e seu formato é redondo, com um comprimento de cerca de 10 centímetros e de cor verde. Em seu interior, há 3 castanhas que são os principais alimentos das araras-azuis, uma das únicas aves que conseguem, com seu bico pontudo e forte, abrir o fruto duro e tirar as castanhas de dentro.'
      }
    ]
  }
]
