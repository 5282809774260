import React from 'react'
import styled from 'styled-components'
import { Colors, Fonts } from '../styles'
import useWindowDimensions from '../hooks/useWindowDimensions'

export function Popup ({ width, style, onClick, children, visible }) {
  const windowDimensions = useWindowDimensions()

  if (!visible) return null
  return (
    <>
      <Overlay className='ovs' windowDimensions={windowDimensions} />
      <Box style={style} width={width} windowDimensions={windowDimensions}>

        {children}
        <br />
        <br />
      </Box>
    </>
  )
}

const Box = styled.div`
  background: ${Colors.white};
  color: ${Colors.black};
  padding: 25px 0px;
  font-family: ${Fonts.regular};
  text-align: center;
  padding: 30px 0px;
  margin-bottom: 50px;
  left: 50%;
  max-width: 100%;
  min-width: ${({ windowDimensions }) => windowDimensions.width <= 1024 ? '100vw' : 'fit-content'};
  position: ${({ windowDimensions }) => windowDimensions.width <= 1024 ? 'fixed' : 'absolute'};
  height: ${({ windowDimensions }) => windowDimensions.width <= 1024 ? '100vh' : 'auto'};
  top: ${({ windowDimensions }) => windowDimensions.width <= 1024 ? 0 : '5%'};
  overflow-y: ${({ windowDimensions }) => windowDimensions.width <= 1024 ? 'auto' : 'auto'};
  border-radius: ${({ windowDimensions }) => windowDimensions.width <= 1024 ? 0 : '30px'};
  transform: translateX(-50%);
  -webkit-box-shadow: 0px 0px 11px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 11px -3px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 11px -3px rgba(0,0,0,0.75);
  z-index: 501;
`

const Overlay = styled.div`
  position: fixed;
  z-index: 500;
  background: rgba(0,0,0,0.7);
  top: -70px;
  height: calc(100% + 180px);
  width: calc(100%);
`
