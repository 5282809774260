export default [
  {
    id: 'amazonia',
    name: 'Amazônia',
    nameLibras: 'https://youtu.be/HSE6YJ-CKsA',
    thumb: require('../biomes/thumbnail/amazonia-thumbnail.webp'),
    images: [{
      id: 'amazonia-1',
      description: 'Fotografia colorida da floresta amazônica vista de cima. Do canto inferior direito até o canto superior esquerdo, um longo rio de águas barrentas corre formando curvas. Ambas as margens são cobertas por vegetação densa e fechada, como um imenso tapete formado por árvores muito grandes em vários tons de verde. As árvores próximas às margens são refletidas nas águas calmas do rio.',
      src: 'https://naccusew.sirv.com/iara/biomas/amazonia.jpg?w=1280&h=950&format=webp'
    }],
    audioSrc: require('../sounds/amazonia.mp3'),
    content: [
      {
        libras: 'https://youtu.be/0Xs5sn7b_48',
        text: 'A Amazônia ocupa a região norte do Brasil e abriga a maior biodiversidade de todo o planeta. Ou seja, existem muitos animais (fauna) e plantas (flora), sendo que algumas dessas espécies só existem na Amazônia. Neste bioma, a vegetação varia desde árvores muito grandes até muito pequenas. Seu clima é tropical, ou seja, é muito quente e úmido o ano todo. Por causa da grande quantidade de plantas da Amazônia, a água que existe nelas evapora e é suficiente para formar nuvens, que são levadas pelo vento para outras regiões do Brasil, fazendo com que chova nesses lugares. Esse é um dos motivos pelos quais é importante proteger a floresta e não desmatá-la.<br><br><br>O nome “Amazônia” deriva da palavra “Amazonas”, um grupo de guerreiras mitológicas descritas pelos gregos antigos. Um dos primeiros exploradores do Brasil encontrou nessa região uma tribo de mulheres com costumes semelhantes às guerreiras do mito, e, por esse motivo, foi dado esse nome ao local.'
      }
    ]
  },
  {
    id: 'caatinga',
    name: 'Caatinga',
    nameLibras: 'https://youtu.be/OtwoC4W1520',
    thumb: require('../biomes/thumbnail/caatinga-thumbnail.webp'),
    images: [{
      id: 'caatinga-1',
      description: 'Fotografia colorida de uma paisagem típica da Caatinga num dia ensolarado. A terra é marrom e seca, com alguns poucos trechos de vegetação rasteira. No canto inferior esquerdo há um cacto com muitos ramos laterais. O cacto é verde e tem os ramos compridos e cilíndricos cobertos de espinhos brancos e afiados. Ao fundo, estão árvores de pequeno porte e algumas cabras.',
      src: 'https://naccusew.sirv.com/iara/biomas/caatinga.jpg?w=1280&h=950&format=webp'
    }],
    audioSrc: require('../sounds/caatinga.mp3'),
    content: [
      {
        libras: 'https://youtu.be/KJ5HiENA7og',
        text: 'A Caatinga fica na região nordeste do Brasil. O clima desse local é considerado semiárido. Isso significa que lá é muito seco e quente, com pouca chuva durante o ano. A vegetação é adaptada ao clima, ou seja, as plantas têm poucas folhas (para evitar a perda de água pelo seu suor) e as raízes são muito profundas (para procurar água no solo). As árvores são baixas e os troncos são tortos; e algumas plantas possuem espinhos, como os cactos.<br><br><br>O nome "Caatinga" vem do tupi e significa “mata branca”. Isso porque, na seca, a maioria das plantas perdem suas folhas, e o seu tronco esbranquiçado fica bem evidente na paisagem.'
      }
    ]
  },
  {
    id: 'cerrado',
    name: 'Cerrado',
    nameLibras: 'https://youtu.be/NnZQdx6MzEM',
    thumb: require('../biomes/thumbnail/cerrado-thumbnail.webp'),
    images: [{
      id: 'cerrado-1',
      description: 'Fotografia colorida de uma paisagem típica do Cerrado num dia ensolarado. O chão é coberto por terra batida e avermelhada. Há plantas de pequeno porte espaçadas pelo terreno, algumas com aspecto seco e sem folhas e outras mais viçosas. Ao fundo, à direita, há um morro baixo que contrasta com o céu azul entrecortado por grandes nuvens brancas.',
      src: 'https://naccusew.sirv.com/iara/biomas/cerrado.jpg?w=1280&h=950&format=webp'
    }],
    audioSrc: require('../sounds/cerrado.mp3'),
    content: [
      {
        libras: 'https://youtu.be/kUa4YyGAKCA',
        text: ' O Cerrado ocupa a região central do Brasil. Possui muitos arbustos e gramas, além de árvores altas e baixas, com troncos retorcidos e casca grossa, que ajudam a evitar a perda de água.  O clima é tropical sazonal, ou seja, o inverno é muito seco e o verão é chuvoso. Na estação seca, podem ocorrer incêndios naturais que são importantes e fazem parte do ciclo de vida das plantas. O Cerrado já perdeu quase metade de sua área original devido a ação do ser humano, que desmata para usar a área para a agricultura e pecuária. Para que os animais e as plantas que vivem lá consigam sobreviver a tudo isso, precisamos cuidar e proteger esse bioma.<br><br><br>O nome "Cerrado" deriva do espanhol e significa fechado, que se refere à vegetação densa de arbustos e gramas desse bioma.'
      }
    ]
  },
  {
    id: 'mata-atlantica',
    name: 'Mata Atlântica',
    nameLibras: 'https://youtu.be/KYbotliFgzg',
    thumb: require('../biomes/thumbnail/mata-atlantica-thumbnail.webp'),
    images: [{
      id: 'mata-atlantica-1',
      description: 'Fotografia colorida de uma paisagem típica da Mata Atlântica num dia ensolarado. O terreno é completamente tomado por plantas de diversas espécies e de variados tons de verde. A mata fechada é formada por arbustos, palmeiras baixas e árvores altas com troncos escuros e finos, alguns cobertos por trepadeiras.',
      src: 'https://naccusew.sirv.com/iara/biomas/mata-atlantica.jpg?w=1280&h=950&format=webp'
    }],
    audioSrc: require('../sounds/mata-atlantica.mp3'),
    content: [
      {
        libras: 'https://youtu.be/RMr_--1zYiI',
        text: 'A Mata Atlântica fica próxima das praias (região litorânea). O clima é tropical, ou seja, é quente, úmido e chove bastante. Este é um dos principais biomas desmatados, restando muito pouco de sua cobertura original. Por conta disso, muitas espécies que moravam nessa região já foram extintas. Porém, a Mata Atlântica ainda abriga uma grande quantidade de espécies e, para evitar que mais animais e plantas desapareçam, é prioridade conservar sua biodiversidade.<br><br><br>A Mata Atlântica tem esse nome por conta da região em que se encontra ser próxima ao Oceano Atlântico. Esse oceano recebeu esse nome em homenagem ao Atlas, filho do Deus dos mares.'
      }
    ]
  },
  {
    id: 'pampa',
    name: 'Pampa',
    nameLibras: 'https://youtu.be/32Yg5mqXydM',
    thumb: require('../biomes/thumbnail/pampa-thumbnail.webp'),
    images: [{
      id: 'pampa-1',
      description: 'Fotografia colorida de uma paisagem típica do Pampa em um dia ensolarado. O terreno é formado por um imenso campo aberto e plano coberto por gramíneas em diversos tons de verde.  Na parte inferior da fotografia, o terreno é cortado por um rio estreito de margens retas e águas calmas. Ao longe, próximo à linha do horizonte, há bois e vacas pastando. O céu, de um azul vibrante, tem poucas nuvens.',
      src: 'https://naccusew.sirv.com/iara/biomas/pampa.jpg?w=1280&h=950&format=webp'
    }],
    audioSrc: require('../sounds/pampa.mp3'),
    content: [
      {
        libras: 'https://youtu.be/RqX0Ci75GzU',
        text: 'O Pampa está na região sul do Brasil e possui uma vegetação campestre, ou seja, há um predomínio de gramas e plantas baixas, deixando o território aberto, parecido com um campo. Não existem muitas árvores grandes na região, e as poucas que existem são encontradas próximas dos morros e dos rios. Muitos dos animais que vivem lá só são encontrados nessa região. O clima do Pampa é subtropical, com verões quentes e invernos muito frios, podendo eventualmente cair um pouco de neve.  Esse bioma é diferente dos outros do Brasil, pois possui as quatro estações do ano bem definidas, ou seja, é possível saber bem quando é outono, inverno, primavera e verão.<br><br><br>O nome “Pampa” significa “planícies”, e deriva da língua aimará e quéchua de povos que habitavam essa região. Esse nome é relacionado com a aparência de campo que o bioma possui.'
      }
    ]
  },
  {
    id: 'pantanal',
    name: 'Pantanal',
    nameLibras: 'https://youtu.be/0DtUdGGSzBI',
    thumb: require('../biomes/thumbnail/pantanal-thumbnail.webp'),
    images: [{
      id: 'pantanal-1',
      description: 'Fotografia colorida do Pantanal visto de cima. Diversos lagos de tamanhos e formatos variados estão espalhados pelo terreno. Suas margens são cobertas por vegetação rasteira de cor verde clara. Entre os corpos de água estão árvores de copas em vários tons de verde e algumas piúvas com suas copas rosas e roxas.',
      src: 'https://naccusew.sirv.com/iara/biomas/pantanal.jpg?w=1280&h=950&format=webp'
    }],
    audioSrc: require('../sounds/pantanal.mp3'),
    content: [
      {
        libras: 'https://youtu.be/lA0Vm5Zrkvw',
        text: 'O Pantanal fica na região centro-oeste do Brasil e tem o clima tropical continental, ou seja, é frio e seco no inverno, e quente e chuvoso no verão, principalmente nos meses de dezembro e janeiro. As chuvas fazem com que os rios fiquem muito cheios e ocorre alagamento em grande parte do território. Por isso as plantas são adaptadas para conseguir sobreviver também de baixo da água. A maior parte das plantas do Pantanal vêm de outros biomas do Brasil. A vegetação é organizada no que é chamado de “Capão”, que são manchas circulares de vegetação unida, formando uma ilha verde.<br><br><br>Por conta dos alagamentos que ocorrem no local, forma-se uma grande extensão de pântano. Por isso, a região foi nomeada “Pantanal”.'
      }
    ]
  }
]
