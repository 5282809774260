// README> QUESTION IDS MUST BE SEQUENTIALS

export default {
  id: 'pampa',
  questions: [
    {
      id: 1,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/vcqXKZ89VsU',
      text: 'Sobre o bioma do Pampa, escolha a alternativa falsa:',
      correctAnswer: [2],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/LtKjKTevcVs',
            text: 'A informação dessa alternativa está correta! Mas lembre-se de que estamos perguntando qual é a alternativa falsa.'
          },
          libras: 'https://youtu.be/GgoPCv0MbDM',
          text: 'A vegetação do Pampa é composta principalmente de gramas e plantas baixas'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/zngZ0Bjn958',
            text: 'Parabéns, resposta correta! Essa alternativa é falsa porque, na verdade, a maioria dos animais que vivem no Pampa só são encontrados nessa região.'
          },
          libras: 'https://youtu.be/J52e9dIuG0w',
          text: 'Muitos dos animais que vivem lá são encontrados em outras regiões do mundo'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/LtKjKTevcVs',
            text: 'A informação dessa alternativa está correta! Mas lembre-se de que estamos perguntando qual é a alternativa falsa.'
          },
          libras: 'https://youtu.be/-TXubM8kMRs',
          text: 'O Pampa está na região sul do Brasil'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/LtKjKTevcVs',
            text: 'A informação dessa alternativa está correta! Mas lembre-se de que estamos perguntando qual é a alternativa falsa.'
          },
          libras: 'https://youtu.be/nlJ3osvIqmQ',
          text: 'O Pampa possui animais que só existem lá'
        }
      ]
    },
    {
      id: 2,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/dXDzeCpbg5o',
      text: 'Sobre os animais do Pampa (tuco-tuco, sapo-de-barriga-vermelha e pica-pau- chorão), escolha a alternativa correta:',
      correctAnswer: [1],
      alternatives: [
        {
          id: 1,
          libras: 'https://youtu.be/FM8-nWOtmjY',
          text: 'Os tuco-tucos são da família dos roedores, assim como os ratos'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/1q6ZYL3FQEU',
            text: 'Os sapos-de-barriga-vermelha moram nas margens e mata próximos aos rios. São os tuco-tucos que cavam túneis.'
          },
          libras: 'https://youtu.be/wppsypYBa5k',
          text: 'Os sapos-de-barriga-vermelha cavam túneis para morarem'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/HYsUcVZCaZg',
            text: 'Somente os machos possuem topete vermelho.'
          },
          libras: 'https://youtu.be/v4Up7EENX1s',
          text: 'Os machos e as fêmeas do pica-pau-chorão possuem topete vermelho'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/OSbkbIXGn0Y',
            text: 'Os tuco-tucos vivem sozinhos e fazem túneis debaixo da terra para morarem e se protegerem.'
          },
          libras: 'https://youtu.be/rX1gkZLRB84',
          text: 'Os tuco-tucos vivem em bandos dentro dos troncos das árvores'
        }
      ]
    },
    {
      id: 3,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/SstABOVNDX4',
      text: 'Sobre as plantas do Pampa (algarrobo e barba-de-bode), escolha a alternativa correta:',
      correctAnswer: [4],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/ty0pwFXioqU',
            text: 'É a barba-de-bode que pode ser considerada uma planta invasora.'
          },
          libras: 'https://youtu.be/dz1YBCeaqN0',
          text: 'O algarrobo pode ser considerado uma planta invasora'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/WUdEQMww5uo',
            text: 'A barba-de-bode não possui flor. Quem tem flores pequenas e amarelas é o algarrobo.'
          },
          libras: 'https://youtu.be/NxZS_i18fyA',
          text: 'A barba-de-bode tem flores pequenas e amarelas'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/istlB3xzD0M',
            text: 'O algarrobo é uma árvore da família do feijão e das ervilhas.'
          },
          libras: 'https://youtu.be/2qcnWwnq4K0',
          text: 'O algarrobo é da família das gramas'
        },
        {
          id: 4,
          libras: 'https://youtu.be/4uCbmLxq8-U',
          text: 'A barba-de-bode não possui nem flor nem fruto pois pertence à família das gramas'
        }
      ]
    }
  ]
}
