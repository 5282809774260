import React from 'react'
import styled from 'styled-components'
import { Colors } from '../styles'
import { connect } from 'react-redux'
import { changePlayerVideo } from '../actions/LibrasPlayerActions'
// import useWindowDimensions from '../hooks/useWindowDimensions'
import { AccessibilityType } from '../services/accessibilityType'

function Accessible ({ style, libras, children, changePlayerVideo, accessibility }) {
  // const windowDimensions = useWindowDimensions()

  function handleClick () {
    if (!libras) return window.alert('Nenhum vídeo associado')
    return changePlayerVideo(libras)
  }

  const isDeaf = accessibility.type === AccessibilityType.libras

  if (!children) {
    return (
      <Wrapper
        onClick={() => handleClick()}
        style={style}
      >
        <Icon clickable src={require('./../assets/specialResources/libras.png')} />
      </Wrapper>
    )
  }

  if (!isDeaf) return children

  return (
    <>
      <Wrapper
        onClick={() => handleClick()}
        style={style}
      >
        {children}
      </Wrapper>
    </>
  )
}

const mapStateToProps = state => ({
  accessibility: state.AccessibilityReducer
})

export default connect(mapStateToProps, { changePlayerVideo })(Accessible)

const Wrapper = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  background: transparent;
  & > p:hover,  & >  h1:hover, & > p:hover , & > h1:hover  {
    cursor: pointer;
    border-radius: 20px;
    background: ${Colors.libras};
    color: ${Colors.white};
  } 
`

const Icon = styled.img`
  height: 30px;
  border: none;
  border-radius: 50%;
  color: ${Colors.white};
  background: ${Colors.libras};
  padding: 7px;
  margin: 15px;
  cursor: ${props => props.clickable ? 'pointer' : 'normal'};
  float: right;
  display: inline-block;
`
