export default [
  {
    id: 'anta',
    name: 'Anta',
    nameLibras: 'https://youtu.be/bU9_VsZf564',
    thumb: require('../../entities/cerrado/anta.webp'),
    images: [{
      id: 'anta-1',
      description: 'Fotografia colorida de duas antas prestes a se alimentar. A anta é um animal que lembra um porco bem grande e forte, com um nariz longo, parecendo uma mini tromba de elefante, e uma crina bem curtinha. Ela tem cerca de 2 metros de comprimento. Seu pelo é bem baixo e acinzentado. Suas orelhas são ovaladas e voltadas para cima e seus olhos, arredondados e pretos. As antas estão no centro da foto, uma ao lado da outra, diante de um monte de grama num chão de terra batida.',
      src: require('../../entities/cerrado/anta.webp')
    }],
    audioSrc: require('../../sounds/anta.mp3'),
    content: [
      {
        libras: 'https://youtu.be/9Ej25SGBX4s',
        text: 'As antas (nome científico: <i>Tapirus terrestris</i>) são animais de pernas curtas com casco, corpo marrom e gordinho. Tem um focinho mole e flexível, muito parecido com uma pequena tromba de elefante, que ajuda a tirar coisas da frente para encontrar comida, como folhas e frutos. As antas têm cerca de 2 metros de comprimento e vivem cerca de 22 anos.'
      },
      {
        libras: 'https://youtu.be/ubwxBEhBO2E',
        text: 'As antas são preguiçosas, descansam quase o dia todo, e só de noite saem para comer. São animais tímidos que gostam de ficar escondidos na mata. Elas correm risco de extinção, pois é caçada e também porque o humano destrói a casa dela derrubando a floresta.'
      },
      {
        libras: 'https://youtu.be/vz74vd2LkmI',
        text: 'Você sabia que o pelo do filhote de anta é bem diferente do pelo dos adultos? A pelagem é repleta de faixas brancas que servem para o filhote se camuflar contra predadores.'
      }
    ]
  },
  {
    id: 'lobo-guara',
    name: 'Lobo-guará',
    nameLibras: 'https://youtu.be/Gi82U_EY1zk',
    thumb: require('../../entities/cerrado/lobo-guara_thumb.jpg'),
    images: [{
      id: 'lobo-guara-1',
      description: 'Fotografia colorida de um lobo-guará em um rochedo. O lobo-guará é um animal que lembra um cachorro esguio de pernas bem longas e finas. Ele mede cerca de 1 metro de comprimento. A maior parte do seu corpo é coberto por pelos alaranjados, mas as extremidades de suas pernas são pretas, sua cauda é longa e branca, e suas orelhas triangulares têm pelos brancos na parte interna. Ele tem olhos redondos e pretos, focinho comprido e nariz também preto. O lobo está no centro da fotografia, de pé, cercado por grandes pedras. Seu corpo está voltado para frente e sua cabeça virada para a esquerda.',
      src: require('../../entities/cerrado/lobo-guara_thumb.jpg')
    }],
    audioSrc: require('../../sounds/lobo-guara.mp3'),
    content: [
      {
        libras: 'https://youtu.be/hs5Gny0JrlE',
        text: 'O lobo-guará (nome científico: <i>Chrysocyon brachyurus</i>) é parente dos cachorros e ele existe somente na América do Sul. Ele é tímido e solitário, preferindo manter distância dos seres humanos. Por isso, é difícil de ser visto vivendo em áreas com mato baixo. Ele come pequenos animais, como os tatus, e frutos variados do cerrado, como a lobeira, que serve como remédio natural para evitar que ele fique doente.'
      },
      {
        libras: 'https://youtu.be/6UFQWBTLmnU',
        text: 'Seu pelo é avermelhado, com partes pretas. Suas pernas são longas e finas, o que faz dele um animal veloz e ágil. Seu comprimento é de cerca de 1 metro, e eles conseguem viver na natureza até 15 anos.'
      },
      {
        libras: 'https://youtu.be/tFfbR60t55g',
        text: 'Quando os filhotes nascem, a mãe fica cuidando deles na toca enquanto o pai vai buscar comida.'
      },
      {
        libras: 'https://youtu.be/nl7ws20HJiY',
        text: 'O lobo-guará é mundialmente conhecido como espécie ameaçada de extinção. Com o desmatamento, o lobo-guará precisa atravessar estradas para ir de um lado até o outro da mata, sendo, por isso, muitas vezes atropelado e caçado.'
      }
    ]
  },
  {
    id: 'seriema',
    name: 'Seriema',
    nameLibras: 'https://youtu.be/E0mzeJ3zO2A',
    thumb: require('../../entities/cerrado/seriema_thumb.jpg'),
    images: [{
      id: 'seriema-1',
      description: 'Fotografia colorida de uma seriema em meio à mata. A seriema é uma ave grande e esguia que mede 70 centímetros de altura. A maior parte do seu corpo é coberto por penas marrons claras, mas as da barriga são brancas e as da cauda, mais longas e escuras. As pernas da seriema são compridas, finas e avermelhadas. Seu pescoço é longo. Ela tem a cabeça pequena, olhos pequenos, arredondados e escuros e o bico curto e avermelhado. Acima do bico, ela tem um tufo de penas. A seriema está no centro da fotografia, voltada para a direita, num chão de terra batida à frente de algumas plantas.',
      src: require('../../entities/cerrado/seriema_thumb.jpg')
    }],
    audioSrc: require('../../sounds/seriema.mp3'),
    content: [
      {
        libras: 'https://youtu.be/AuMTN2NWZCQ',
        text: 'A seriema (nome científico: <i>Cariama cristata</i>) é uma ave grande, com altura de até 70 centímetros de comprimento. Sua cauda é longa, suas penas são cinza-amarelado, com o bico e pernas vermelhas. Tem uma crista formada por um tufo de penas longas e escuras, que foi o motivo pelo qual os indígenas tupi deram o nome de Seriema, que significa “crista levantada”.'
      },
      {
        libras: 'https://youtu.be/JHVyN62Rzxc',
        text: 'A seriema bota dois ovos branco-rosados e os pais se revezam para cuidar do ninho e dos filhotes. Demora 1 mês para os filhotes nascerem dos ovos e, depois de 2 semanas sob cuidado dos pais, vão embora do ninho e se tornam independentes. O canto da seriema pode ser ouvido a mais de 1km de distância, e parece ser longas risadas. Ela come insetos e pequenos animais como ratos e lagartos. Os fazendeiros gostam dela porque às vezes elas comem as cobras das fazendas, e por isso a seriema pode ficar acostumada com a presença do ser humano.'
      },
      {
        libras: 'https://youtu.be/XsqY43yS5p8',
        text: 'Esse animal vive cerca de 25 anos e não está na lista dos animais em risco de extinção, porém não devemos deixar de protegê-la.'
      }
    ]
  }
]
