import styled from 'styled-components'
import { Fonts } from '../../styles'
import { Container } from 'react-grid-system'

export const CardList = styled(Container)`
  margin: 0;
  padding: 0px;
  margin-bottom: 30px;
`

export const Text = styled.p`
  font-size: 25px; 
  font-family: ${Fonts.regular} !important;
`
