import React, { useState, useEffect } from 'react'
import { Accessible, Popup } from '../../components'
import { connect } from 'react-redux'
import { Animations } from '../../styles'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { AlternativeDescription, ConfirmButton, Text, TextContainer, ButtonContainer, AlternativeButton, Label } from './styles'
import { Col, setConfiguration } from 'react-grid-system'
import { AccessibilityType } from '../../services/accessibilityType'
import { playAudio } from '../../services/audioPlayer'
import sounds from '../../assets/data/sounds'
import Lottie from 'react-lottie'
import { saveQuizQuestion, isQuestionDone, isQuizCompleted, isAlternativeOneOfTheCorrectOnes, isLastQuestion } from '../../services/quiz'
import { useHistory } from 'react-router-dom'

function Question (props) {
  setConfiguration({ gutterWidth: 25 })

  const { question, quizId, isMultipleAlternatives, correctAnswer, onCorrectAnswerGoToNextQuestion } = props
  const [selectedAlternatives, setSelectedAlternative] = useState([])
  const [wrongAlternativesAlreadyTried, setWrongAlternativesAlreadyTried] = useState([])
  const windowDimensions = useWindowDimensions()
  const [popupAnimation, setPopupAnimation] = useState(Animations.mustSelect)
  const [popupData, setPopupData] = useState({ texts: [] })
  const [isPopupVisible, setPopupVisible] = useState(false)
  const [isDone, setDone] = useState(false)
  const history = useHistory()

  useEffect(() => {
    setSelectedAlternative([])
    isQuestionDone(quizId, question.id).then(result => setDone(result))
  }, [question, isPopupVisible])

  useEffect(() => {
    checkIfQuizIsCompleted()
  }, [isPopupVisible])

  useEffect(() => {
    setWrongAlternativesAlreadyTried([])
  }, [question, isDone])

  if (!question) {
    return null
  }

  async function checkIfQuizIsCompleted () {
    const isCompleted = await isQuizCompleted(quizId)
    if (!isCompleted) return

    playAudio(sounds.congrats)
    setPopupVisible(true)
    setPopupAnimation(Animations.congrats)
    setPopupData({
      texts: [{
        libras: 'https://youtu.be/aEmpRJIKrA8',
        text: 'Parabéns, você completou o quiz!'
      }],
      redirect: '/biomas'
    })
  }

  const isDeaf = props.accessibility.type === AccessibilityType.libras

  function isSelected (alternativeId) {
    return selectedAlternatives.includes(alternativeId)
  }

  function isWrongAlternativesAlreadyTried (alternativeId) {
    return wrongAlternativesAlreadyTried.includes(alternativeId)
  }

  function confirmQuestion () {
    if (selectedAlternatives.length === 0) {
      return handleNoAnswerSelected()
    } else if (isAnswerCorrect()) {
      handleCorrectAnswer()
    } else {
      handleWrongAnswer()
    }
  }

  function isAnswerCorrect () {
    return JSON.stringify(question.correctAnswer.sort()) === JSON.stringify(selectedAlternatives.sort())
  }

  function handleNoAnswerSelected () {
    playAudio(sounds.noSelectedAnswer)
    setPopupVisible(true)
    setPopupAnimation(Animations.mustSelect)
    setPopupData({
      texts: [{
        libras: 'https://youtu.be/zke6R12S_ps',
        text: 'Você deve selecionar ao menos uma alternativa'
      }]
    })
  }

  function handleCorrectAnswer () {
    if (!isLastQuestion(quizId, question.id)) playAudio(sounds.positive)
    setPopupVisible(true)
    setPopupAnimation(Animations.correct)
    setPopupData({
      texts: [{
        libras: 'https://youtu.be/_m7oj5OOGbs',
        text: 'Parabéns, resposta correta!'
      }]
    })
    saveQuizQuestion(quizId, question.id)
    setDone(true)
    onCorrectAnswerGoToNextQuestion()
  }

  function handleWrongAnswer () {
    const alternatives = question.alternatives.filter(({ id }) => selectedAlternatives.includes(id))
    const wrongAlternatives = selectedAlternatives.filter(id => !question.correctAnswer.includes(id))

    playAudio(sounds.negative)
    setPopupAnimation(Animations.wrong)
    setPopupVisible(true)
    setPopupData({ texts: alternatives.map(alternative => alternative.reason) })
    setWrongAlternatives(wrongAlternatives)
  }

  function renderText () {
    return (
      <TextContainer windowDimensions={windowDimensions}>
        <Accessible
          libras={question.libras}
        >
          <Text>
            {question.text}
          </Text>
        </Accessible>
      </TextContainer>
    )
  }

  function renderButton (alternative) {
    return (
      <>
        <AlternativeButton
          onClick={() => toggleAlternative(alternative.id)}
          accessible={isDeaf}
          libras={alternative.libras}
          shouldShowAsCorrectAlternative={isDone && isAlternativeOneOfTheCorrectOnes(correctAnswer, alternative.id)}
          disabled={isWrongAlternativesAlreadyTried(alternative.id) || (isDone && isAlternativeOneOfTheCorrectOnes(correctAnswer, alternative.id))}
          isSelected={isSelected(alternative.id)}
          isWrongTried={isWrongAlternativesAlreadyTried(alternative.id)}
          windowDimensions={windowDimensions}
          style={{ textAlign: !isDeaf ? 'right' : 'center' }}
        >
          {!isDeaf && (
            <Label
              isWrongTried={isWrongAlternativesAlreadyTried(alternative.id)}
              shouldShowAsCorrectAlternative={isDone && isAlternativeOneOfTheCorrectOnes(correctAnswer, alternative.id)}
            >
              {alternative.id}
            </Label>
          )}
          {alternative.text}
        </AlternativeButton>
      </>
    )
  }

  function renderConfirmButton (label, text) {
    if (isDeaf) {
      return (
        <ConfirmButton
          disabled={isDone}
          onClick={() => confirmQuestion()}
          accessible={isDeaf}
          libras='https://youtu.be/g54nm23XBF4'
          windowDimensions={windowDimensions}
        >
          Confirmar
        </ConfirmButton>
      )
    }

    return (
      <ConfirmButton
        disabled={isDone}
        onClick={() => confirmQuestion()}
      >
        Confirmar
      </ConfirmButton>
    )
  }

  function toggleAlternative (alternativeId) {
    if (isSelected(alternativeId)) {
      setSelectedAlternative(selectedAlternatives.filter(id => id !== alternativeId))
    } else {
      if (isMultipleAlternatives) {
        setSelectedAlternative([...selectedAlternatives, alternativeId])
      } else if (!isMultipleAlternatives) {
        setSelectedAlternative([alternativeId])
      }
    }
  }

  function setWrongAlternatives (wrongAlternatives) {
    const wrongAlternativesToSet = wrongAlternatives.filter(({ wrongAlternative }) => !wrongAlternativesAlreadyTried.includes(wrongAlternative))

    setWrongAlternativesAlreadyTried([...wrongAlternativesAlreadyTried, ...wrongAlternativesToSet])
  }

  function renderAlternatives () {
    return (
      <ButtonContainer width={windowDimensions.width}>
        {question.alternatives.map(alternative => (
          <Col key={alternative.id} sm={12} lg={6}>
            {renderButton(alternative)}
          </Col>
        ))}
        <Col sm={12}>
          {renderConfirmButton()}
        </Col>
      </ButtonContainer>
    )
  }

  function renderPopup () {
    return (
      <>
        <Popup
          visible={isPopupVisible}
        >
          <AlternativeDescription
            style={{ marginBottom: -25 }}
            windowDimensions={windowDimensions}
          >
            <Lottie
              options={{
                loop: popupAnimation.loop,
                autoplay: true,
                animationData: popupAnimation.src,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={popupAnimation.size}
              width={popupAnimation.size}
              isStopped={false}
              isPaused={false}
              style={{
                marginBottom: popupAnimation.margin,
                marginTop: popupAnimation.margin
              }}
            />
            {popupData.texts.map((data, index) =>
              (
                <Accessible libras={data.libras} key={index + 'reason'.substr(0, 5)}>
                  <p style={{ paddingLeft: 0, paddingRight: 0 }}>{data.text}</p>
                  {data.redirect}
                </Accessible>
              ))}
            <ConfirmButton
              style={{ marginBottom: windowDimensions.width < 1024 ? 50 : 0 }}
              description='Ok, voltar para as alternativas'
              onClick={() => {
                popupData.redirect
                  ? history.push(popupData.redirect)
                  : setPopupVisible(false)
              }}
            >
              Ok
            </ConfirmButton>
          </AlternativeDescription>
        </Popup>
      </>
    )
  }

  return (
    <>
      {renderPopup()}
      {renderText()}
      {renderAlternatives()}
    </>
  )
}

const mapStateToProps = state => ({
  accessibility: state.AccessibilityReducer,
  librasPlayerProps: state.LibrasPlayerReducer
})

export default connect(mapStateToProps, null)(Question)
