export default
{
  id: 'tale-pantanal',
  name: 'Conto da Pantanal',
  nameLibras: 'https://youtu.be/vgRD0mj5EJ8',
  images: [],
  audioSrc: require('../../../sounds/tales/pantanal.mp3'),
  videoSrc: 'https://www.youtube.com/embed/UGDIgrSfGB4',
  content: [
    {
      code: 'pt-01',
      libras: 'https://youtu.be/3GOk4hdiC9w',
      text: '- Onde será que começo a procurar a semente aqui no Pantanal?<br><br>-  Iara, para a semente encontrar, o primeiro canto deve escutar.<br><br>Estava andando a procura de sons, mas fiquei muito confusa, pois no Pantanal tem muitas aves e não sabia qual canto deveria seguir. Comecei a ficar com fome e decidi dar uma pausa para procurar algo para comer. Foi quando vi uma árvore com algo verde em cachos que parecia ser uma fruta gostosa. Peguei um fruto e me sentei para tentar tirar a casca.'
    },
    {
      code: 'pt-02',
      libras: 'https://youtu.be/HOllGmLMDSs',
      text: '- O que você está fazendo?<br><br>- Que susto! Oi, senhor arara. Eu estou com fome, daí pensei em comer isso...<br><br>- Você está louca? Você não pode comer um acuri!<br><br>- Então por que você está comendo?<br><br>- Só nós, araras-azuis, conseguimos comer esse fruto. Vou te levar até uma bananeira, daí vai poder comer à vontade.'
    },
    {
      audioDescription: 'teste',
      style: {
        maxHeight: '600px',
        maxWidth: '600px'
      },
      image: 'https://iaraapp.sirv.com/iara/contos/pantanal/iara-palmeira.png?w=600&h=600&format=webp'
    },
    {
      code: 'pt-03',
      libras: 'https://youtu.be/JY9Rp22nlg8',
      text: 'Ele começou a voar e eu corri atrás dele. Quando chegamos, comecei a comer as bananas e olhar a paisagem ao lado da arara-azul. Foi quando vi um ninho dentro do tronco numa árvore alta ali por perto.<br><br>- Olha que bonito aquele ninho!<br><br>- É a minha casa! Lá está meu marido e nossos lindos ovinhos. Bom, eles são filhos de um casal de amigos nossos que foram, infelizmente, capturados.<br><br>- Nossa! O que aconteceu? Vocês sabem onde eles podem estar?<br><br>- Não sabemos, mas achamos que eles devem estar presos em alguma gaiola por aí… Tiveram que deixar seu ninho com seus filhotes, e eles não iriam conseguir sobreviver sozinhos. Foi então que eu e meu marido decidimos adotá-los.'
    },
    {
      code: 'pt-04',
      libras: 'https://youtu.be/BLgo_V59i3E',
      text: '- Sinto muito por eles. Mas fico feliz que os filhotes tenham uma família.<br><br>- Estou tão ansioso pela chegada deles! Mas meio preocupado… Um dos ovinhos está brilhando e não sabemos o que está acontecendo... Mas iremos amá-lo de qualquer forma. Quer dar uma olhadinha neles?<br><br>- Quero muito!<br><br>Segui a arara-azul até a árvore onde estava seu ninho.<br><br>- Oi, querido! Trouxe uma amiga que quer ver nossos filhotes.<br><br>- Oi! Claro, pode dar uma olhada!<br><br>Dei uma espiada dentro do tronco e vi 3 ovos no ninho. De repente, eles começaram a se mexer e os filhotes, a bicar a casca.'
    },
    {
      audioDescription: 'dummy image',
      style: {
        maxHeight: '600px',
        maxWidth: '600px'
      },
      image: 'https://iaraapp.sirv.com/iara/contos/pantanal/iara-arara.png?w=600&h=600&format=webp'
    },
    {
      code: 'pt-05',
      libras: 'https://youtu.be/kr0kAVUXm4g',
      text: '- Ai, meu Deus! Estão nascendo!<br><br>Ficamos vendo até todos saírem dos seus ovos e darem seus primeiros pios.<br><br>- Bem-vindos ao mundo, meus filhos! Seus pais amam muito todos vocês!<br><br>Fiquei tão contente de ver uma família tão carinhosa e feliz! Desviei a atenção dos filhotes e percebi que a semente mágica que eu precisava estava ali, dentro da casca do ovo. Era ela que estava dando o brilho ao filhote!<br><br>- Olha, essa é a semente que estava procurando. Posso ficar com ela?<br><br>- Claro que sim, temos tudo que precisamos aqui: nossa família.<br><br>Posso pegar essa pena que está solta no ninho também? Ela é tão bonita e eu queria ter uma recordação de vocês.<br><br>- Pode sim, nunca esqueceremos de você também.'
    }
  ]
}
