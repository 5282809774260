import React from 'react'
import styled from 'styled-components'

export default function VideoIframe ({ src, title }) {
  return (
    <VideoWrapper>
      <Iframe
        title={title}
        src={src}
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowfullscreen
      />
    </VideoWrapper>
  )
}

const VideoWrapper = styled.div`
    margin: 20px;
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
`

const Iframe = styled.iframe`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    @media(min-width: 1080px) {
        max-width: 800px;
        max-height: 450px;
        left: calc(50% - 400px);
    }
`
