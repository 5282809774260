import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import { connect } from 'react-redux'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { PrimaryButton } from './Button'
import { changePlayerVideo } from '../actions/LibrasPlayerActions'
import { Colors } from '../styles'
import { RiCloseLine } from 'react-icons/ri'
import Draggable from 'react-draggable'

function LibrasPlayer ({ librasPlayerProps, changePlayerVideo }) {
  const windowDimensions = useWindowDimensions()
  const [visible, setVisible] = React.useState(false)
  const { url } = librasPlayerProps
  if (!url) return null
  return (
    <div>
      <Draggable
        handle='.box'
        cancel='.no-cursor'
      >
        <PlayerContainer
          className='box'
          visible={visible}
          windowDimensions={windowDimensions}
        >
          <CloseButton
            className='no-cursor'
            windowDimensions={windowDimensions}
            onClick={() => changePlayerVideo()}
          >
            <RiCloseLine />
          </CloseButton>
          <ReactPlayer
            url={url}
            playing
            style={{ display: url ? 'block' : 'none' }}
            muted
            controls={false}
            width='100%'
            height='100%'
            onPlay={() => setVisible(true)}
          />
        </PlayerContainer>
      </Draggable>
    </div>
  )
}

const mapStateToProps = state => ({
  librasPlayerProps: state.LibrasPlayerReducer
})

export default connect(mapStateToProps, { changePlayerVideo })(LibrasPlayer)

const CloseButton = styled(PrimaryButton)`
  width: auto;
  font-size: 30px;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
  z-index: 103;  
  border-radius: 50px;
  margin-right: 0;
  height:50px;
  width:50px;
  padding: 0;
  color: ${Colors.blue};
  bottom: ${({ windowDimensions }) => windowDimensions.width <= 1000 ? '140px' : '190px'};
  right: ${({ windowDimensions }) => windowDimensions.width <= 1000 ? '5px' : '10px'};
  position: fixed;
  `

const PlayerContainer = styled.div`
  border: ${Colors.blue} solid;
  cursor: grab;
  background: ${Colors.blue};
  border-radius: 20px;
  height: ${({ windowDimensions }) => windowDimensions.width <= 1000 ? '150px' : '200px'};
  width: ${({ windowDimensions }) => windowDimensions.width <= 1000 ? '266.67px' : '355.56px'};
  right: ${({ windowDimensions }) => windowDimensions.width <= 1000 ? '15px' : '30px'};
  position: fixed;
  padding: 10px;
  bottom: 10px;
  z-index: 10001;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);

  &:active {
    cursor: grabbing;
  }
  `
