import React, { useEffect, useState } from 'react'
import * as serviceWorker from './serviceWorker'
import { Tooltip } from './components/Tooltip'

export default function ServiceWorkerWrapper () {
  const [showReload, setShowReload] = useState(false)
  const [waitingWorker, setWaitingWorker] = useState(null)

  function onSWUpdate (registration) {
    setShowReload(true)
    setWaitingWorker(registration.waiting)
  }

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate })
  }, [])

  function reloadPage () {
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' })
    }
    setShowReload(false)
    window.location.reload(true)
  }

  return (
    <Tooltip
      visible={showReload}
      onClick={() => reloadPage()}
      mobileMessage='Nova versão disponível'
      desktopMessage='Uma nova versão do aplicativo está disponível'
      buttonText='Atualizar'
    />
  )
}
