import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Header, Accessible, RoundedButton, LockableRoundedButton, LibrasAccessible, Popup, AudioPlayer } from '../../components'
import { Title, SubTitle, Content, EntityImage, EntityImageDesktop, Spacer, Text, TextContainer, TitleDesktop, ImageInfo, BtnStyle, AudioPlayerWrapper } from './styles'
import { getEntityById, getBiomeById } from '../../services'
import { connect } from 'react-redux'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { GiTreeBranch, GiHummingbird } from 'react-icons/gi'
import { BsPuzzleFill } from 'react-icons/bs'
import Lightbox from 'react-image-lightbox'
import Parser from 'html-react-parser'
import { saveAsRead, LockStatus } from '../../services/quizLock'
import Lottie from 'react-lottie'
import { AlternativeDescription, ConfirmButton } from '../Quiz/styles'
import { Animations } from '../../styles'
import { AccessibilityType } from '../../services/accessibilityType'
import { isQuizCompleted } from '../../services/quiz'
import { playAudio } from '../../services/audioPlayer'
import sounds from '../../assets/data/sounds'

const mapTypeToFile = {
  plantas: 'plants',
  animais: 'animals'
}

function Entity (props) {
  const params = useParams()
  const [isBlind, setAsBlind] = useState(false)
  const [isDeaf, setAsDeaf] = useState(false)
  const [isLightboxOpen, setLightBoxOpen] = useState(false)
  const [popupAnimation, setPopupAnimation] = useState(Animations.sad)
  const [popupData, setPopupData] = useState({ texts: [] })
  const [isPopupVisible, setPopupVisible] = useState(false)
  const biomeId = params.biome ? params.biome.toString() : undefined
  const [unlocked, setUnlocked] = useState(false)
  const [quizCompleted, setQuizCompleted] = useState(false)
  const windowDimensions = useWindowDimensions()
  const history = useHistory()
  const type = params.type
  const [entity, setEntity] = useState(null)
  const [isAudioPresent, setIsAudioPresent] = useState(true)

  useEffect(() => {
    const isBiome = type === undefined

    if (isBiome) {
      const biome = getBiomeById(biomeId)
      return setEntity(biome)
    }

    const isTypeNotValid = !(['plantas', 'animais'].includes(type))

    if (isTypeNotValid) {
      history.goBack()
      window.alert('Ops!')
      return
    }

    const entityId = params.entity.toString()
    const entity = getEntityById(entityId, mapTypeToFile[type], biomeId)

    if (entity.audioSrc === undefined) {
      setIsAudioPresent(false)
    }

    if (!entity) {
      history.goBack()
      window.alert('Esse bioma não tem dados de ' + type + ' ainda!')
      return null
    }

    setEntity(entity)
  }, [])

  useEffect(() => {
    const isUserBlind = props.accessibility.type === AccessibilityType.audioDescription
    const isUserDeaf = props.accessibility.type === AccessibilityType.libras
    if (isUserBlind) {
      setAsBlind(true)
    }
    if (isUserDeaf) {
      setAsDeaf(true)
    }
  }, [props.accessibility])

  useEffect(() => {
    if (entity === null) return

    saveAsRead(biomeId, entity.id)
      .then(lockStatus => {
        if (lockStatus === LockStatus.locked) {
          return
        }

        setUnlocked(lockStatus)
        if (lockStatus === LockStatus.alreadyUnlocked) {
          return
        }

        playAudio(sounds.unlocked)
        setPopupAnimation(Animations.congrats)
        setPopupData({
          texts: [{
            libras: 'https://youtu.be/3faXeyEqwRQ',
            text: 'Você liberou o quiz desse bioma!'
          }]
        })
        setPopupVisible(true)
      })
  }, [entity])

  useEffect(() => {
    async function fetchQuizCompleted () {
      const completed = await isQuizCompleted(biomeId)
      setQuizCompleted(completed)
    }

    fetchQuizCompleted()
  }, [])

  if (!entity) {
    return (<></>)
  }

  const { name, nameLibras, id } = entity
  const img = entity.images[0]

  function mustStudyBeforeOpenQuiz () {
    playAudio(sounds.locked)
    setPopupData({
      texts: [{
        libras: 'https://youtu.be/9q2uFRfvHRM',
        text: 'Você precisa estudar sobre o bioma e todos seus animais e plantas para poder acessar o quiz'
      }]
    })
    setPopupVisible(true)
  }

  function checkQuizLock (e) {
    if (unlocked) {
      return history.push(`/quiz/${biomeId}`)
    }

    e.preventDefault()
    mustStudyBeforeOpenQuiz()
  }

  function renderLearnMore () {
    return (
      <>
        <BtnStyle />
        <Accessible libras='https://youtu.be/WQVsArZpkns'>
          <SubTitle windowDimensions={windowDimensions}>Explorar mais</SubTitle>
        </Accessible>
        <div style={{ margin: windowDimensions.width > 1080 ? '0 75px' : '0 35px' }}>
          <RoundedButton description={`Acessar fauna do bioma ${getBiomeById(biomeId).name}`} className='rndBtn' to={`/bioma/${biomeId}/animais`}>
            <GiHummingbird />
          </RoundedButton>
          <RoundedButton description={`Acessar flora do bioma ${getBiomeById(biomeId).name}`} className='rndBtn' to={`/bioma/${biomeId}/plantas`}>
            <GiTreeBranch />
          </RoundedButton>
          <LockableRoundedButton onClick={checkQuizLock} unlocked={unlocked} completed={quizCompleted} description={`Acessar quiz do bioma ${getBiomeById(biomeId).name}`} className='rndBtn'>
            <BsPuzzleFill />
          </LockableRoundedButton>
        </div>
        <Spacer />
      </>
    )
  }

  function renderText () {
    return (
      <>
        {entity.content.map(({ text, libras }, index) => (
          <Accessible
            libras={libras}
            key={index + text.slice(10)}
          >
            <Text windowDimensions={windowDimensions}>
              {Parser(text)}
            </Text>
          </Accessible>
        ))}
      </>
    )
  }

  function renderMobile () {
    return (
      <>
        <EntityImage
          onClick={() => setLightBoxOpen(true)}
          windowDimensions={windowDimensions}
          key={img.id.toString().toUpperCase()}
          alt={img.description}
          tabIndex='-1'
          draggable='false'
          src={img.src}
        />
        {!isBlind && <ImageInfo>Clique na imagem para ver em tela cheia</ImageInfo>}
        <Accessible
          libras={nameLibras}
          key={id}
        >
          <Title>{name}</Title>
          <AudioPlayerWrapper>
            {!isDeaf && isAudioPresent && <AudioPlayer audioSrc={entity.audioSrc} />}
          </AudioPlayerWrapper>
        </Accessible>
        {renderText()}
        {renderLearnMore()}
      </>
    )
  }

  function renderDesktop () {
    return (
      <>
        <TextContainer>
          <Accessible
            libras={nameLibras}
            key={img.id}
          >
            <TitleDesktop>{name}</TitleDesktop>
            <AudioPlayerWrapper>
              {!isDeaf && isAudioPresent && <AudioPlayer audioSrc={entity.audioSrc} />}
            </AudioPlayerWrapper>
          </Accessible>
          {renderText()}
          {renderLearnMore()}
        </TextContainer>
        <EntityImageDesktop
          tabIndex='-1'
          onClick={() => setLightBoxOpen(true)}
          draggable='false'
          desktop
          windowDimensions={windowDimensions}
          key={img.id}
          alt={img.description}
          src={img.src}
        />
      </>
    )
  }

  function renderPopup () {
    return (
      <>
        <Popup
          visible={isPopupVisible}
        >
          <AlternativeDescription
            style={{ marginBottom: -25 }}
            windowDimensions={windowDimensions}
          >
            <Lottie
              options={{
                loop: popupAnimation.loop,
                autoplay: true,
                animationData: popupAnimation.src,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={popupAnimation.size}
              width={popupAnimation.size}
              isStopped={false}
              isPaused={false}
              style={{
                marginBottom: popupAnimation.margin,
                marginTop: popupAnimation.margin
              }}
            />
            {popupData.texts.map((data, index) =>
              (
                <Accessible libras={data.libras} key={index + 'reason'.substr(0, 5)}>
                  <p style={{ paddingLeft: 0, paddingRight: 0 }}>{data.text}</p>
                  {data.redirect}
                </Accessible>
              ))}
            <ConfirmButton
              style={{ marginBottom: windowDimensions.width < 1024 ? 50 : 0 }}
              description='Ok, voltar para as alternativas'
              onClick={() => {
                popupData.redirect
                  ? history.push(popupData.redirect)
                  : setPopupVisible(false)
              }}
            >
              Ok
            </ConfirmButton>
          </AlternativeDescription>
        </Popup>
      </>
    )
  }

  const isMobile = windowDimensions.width <= 1080
  return (
    <>
      <Header transparent />
      <LibrasAccessible />
      {renderPopup()}
      {!isBlind && isLightboxOpen && (
        <Lightbox
          mainSrc={img.src}
          onCloseRequest={() => setLightBoxOpen(false)}
        />
      )}
      <Content headerTransparent={isMobile}>
        {isMobile ? renderMobile() : renderDesktop()}
      </Content>
      {
        props.librasPlayerProps.url && isMobile && <Spacer />
      }
    </>
  )
}

const mapStateToProps = state => ({
  accessibility: state.AccessibilityReducer,
  librasPlayerProps: state.LibrasPlayerReducer
})

export default connect(mapStateToProps, null)(Entity)
