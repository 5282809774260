export const Colors = {
  purple: '#8513e8',
  lightPurple: '#a969e0',
  white: '#fff',
  black: '#000',
  darkBlue: '#002c57',
  darkBlueLighter: '#0051a1',
  blue: '#3742fa',
  blueLighter: '#5c65ff',
  green: '#00db9a',
  lightBlue: '#a6e9ff',
  gray: '#e0e0e0',
  red: '#ff8585',
  darkRed: '#ff5252',
  libras: '#004d92',
  yellow: '#ffff00'
}
