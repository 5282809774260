import React from 'react'
import { Popup } from './Popup'
import Lottie from 'react-lottie'
import { Fonts } from '../styles'
import useWindowDimensions from '../hooks/useWindowDimensions'
import styled from 'styled-components'
import { SecondaryButton } from './Button'
import { Accessible } from '.'
import { isMobile } from 'react-device-detect'

export function OkPopup ({ okButtonDescription, popupAnimation, popupData, isPopupVisible, onClick }) {
  const windowDimensions = useWindowDimensions()

  if (!isMobile) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <>
      <Popup visible={isPopupVisible}>
        <Description
          style={{ marginBottom: -25 }}
          windowDimensions={windowDimensions}
        >
          <Lottie
            options={{
              loop: popupAnimation.loop,
              autoplay: true,
              animationData: popupAnimation.src,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
            height={popupAnimation.size}
            width={popupAnimation.size}
            isStopped={false}
            isPaused={false}
            style={{
              marginBottom: popupAnimation.margin,
              marginTop: popupAnimation.margin
            }}
          />
          {popupData && popupData.texts && popupData.texts.map((data, index) =>
            (
              <Accessible libras={data.libras} key={index + 'reason'.substr(0, 5)}>
                <p style={{ paddingLeft: 0, paddingRight: 0 }}>{data.text}</p>
                {data.redirect}
              </Accessible>
            ))}
          <OkButton
            style={{ marginBottom: windowDimensions.width < 1024 ? 50 : 0 }}
            description={okButtonDescription}
            onClick={onClick}
          >
            Ok
          </OkButton>
        </Description>
      </Popup>
    </>
  )
}

const OkButton = styled(SecondaryButton)`
  width: 100%;
  margin: 0;
  margin-bottom: 25px;
  &:hover{
    transform: scale(1.02);
  }
`
export const Description = styled.div`
  text-align: center;
  transition: 0.5;
  font-family: ${Fonts.regular};
  & p {
    font-size: 20px;
    width: ${props => props.windowDimensions.width <= 1024 ? '100%' : '500px'};
    margin: 30px 0;
    padding: 20px;
  }
  margin: 25px;
  `
