import React from 'react'
import Home from '../pages/Home'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import BiomePicker from '../pages/BiomePicker'
import BiomeEntities from '../pages/BiomeEntities'
import Entity from '../pages/Entity'
import Quiz from '../pages/Quiz'
import Tale from '../pages/Tale/index'
import InstallPWA from '../components/InstallPWA'
import { LibrasPlayer, Accessibility } from '../components'
import ScrollToTop from './ScrollToTop'
import Info from '../pages/Info'

export default function Router () {
  return (
    <>
      <BrowserRouter>
        <InstallPWA />
        <LibrasPlayer />
        <ScrollToTop />
        <Accessibility />
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route exact path='/quiz'>
            <Quiz />
          </Route>
          <Route exact path='/quiz/:quizId'>
            <Quiz />
          </Route>
          <Route exact path='/conto/:biomeId'>
            <Tale />
          </Route>
          <Route exact path='/conto/'>
            <Redirect to='/biomas' />
          </Route>
          <Route exact path='/biomas'>
            <BiomePicker />
          </Route>
          <Route exact path='/info'>
            <Redirect to='/biomas' />
          </Route>
          <Route exact path='/info/:infoId'>
            <Info />
          </Route>
          <Route exact path='/bioma'>
            <Redirect to='/biomas' />
          </Route>
          <Route exact path='/bioma/:biome'>
            <Entity />
          </Route>
          <Route exact path='/bioma/:biome/:type/:entity'>
            <Entity />
          </Route>
          <Route exact path='/bioma/:biome/:type'>
            <BiomeEntities />
          </Route>
          <Route path='/**'>
            <Redirect to='/' />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  )
}
