import { AccessibilityType } from '../services/accessibilityType'

const INITIAL_STATE = {
  type: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'change_accessibility_type':
      if (!Object.keys(AccessibilityType).includes(action.payload)) {
        console.error('Accessibility type not found')
        return state
      }
      return { ...state, type: action.payload }
    default:
      return state
  }
}
