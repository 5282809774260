export default [
  {
    id: 'jabuticabeira',
    name: 'Jabuticabeira',
    nameLibras: 'https://youtu.be/AfrCGvNgtFk',
    thumb: require('../../entities/mata-atlantica/jabuticabeira_thumb.jpg'),
    images: [{
      id: 'jabuticabeira-1',
      description: 'Fotografia colorida do tronco de uma jabuticabeira carregada de frutos. Os galhos da jabuticabeira são lisos e tem coloração marrom com algumas manchas claras. Eles estão cobertos por frutos que brotam diretamente dos galhos e crescem rente ao tronco. As jabuticabas são frutinhas redondas e pequenas, com cerca de 2 centímetros de diâmetro. Elas têm casca lisa e cor roxa, quase preta, quando estão maduras. A maioria das frutinhas na fotografia estão maduras, mas há algumas poucas jabuticabas ainda na cor verde claro.',
      src: require('../../entities/mata-atlantica/jabuticabeira_thumb.jpg')
    }],
    content: [
      {
        libras: 'https://youtu.be/w7XA-8iafOU',
        text: 'A jabuticabeira (nome científico: <i>Myrciaria cauliflora</i>) é uma árvore frutífera típica da Mata Atlântica e é a responsável por nos oferecer as frutinhas deliciosas que conhecemos como jabuticaba. A fruta é redonda e pequena, com a casca preta e o interior branco e gosmento, com uma única semente. As jabuticabas crescem nos troncos lisos de cor marrom-claro e cobrem todos os galhos com frutinhas deliciosas.'
      },
      {
        libras: 'https://youtu.be/ONjth-cKKsg',
        text: 'O crescimento da jabuticabeira é lento, demorando até 10 anos até que nasçam as primeiras frutinhas (jabuticabas). Quando adultas, essas árvores atingem até 15 metros de altura.'
      },
      {
        libras: 'https://youtu.be/iCl38aIWpdU',
        text: 'Muitos animais, como macacos e pássaros, gostam de se alimentar de jabuticabas, assim como os seres humanos. Além disso, os insetos se alimentam das muitas flores brancas e pequenas que nascem da jabuticabeira. Por isso, essa árvore é muito importante para todos os bichinhos que vivem na Mata Atlântica.'
      }
    ]
  }, {
    id: 'cajueiro',
    name: 'Cajueiro',
    nameLibras: 'https://youtu.be/L2B9JchB5UQ',
    thumb: require('../../entities/mata-atlantica/cajueiro_thumb.jpg'),
    images: [{
      id: 'cajueiro-1',
      description: 'Fotografia colorida de dois cajus maduros. Os cajus estão pendurados em um galho fino, cercados por folhas bem verdes e ovaladas. Eles medem cerca de 10 centímetros de comprimento. Cada caju possui um fruto e um pseudofruto. O pseudofruto é a parte macia, maior e ovalada, com casca alaranjada de aspecto liso. Na parte inferior do pseudofruto está o fruto, pequeno com formato de meia lua. Ele é conhecido como castanha de caju, é marrom claro e tem aspecto levemente rugoso.',
      src: require('../../entities/mata-atlantica/cajueiro_thumb.jpg')
    }],
    content: [
      {
        libras: 'https://youtu.be/LzAJOgOv31M',
        text: 'O cajueiro (nome científico: <i>Anacardium occidentale</i>) é a árvore que forma o fruto chamado castanha de caju, que é pequeno, marrom claro, seco e com formato de meia lua. Essa castanha é confundida com semente, enquanto seu prolongamento carnoso, suculento e de coloração laranja é chamado de fruta, mas é na verdade um pseudofruto (que significa “falso fruto”). Ou seja, o caju é dividido em duas partes: o fruto verdadeiro que é a castanha de caju e o fruto falso que é a parte suculenta e amarelada e que também é comestível, com a qual podemos fazer o suco de caju.'
      },
      {
        libras: 'https://youtu.be/onAmhyoK_rM',
        text: 'A maioria das árvores de caju atingem 15 metros de altura e até mais ou menos 20 metros de largura das folhagens e galhos. O cajueiro é muito importante para os seres humanos por ser bastante utilizado na alimentação. A castanha de caju é o que comemos ou colocamos no sorvete e no bolo e, se a castanha passar por processos químicos, a casca dela pode virar remédios, tintas, plásticos, entre outras coisas.'
      },
      {
        libras: 'https://youtu.be/Q8dq77SaPaw',
        text: 'As flores do cajueiro são brancas e cremes quando se abrem, mas vão se tornando rosas e até vermelhas com o tempo. Elas são pequenas e possuem 5 pétalas compridas e finas.'
      },
      {
        libras: 'https://youtu.be/EjcDPpens0w',
        text: 'Você sabia que o maior cajueiro do mundo fica no Rio Grande do Norte (Brasil)? Ele produz cerca de 80 mil frutos por ano, é muito antigo (cerca de 120 anos), e é maior que um campo de futebol, crescendo cada vez mais a cada ano.'
      }
    ]
  }
]
