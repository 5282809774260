export default [
  {
    id: 'mico-leao-dourado',
    name: 'Mico-leão-dourado',
    nameLibras: 'https://youtu.be/__6lxgqH5LM',
    thumb: require('../../entities/mata-atlantica/mico-leao-dourado_thumb.jpg'),
    images: [{
      id: 'mico-leao-dourado-1',
      description: 'Fotografia colorida de um mico-leão-dourado num galho de uma árvore. O mico-leão-dourado é um macaco bem pequeno que mede cerca de vinte e cinco centímetros. Ele tem uma pelagem dourado-alaranjada, que cobre todo seu corpo, com exceção da pele rosada do rosto e dos dedos. Os pelos da cabeça são mais compridos e lembram uma juba de leão. Ele tem mãos e pés bem pequenos e parecidos com os nossos, com cinco dedos em cada pata. Seus olhos são redondos e marrons e seu nariz é formado por dois furinhos no centro do rosto. O mico-leão-dourado está no centro da fotografia, sentado no alto de uma árvore, com as quatro patas apoiadas no galho. Seu corpo está ligeiramente voltado para a esquerda.',
      src: require('../../entities/mata-atlantica/mico-leao-dourado.webp')
    }],
    audioSrc: require('../../sounds/mico-leao-dourado.mp3'),
    content: [
      {
        libras: 'https://youtu.be/_tiEh0uYfg0',
        text: 'O mico-leão-dourado (nome científico: <i>Leontopithecus rosalia</i>) é um macaco de pequeno porte, com aproximadamente 25 centímetros de altura e possui uma cauda longa que o ajuda a balançar nas árvores. Seu pelo chama a atenção pela cor dourada e alaranjada. De noite o mico dorme em cima das árvores, e durante o dia ele se alimenta de frutos, insetos e pequenos animais.'
      },
      {
        libras: 'https://youtu.be/7sP1MHpuXq4',
        text: 'São animais barulhentos e brincalhões. Vivem em grupos de até 14 indivíduos e, apesar de serem pequenos, eles precisam de muito espaço na floresta para comer e brincar. Eles podem viver até 15 anos na natureza.'
      },
      {
        libras: 'https://youtu.be/RMxcSS-6syY',
        text: 'Por conta do desmatamento, o mico-leão-dourado teve sua população quase extinta, e por isso hoje é um dos símbolos de conservação do Brasil.'
      },
      {
        libras: 'https://youtu.be/SHGdAiBvVTA',
        text: 'Você sabia que a gravidez das fêmeas de mico-leão-dourado dura mais ou menos 4 meses e meio? Outro fato curioso é que, na maioria das vezes, nascem gêmeos. O nascimento de filhos únicos e trigêmeos é mais raro. Não somente a mãe e o pai cuidam dos bebês, mas também todo o bando.'
      }
    ]
  },
  {
    id: 'sapo-cururu',
    name: 'Sapo-cururu',
    nameLibras: 'https://youtu.be/57DRahDuAUM',
    thumb: require('../../entities/mata-atlantica/sapo-cururu.webp'),
    images: [{
      id: 'sapo-cururu-1',
      description: 'Fotografia colorida de um sapo-cururu em um terreno rochoso. O sapo-cururu tem o corpo arredondado e mede cerca de 15 centímetros. Sua pele é bege com manchas marrons e tem o aspecto rugoso e seco.  Ele tem quatro patas arqueadas que saem da lateral de seu corpo. As dianteiras são curtas e as traseiras bem mais longas. Seus olhos são redondos e verdes e ficam opostos um ao outro no topo da cabeça. Atrás dos olhos há alguns furinhos que são as glândulas de veneno. O sapo está no centro da fotografia, virado para a esquerda, sobre uma pedra cercada por plantas rasteiras.',
      src: require('../../entities/mata-atlantica/sapo-cururu.webp')
    }],
    audioSrc: require('../../sounds/sapo-cururu.mp3'),
    content: [
      {
        libras: 'https://youtu.be/WR93Bl8aCro',
        text: 'O sapo-cururu (nome científico: <i>Rhinella marina</i>) é o sapo mais comum do Brasil. Ele possui glândulas de veneno na cabeça que o protegem do predador. Apenas quando se sente ameaçado, ele libera o veneno que faz mal quando ingerido. O sapo-cururu pode chegar até 15 centímetros. Ele é meio redondinho, com cor bege e manchas marrons. Sua pele é rugosa e seca, e ele não possui dentes. Então, para comer os insetos, ele libera sua língua muito comprida e grudenta para capturá-los. Animais maiores como camundongos e até mesmo algumas cobras, o sapo-cururu só engole. Abaixo da boca do macho tem uma pele que enche de ar e faz sons para chamar atenção da fêmea, competir por território e assustar predadores.'
      },
      {
        libras: 'https://youtu.be/6RN5eo42tls',
        text: 'O sapo-cururu pode morar praticamente em todos os ambientes, mas prefere os lugares com mais sombra e umidade para se manter fresquinho. Ele vai para perto de lagoas e rios quando vai ter seus filhotes, chamados girinos, que precisam da água para crescer. Eles conseguem viver na natureza até mais ou menos 15 anos.'
      },
      {
        libras: 'https://youtu.be/ykKGF00Tq7w',
        text: 'Ele não está ameaçado de extinção ainda, sendo muito importante para o equilíbrio da natureza. Por isso, é errado tentar matar os sapos que estão aqui para nos ajudar.'
      }
    ]
  },
  {
    id: 'tamandua-bandeira',
    name: 'Tamanduá-bandeira',
    nameLibras: 'https://youtu.be/ajhD8boFB9o',
    thumb: require('../../entities/mata-atlantica/tamandua-bandeira.webp'),
    images: [{
      id: 'tamandua-bandeira-1',
      description: 'Fotografia colorida de um tamanduá-bandeira num campo coberto de gramíneas. O tamanduá é um animal de quatro patas bem exótico com uma imensa cauda peluda e um focinho muito comprido, fino e cilíndrico. Ele mede cerca de 2 metros de comprimento. Seu tronco é coberto por pelos longos e escuros, com uma faixa preta e branca na diagonal. Seu rabo também tem pelos escuros, que são mais longos que o restante do corpo, arrastando no chão. Já suas pernas dianteiras têm pelos brancos, com uma faixa preta circundando a área próxima às patas. Ele tem garras muito grandes, fortes e curvadas. Suas orelhas, no topo da cabeça pequena, são pequenas e ovaladas e seus olhos são redondos e marrons. O tamanduá está no centro da fotografia em meio a um campo coberto por grama. Sua cabeça está voltada para baixo, ligeiramente voltada para a esquerda.',
      src: require('../../entities/mata-atlantica/tamandua-bandeira.webp')
    }],
    audioSrc: require('../../sounds/tamandua-bandeira.mp3'),
    content: [
      {
        libras: 'https://youtu.be/lHZi8ipn5vI',
        text: 'O tamanduá-bandeira (nome científico: <i>Myrmecophaga tridactyla</i>) é muito grande, medindo até 2 metros de comprimento. Ele possui pelos compridos e escuros com uma faixa preta de bordas brancas característico da espécie. Tem o focinho e a língua muito longos, que o ajudam a se alimentar de insetos como formigas e cupins. O tamanduá-bandeira enfia sua língua pegajosa no ninho de alguns insetos, capturando muitos deles.'
      },
      {
        libras: 'https://youtu.be/HNqUQu7305U',
        text: 'Geralmente, andam sozinhos ou em dupla: a mãe com seu filhote. São bons nadadores, e isso os ajuda a fugir de predadores. Moram em áreas com mato baixo ou florestas, porque gostam das sombras das árvores para se refrescar. Esses animais vivem mais ou menos 25 anos na natureza.'
      },
      {
        libras: 'https://youtu.be/GWdQkxck4qU',
        text: 'O tamanduá corre grande risco de extinção por conta do desmatamento, da agricultura, da construção de estradas e caça. Por isso, em alguns lugares, ele já foi extinto, como no Sul do Brasil e em algumas áreas da Mata Atlântica.'
      }
    ]
  }
]
