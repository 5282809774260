// README> QUESTION IDS MUST BE SEQUENTIALS

export default {
  id: 'cerrado',
  questions: [
    {
      id: 1,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/pIEEz11qZm0',
      text: 'Sobre o bioma do Cerrado, escolha a alternativa correta:',
      correctAnswer: [3],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/aQVPnIbDdXY',
            text: 'Os incêndios podem ser também de causas naturais, fazendo parte do ciclo de vida das plantas do Cerrado.'
          },
          libras: 'https://youtu.be/D1QJXtPvlmc',
          text: 'Os incêndios que ocorrem no Cerrado são sempre provocados pelo homem'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/v7mIqr2ZdiM',
            text: 'O Cerrado tem a vegetação menos densa que da Amazônia.'
          },
          libras: 'https://youtu.be/-C-hfwVKsiI',
          text: 'O Cerrado é um bioma igual ao da Amazônia, com floresta densa'
        },
        {
          id: 3,
          libras: 'https://youtu.be/VwxMPy85VnY',
          text: 'O Cerrado já perdeu muito da sua mata original pela ação humana'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/7RqpQW8ks8A',
            text: 'O Cerrado tem um clima quente e seco.'
          },
          libras: 'https://youtu.be/fKQKvQw-JrY',
          text: 'O Cerrado tem um clima úmido e frio'
        }
      ]
    },
    {
      id: 2,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/hMZuBM0QLh0',
      text: 'Sobre os animais vistos no Cerrado (anta, lobo-guará e seriema), escolha a alternativa correta:',
      correctAnswer: [1],
      alternatives: [
        {
          id: 1,
          libras: 'https://youtu.be/CaAzGND9iyQ',
          text: 'Dos animais citados, só a anta não come outros animais'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/0zLddmQMfO0',
            text: 'O lobo-guará que é parente dos cachorros.'
          },
          libras: 'https://youtu.be/0KwC1nQOHko',
          text: 'A seriema é parente dos cachorros'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/Ok5sd-qFe4Y',
            text: 'É a seriema que bota ovos e possui penas, pois ela é uma ave, já a anta não.'
          },
          libras: 'https://youtu.be/A7Sxw0mw0B4',
          text: 'A anta bota ovo e possui penas'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/lBa8Kj4YjtE',
            text: 'Quem é gordinho e tem uma pequena tromba é a anta.'
          },
          libras: 'https://youtu.be/pncNRAYHl14',
          text: 'O lobo-guará é gordinho e tem uma pequena tromba'
        }
      ]
    },
    {
      id: 3,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/Hr6Fj2L2hiQ',
      text: 'Sobre as plantas do Cerrado, escolha a alternativa correta:',
      correctAnswer: [4],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/-eTjCzKNFdU',
            text: 'O pau-papel tem esse nome porque tem o tronco branco e descasca em cascas finas que parecem um papel.'
          },
          libras: 'https://youtu.be/zMSJieUnjn8',
          text: 'O pau-papel tem esse nome porque é uma árvore que produz papel'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/XiY80_O5rC4',
            text: 'O pau-papel possui o tronco branco, por isso tem esse nome.'
          },
          libras: 'https://youtu.be/6WXuC06Sj2s',
          text: 'O pau-papel tem o tronco marrom, igual ao das outras árvores'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/cBgSNI3Zt-Q',
            text: 'As duas plantas possuem frutos.'
          },
          libras: 'https://youtu.be/K_ybSjFqbx0',
          text: 'O ipê-amarelo e o pau-papel não possuem frutos'
        },
        {
          id: 4,
          libras: 'https://youtu.be/K5-LfcxCKME',
          text: 'Em uma época do ano, as folhas caem dos galhos do ipê-amarelo, deixando somente as flores'
        }
      ]
    }
  ]
}
