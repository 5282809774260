import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from '.'
import defaultImage from '../assets/biomes/default.jpg'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { Colors } from '../styles'
import { GiSpellBook } from 'react-icons/gi'
import { IoMdDownload } from 'react-icons/io'
import { RoundedButton } from './Button'

export function Card ({ unlockedTale, unlockedDownload, taleOnClick, downloadOnClick, taleable, taleButtonDescription, downloadButtonDescription, buttonDescription, buttonStyle, imageDescription = '', cardStyle, onClick, accessible = true, imageStyle = {}, bottom = false, noButton = false, title = 'Jungle', thumb = defaultImage, redirectButtonUrl, buttonText = 'Botão', isGeneralQuizCard }) {
  const windowDimensions = useWindowDimensions()
  const [isLoaded, setLoaded] = useState(false)

  function renderCardContent () {
    return (
      <>
        {!noButton && (
          <>
            <SelectButton tabindex='1' description={buttonDescription} style={buttonStyle} bottom={bottom.toString()} onClick={onClick} to={redirectButtonUrl}>{title}</SelectButton>
            {taleable &&
              <TaleButton
                unlockedTale={unlockedTale}
                tabindex='1'
                description={taleButtonDescription}
                style={buttonStyle}
                bottom={bottom.toString()}
                onClick={taleOnClick}
              >
                <GiSpellBook />
              </TaleButton>}
            {isGeneralQuizCard &&
              <DownloadButton
                unlockedDownload={unlockedDownload}
                tabindex='1'
                description={downloadButtonDescription}
                style={buttonStyle}
                bottom={bottom.toString()}
                onClick={() => downloadOnClick()}
              >
                <IoMdDownload />
              </DownloadButton>}
          </>
        )}
        <Image
          alt={imageDescription}
          style={{ ...defaultImageStyle(windowDimensions, isLoaded), ...imageStyle }}
          onLoad={() => setLoaded(true)}
          draggable='false' src={thumb}
        />
      </>
    )
  }

  return (
    <>
      <CardWrapper style={cardStyle} windowDimensions={windowDimensions}>
        {renderCardContent()}
      </CardWrapper>
    </>
  )
}

const CardWrapper = styled.div`
  display: block;
  margin-top: 25px;
  background: ${Colors.white};
  min-height: 400px;
  max-height: 400px;
  border-radius: 20px;
  max-width: 100%;
  min-width: 100%;
`

const defaultImageStyle = (windowDimensions, isLoaded) => ({
  borderRadius: 20,
  margin: 0,
  display: isLoaded ? 'block' : 'none',
  minHeight: '400px',
  maxHeight: '400px',
  maxWidth: '100%',
  minWidth: '100%',
  textAlign: 'center',
  objectFit: 'cover'
})

const Image = styled.img`
  transition: 0.5s;
  box-shadow: 0px 10px 30px -8px rgba(0,0,0,0.75);
`

const SelectButton = styled(Button)`
  z-index: 30;
  position: absolute;
  overflow-wrap: break-word;
  max-width: 60%;
  min-width: 80%;
  left: 50%;
  bottom: ${(props) => props.bottom === 'true' ? '13%' : '39%'};
  margin: 0;
  padding: 10px 0;
  transform: translate(-50%);
  transform-origin: 0% 50%;
  &:hover {
    transform: scale(1.2) translate(-50%);
  }
`

const TaleButton = styled(RoundedButton)`
  z-index: 30;
  position: absolute;
  overflow-wrap: break-word;
  left: 50%;
  background: ${({ unlockedTale }) => unlockedTale ? Colors.green : Colors.gray};
  opacity: ${({ unlockedTale }) => unlockedTale ? 1 : 0.6};
  color: ${Colors.black};
  bottom: ${(props) => props.bottom === 'true' ? '0%' : '14%'};
  margin: 0;
  padding: 10px 0;
  transform: translate(-50%);
  transform-origin: 0% 50%;
  &:hover {
    transform: scale(1.2) translate(-50%);
  }
`

const DownloadButton = styled(RoundedButton)`
  z-index: 30;
  position: absolute;
  overflow-wrap: break-word;
  left: 50%;
  background: ${({ unlockedDownload }) => unlockedDownload ? Colors.green : Colors.gray};
  opacity: ${({ unlockedDownload }) => unlockedDownload ? 1 : 0.6};
  color: ${Colors.black};
  bottom: ${(props) => props.bottom === 'true' ? '0%' : '14%'};
  margin: 0;
  padding: 10px 0;
  transform: translate(-50%);
  transform-origin: 0% 50%;
  &:hover {
    transform: scale(1.2) translate(-50%);
  }
`
