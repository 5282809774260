import React from 'react'
import styled from 'styled-components'
import { Colors, Fonts } from '../styles'
import { useHistory } from 'react-router-dom'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { connect } from 'react-redux'
import { changePlayerVideo } from '../actions/LibrasPlayerActions'
import { TiChevronLeft } from 'react-icons/ti'

function Header ({ style, transparent, title, children, onClick, changePlayerVideo }) {
  const windowDimensions = useWindowDimensions()
  const history = useHistory()

  function goBack () {
    try {
      changePlayerVideo(null)
      const currentPath = history.location.pathname
      const newPath = currentPath.split('/')
      newPath.pop()
      history.replace(newPath.join('/'))
    } catch (e) {
      console.log(e)
      history.replace('/')
    }
  }
  if (title) {
    return (
      <Container transparent={transparent} style={style}>
        <Title windowWidth={windowDimensions.width} style={{ fontFamily: Fonts.title, fontSize: 50 }}>{children}</Title>
      </Container>
    )
  }

  // TODO add acessibility to blind
  return (
    <Container transparent={transparent} style={style}>
      <LeftButton aria-label='Voltar para a tela anterior' transparent={transparent} windowWidth={windowDimensions.width} onClick={onClick || (() => goBack())}><TiChevronLeft /></LeftButton>
      <Title windowWidth={windowDimensions.width}>{children}</Title>
    </Container>
  )
}

export default connect(null, { changePlayerVideo })(Header)

const Title = styled.h1`
  text-align: center;
  font-size: ${({ windowWidth }) => windowWidth <= 900 ? '29px' : '50px'};
  color: ${Colors.darkBlue};
  width: 67%;
  margin-left: 50%;
  transform: translateX(-50%);
  line-height: 70px;
  font-family: ${Fonts.cool};
`

const Container = styled.nav`
  line-height: 70px;
  height:${({ transparent }) => !transparent ? '70px' : '100px'};
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 100;
  background-color: ${({ transparent }) => !transparent ? Colors.lightBlue : 'transparent'};
  overflow: hidden;
  `

const LeftButton = styled.button`
  background-color: ${Colors.lightBlue};
  width: ${({ windowWidth }) => windowWidth <= 900 ? 'auto' : '100px'};
  height: 60px; 
  width: 60px;
  position: absolute;
  text-align: left;
  box-shadow: ${({ transparent }) => transparent ? '0px 10px 20px -8px rgba(0,0,0,0.45)' : 'none'};

  color: ${Colors.darkBlue};
  left: 10px;
  top: 5px;
  font-size: 60px;
  font-family: ${Fonts.regular};
  padding: 0;
  margin: 0;
  padding-bottom: 0;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 50px;
  z-index: ${({ transparent }) => transparent ? '1000' : ''};
  &:hover{
    opacity: 0.6;
    transform: scale(1.1) translateX(2%);
  }
`
