export default
{
  id: 'tale-inicial',
  name: 'Conto inicial',
  nameLibras: 'https://youtu.be/squKf741saY',
  images: [],
  audioSrc: require('../../../sounds/tales/conto-inicial.mp3'),
  videoSrc: 'https://www.youtube.com/embed/W0Q7nbVzVog',
  content: [
    {
      code: 'ct-01',
      libras: 'https://youtu.be/_aiQCTz4F2E',
      text: 'Oi, pessoal, tudo bem? Meu nome é Iara e hoje vou contar para vocês a história do meu povo.'
    },
    {
      code: 'ct-02',
      libras: 'https://youtu.be/qYSWtG-7lRQ',
      text: 'Venho de um povo indígena chamado Heni, que significa “planta com poderes mágicos”. Nós temos esse nome porque a minha aldeia sempre teve muito amor e respeito pelas plantas. Acreditamos que cada vez que alguém da comunidade deixa essa vida, os espíritos permanecem nas plantas. Eles nos guiam com seus ensinamentos e protegem o nosso povo.'
    },
    {
      code: 'ct-03',
      libras: 'https://youtu.be/sD_wIjJnF_o',
      text: 'Um belo dia, um homem com espírito explorador decidiu viajar pelo Brasil. Seu nome era Rudá, meu bisavô. Ele gostava muito de conhecer coisas novas, mas nunca tinha saído da Amazônia. Então, ele partiu para sua viagem e, em cada região por onde passou, fez um amigo muito especial, que em agradecimento pela sua amizade deu para ele de presente uma semente. Depois de muito andar, resolveu retornar para a Amazônia, pois, apesar de ter conhecido lugares incríveis e lindos em todo o Brasil, começou a sentir falta do lugar onde nasceu e cresceu. Ao voltar da viagem, ele achou um lugar especial para plantar as 6 sementes que ganhou. Elas foram plantadas em um único buraco, com muito carinho e amor, e deram origem a nossa bela árvore, que é o nosso maior tesouro.'
    },
    {
      audioDescription: 'teste',
      style: {
        maxHeight: '600px',
        maxWidth: '600px'
      },
      image: 'https://iaraapp.sirv.com/iara/contos/inicial/indio-plantando.png?w=600&h=600&format=webp'
    },
    {
      code: 'ct-04',
      libras: 'https://youtu.be/iLKOzSKkAlU',
      text: 'A árvore produz todo tipo de fruto que a gente imagina, basta chegar perto dela e pedir assim: <br/><br/>Na nossa aldeia Heni, tem a árvore mágica plantada por Rudá, <br/>Os nossos ancestrais que a habitam, estão lá para nos guiar <br/>Agora que estou diante dela pergunto: <br/>Ó grande e poderosa árvore, uma fruta pode me dar?'
    },
    {
      code: 'ct-05',
      libras: 'https://youtu.be/6ym-mBMvy4w',
      text: 'Depois dessa frase, nós escolhemos a fruta que queremos e falamos alto o nome dela. Fechamos os olhos, estendemos as duas mãos e pronto! Quando abrimos os olhos a fruta já está nas nossas mãos.'
    },
    {
      audioDescription: 'teste',
      style: {
        maxHeight: '600px',
        maxWidth: '600px'
      },
      image: 'https://iaraapp.sirv.com/iara/contos/inicial/indios-arvore.png?w=600&h=600&format=webp'
    },
    {
      code: 'ct-06',
      libras: 'https://youtu.be/i_b4ZBgCntQ',
      text: 'Nossa comunidade era muito feliz e unida. Mas, um dia, um grupo de lenhadores ficou sabendo dos poderes mágicos da nossa árvore e, movidos pela ganância e pela inveja, foram atrás dela. Durante uma noite, enquanto todos estavam dormindo, eles invadiram nossa aldeia e cortaram a nossa árvore, espantando todos os espíritos para fora dela e de todas as outras plantas.'
    },
    {
      code: 'ct-07',
      libras: 'https://youtu.be/gB1Y8uB41tY',
      text: 'Acordamos assustados no dia seguinte, ouvindo um vento muito forte e fomos ver o que havia acontecido. Vimos que a nossa árvore tinha sido cortada e ficamos todos muito tristes, afinal ela fazia parte da nossa história! “Quem seria capaz de fazer isso?”, era o que todos da aldeia estavam se perguntando.'
    },
    {
      code: 'ct-08',
      libras: 'https://youtu.be/-SbyuKI68U4',
      text: 'Entre tantos barulhos, eu consegui escutar uma voz me chamando: <br/><br/>- Iara, bisneta de Rudá, para ajudar seu povo, numa viagem deve partir. Nós, seus ancestrais, estaremos com você ao longo de todo o caminho. Ao se aproximar das sementes, elas vão brilhar para você as encontrar. <br/><br/>Os ancestrais estavam me chamando para uma missão.'
    },
    {
      audioDescription: 'teste',
      style: {
        maxHeight: '600px',
        maxWidth: '600px'
      },
      image: 'https://iaraapp.sirv.com/iara/contos/inicial/iara-zangada.png?w=600&h=600&format=webp'
    },
    {
      code: 'ct-09',
      libras: 'https://youtu.be/Gg_8VshKEC0',
      text: 'Então, parti para uma viagem pelo Brasil, assim como meu bisavô Rudá fez. Vou coletar as sementes e plantar novamente nossa árvore mágica! Vamos viajar comigo?'
    }
  ]
}
