// README> QUESTION IDS MUST BE SEQUENTIALS

export default {
  id: 'pantanal',
  questions: [
    {
      id: 1,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/pXVnWLsKWM4',
      text: 'Sobre o bioma do Pantanal, escolha a alternativa correta:',
      correctAnswer: [4],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/pD13jAWNeHQ',
            text: 'As plantas do Pantanal são adaptadas para viverem debaixo da água, pois lá os alagamentos são constantes.'
          },
          libras: 'https://youtu.be/v81GrGs36YU',
          text: 'As plantas do Pantanal são adaptadas para conseguirem sobreviver ao clima seco, comum da região'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/-o6MEWpsQa8',
            text: 'O Pantanal fica alagado em uma parte do ano, geralmente entre os meses de Dezembro e Janeiro.'
          },
          libras: 'https://youtu.be/wCeYeft868s',
          text: 'O Pantanal está sempre alagado'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/mBGAD2W1Fow',
            text: 'A maioria das plantas que existem no Pantanal vem dos outros biomas brasileiros.'
          },
          libras: 'https://youtu.be/To8naLmzd1U',
          text: 'A maioria das plantas do Pantanal só existem lá'
        },
        {
          id: 4,
          libras: 'https://youtu.be/CvJT0bmoHBY',
          text: 'A vegetação do Pantanal é organizada no que chamamos de “Capão” (manchas circulares de vegetação)'
        }
      ]
    },
    {
      id: 2,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/tpdYeAIR4Ak',
      text: 'Sobre os animais do Pantanal (tuiuiú, arara-azul e jacaré), escolha a alternativa correta:',
      correctAnswer: [2],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/D_BStsjH9nc',
            text: 'A arara tem o bico em formato de gancho e se alimenta de frutos. É o tuiuiú que possui o bico grande para se alimentar de peixes.'
          },
          libras: 'https://youtu.be/yT7XEWlzP-w',
          text: 'A arara-azul tem o bico muito grande e comprido para comer peixes'
        },
        {
          id: 2,
          libras: 'https://youtu.be/hor2cjalEnY',
          text: 'Os tuiuiús são aves que vivem em casais. Para encontrarem seus parceiros, eles realizam danças e batem os bicos uns nos outros'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/o-zxeX_Odl0',
            text: 'Os jacarés gostam de tomar sol, pois seu corpo não consegue se aquecer sozinho sem a ajuda do calor do sol. Somente quando sentem fome eles vão para a água procurar alimentos.'
          },
          libras: 'https://youtu.be/WC-c7AHSuUA',
          text: 'Os jacarés nunca tomam sol, porque gostam de viver sempre debaixo da sombra e debaixo da água'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/kGYhtfqKPlI',
            text: 'É o tuiuiú que é o símbolo do Pantanal, por ser a maior ave da região.'
          },
          libras: 'https://youtu.be/rNqgNFTXo8w',
          text: 'A arara-azul é considerada o símbolo do Pantanal'
        }
      ]
    },
    {
      id: 3,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/3ZjiwBcEfwg',
      text: 'Sobre as plantas do Pantanal (palmeira acuri e piúva), escolha a alternativa correta:',
      correctAnswer: [1],
      alternatives: [
        {
          id: 1,
          libras: 'https://youtu.be/GV4P_sw-B20',
          text: 'O fruto da piúva é em formato de vagem e possuem tricomas (pelinhos)'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/vanK00ZBEBU',
            text: 'As flores da palmeira acuri são amarelas.'
          },
          libras: 'https://youtu.be/9ojlDqnSBjc',
          text: 'As flores da palmeira acuri são roxas'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/74Xe-5sPTfw',
            text: 'A palmeira acuri tem um fruto chamado acuri, que faz parte da alimentação das araras-azuis.'
          },
          libras: 'https://youtu.be/vNIbGFE1tpo',
          text: 'O piúva tem um fruto que faz parte da alimentação das araras-azuis'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/_8-8gTEoVhA',
            text: 'É a piúva que é chamada de ipê-roxo.'
          },
          libras: 'https://youtu.be/nmT2OAAGR_k',
          text: 'A palmeira acuri é chamada de ipê-roxo'
        }
      ]
    }
  ]
}
