export default [
  {
    id: 'pau-papel',
    name: 'Pau-papel',
    nameLibras: 'https://youtu.be/u48ZxS4suu8',
    thumb: require('../../entities/cerrado/pau-papel.webp'),
    images: [{
      id: 'pau-papel-1',
      description: 'Fotografia colorida de um pau-papel. O pau-papel é uma árvore de pequeno porte com cerca de 2 metros e meio de altura. Seu tronco é branco, torto e muito ramificado, com o aspecto de que está descascando em finas lâminas que lembram papel de seda. Suas folhas são verdes e, entre elas, há algumas flores rosas. O pau-papel está no centro da fotografia num terreno montanhoso e rochoso. Ao seu redor há uma grande diversidade de plantas rasteiras. Ao fundo, estão um vale e o céu iluminado pela luz do sol.',
      src: require('../../entities/cerrado/pau-papel.webp')
    }],
    content: [
      {
        libras: 'https://youtu.be/eJIwVTQu-mE',
        text: 'O pau-papel (nome científico: <i>Tibouchina papyrus</i>) é uma árvore que só é encontrada no Cerrado. Tem aproximadamente 2 metros e meio de altura. Durante a estação chuvosa, as flores rosas do pau-papel florescem em grande quantidade e elas possuem 5 pétalas. Já na estação seca do Cerrado, as flores e folhas caem, ficando apenas os galhos secos da árvore e frutos. O pau-papel tem esse nome porque seu tronco é branco e descasca em lâminas finas que parecem papel no período da seca do Cerrado. A cor branca do tronco é por causa de uma substância que protege o pau-papel da seca. Apesar do seu nome, essa árvore não é utilizada para a produção de papel.'
      }
    ]
  },
  {
    id: 'ipe-amarelo',
    name: 'Ipê-amarelo',
    nameLibras: 'https://youtu.be/q2X61eQeLmo',
    thumb: require('../../entities/cerrado/ipe-amarelo_thumb.jpg'),
    images: [{
      id: 'ipe-amarelo-1',
      description: 'Fotografia colorida de um ipê-amarelo. O ipê-amarelo é árvore com cerca de 14 metros de altura. Seu tronco é marrom, fino e comprido. Sua copa é formada por muitos cachos de flores amarelo-douradas que contrastam com o azul do céu de um dia ensolarado e com poucas nuvens. O ipê-amarelo está no centro da fotografia em meio a um grande gramado. Ao fundo, algumas árvores estão próximas de uma cerca baixa que separa o gramado do terreno de uma casinha laranja.',
      src: require('../../entities/cerrado/ipe-amarelo_thumb.jpg')
    }],
    content: [
      {
        libras: 'https://youtu.be/cQdjrTfyU1A',
        text: 'O ipê-amarelo (nome científico: <i>Handroanthus ochraceus</i>) é muito diferente da maioria das árvores, porque quando as flores nascem no início da primavera, as folhas caem dos galhos. A árvore fica somente com flores amarelas.  Por esse motivo, muitas pessoas plantam para deixar seus jardins mais bonitos.'
      },
      {
        libras: 'https://youtu.be/0AxFGIZaROI',
        text: 'Seu fruto é do formato de uma vagem com alguns pelinhos. Quando secos, as sementes (com uma aba que parece uma asa) são liberadas, permitindo seu transporte pelo vento, até encontrarem um lugar para nascer, crescer e florescer.'
      },
      {
        libras: 'https://youtu.be/Ap8AKBaexq0',
        text: 'A árvore pode chegar até 14 metros de altura. Sua madeira é muito dura e usada pelos humanos para fazerem brinquedos e objetos de madeira, entre outras coisas.'
      }
    ]
  }
]
