import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Header, Accessible, LibrasAccessible, AudioPlayer, VideoIframe } from '../../components'
import { Title, Content, InfoImage, InfoImageDesktop, Spacer, ContentImage, Text, TextContainer, TitleDesktop, ImageInfo, AudioPlayerWrapper } from './styles'
import { getInfoById } from '../../services/info'
import { AccessibilityType } from '../../services/accessibilityType'
import { connect } from 'react-redux'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import Lightbox from 'react-image-lightbox'
import Parser from 'html-react-parser'

function Info (props) {
  const [isLightboxOpen, setLightBoxOpen] = useState(false)
  const [currentImageOnLightbox, setImageOnLightbox] = useState(null)
  const windowDimensions = useWindowDimensions()
  const history = useHistory()
  const params = useParams()
  const info = getInfoById(params.infoId || props.infoId)
  const isBlind = props.accessibility.type === AccessibilityType.audioDescription
  const [isAudioPresent, setIsAudioPresent] = useState(true)
  const isTale = props.isTale

  useEffect(() => {
    if (info.audioSrc === undefined) {
      setIsAudioPresent(false)
    }
  }, [])

  if (!info) {
    history.goBack()
    window.alert('Essa informação não existe: ' + params.infoId)
    return null
  }

  const { name, nameLibras, id } = info
  const img = info.images[0]

  function renderContent () {
    return (
      <>
        {info.content.map((content, index) => {
          if (content.image) {
            return renderContentImage(content, index)
          } else {
            return renderText(content, index)
          }
        })}
      </>
    )
  }

  function renderContentImage (content, index) {
    return (
      <ContentImage
        key={index + '-content-image'}
        style={{ ...content.style }}
        onClick={() => {
          setImageOnLightbox(content.image)
          setLightBoxOpen(true)
        }}
        src={content.image} alt={content.audioDescription}
      />
    )
  }

  function renderText (content, index) {
    return (
      <Accessible
        libras={content.libras}
        key={index + content.text.slice(10)}
      >
        <Text windowDimensions={windowDimensions}>
          {Parser(content.text)}
        </Text>
      </Accessible>
    )
  }

  function renderMobile () {
    return (
      <>
        {img && (
          <>
            <InfoImage
              onClick={() => {
                setImageOnLightbox(img.src)
                setLightBoxOpen(true)
              }}
              windowDimensions={windowDimensions}
              key={img.id.toString().toUpperCase()}
              alt={img.description}
              tabIndex='-1'
              draggable='false'
              src={img.src}
            />
            <ImageInfo>Clique na imagem para ver em tela cheia</ImageInfo>
          </>
        )}
        {!img && <div style={{ height: '100px' }} />}
        <Accessible
          libras={nameLibras}
          key={id}
        >
          <Title>{name}</Title>
          <AudioPlayerWrapper>
            {props.accessibility.type === AccessibilityType.none && isAudioPresent && isTale && <AudioPlayer audioSrc={info.audioSrc} />}
          </AudioPlayerWrapper>
        </Accessible>
        {isBlind && isTale ? <VideoIframe title={info.name} src={info.videoSrc} /> : renderContent()}
      </>
    )
  }

  function renderDesktopContent () {
    return (
      <>
        <Accessible
          libras={nameLibras}
          key={id}
        >
          <TitleDesktop>{name}</TitleDesktop>
          <AudioPlayerWrapper>
            {props.accessibility.type === AccessibilityType.none && isAudioPresent && isTale && <AudioPlayer audioSrc={info.audioSrc} />}
          </AudioPlayerWrapper>
        </Accessible>
        {isBlind && isTale ? <VideoIframe title={info.name} src={info.videoSrc} /> : renderContent()}
      </>
    )
  }

  function renderDesktop () {
    return (
      <>
        {img ? (<TextContainer>{renderDesktopContent()}</TextContainer>) : renderDesktopContent()}
        {img && (
          <InfoImageDesktop
            tabIndex='-1'
            onClick={() => {
              setImageOnLightbox(img.src)
              setLightBoxOpen(true)
            }}
            draggable='false'
            desktop
            windowDimensions={windowDimensions}
            key={img.id}
            alt={img.description}
            src={img.src}
          />
        )}
      </>
    )
  }

  const isMobile = windowDimensions.width <= 1080
  return (
    <>
      <Header transparent />
      <LibrasAccessible />

      {!isBlind && isLightboxOpen && (
        <Lightbox
          mainSrc={currentImageOnLightbox}
          onCloseRequest={() => setLightBoxOpen(false)}
        />
      )}
      <Content headerTransparent={isMobile}>

        {isMobile ? renderMobile() : renderDesktop()}
      </Content>
      {
        props.librasPlayerProps.url && isMobile && <Spacer />
      }
    </>
  )
}

const mapStateToProps = state => ({
  accessibility: state.AccessibilityReducer,
  librasPlayerProps: state.LibrasPlayerReducer
})

export default connect(mapStateToProps, null)(Info)
