// README> QUESTION IDS MUST BE SEQUENTIALS

export default {
  id: 'mata-atlantica',
  questions: [
    {
      id: 1,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/M2gI0-lKXGg',
      text: 'Sobre o bioma da Mata Atlântica, escolha a alternativa falsa:',
      correctAnswer: [2],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/LtKjKTevcVs',
            text: 'A informação dessa alternativa está correta! Mas lembre-se de que estamos perguntando qual é a alternativa falsa.'
          },
          libras: 'https://youtu.be/-4-brKZULzQ',
          text: 'A Mata Atlântica é um dos principais biomas desmatados'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/DvH_f4jSicE',
            text: 'Parabéns, resposta correta! Essa é a alternativa falsa porque, na verdade, a vegetação da Mata Atlântica tem árvores baixas e altas.'
          },
          libras: 'https://youtu.be/eGe149xHSkU',
          text: 'A vegetação da Mata Atlântica só tem árvores baixas'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/LtKjKTevcVs',
            text: 'A informação dessa alternativa está correta! Mas lembre-se de que estamos perguntando qual é a alternativa falsa.'
          },
          libras: 'https://youtu.be/tJYmOlUlR4Y',
          text: 'A Mata Atlântica possui o clima semelhante ao da Amazônia'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/LtKjKTevcVs',
            text: 'A informação dessa alternativa está correta! Mas lembre-se de que estamos perguntando qual é a alternativa falsa.'
          },
          libras: 'https://youtu.be/9QyX-NWNasc',
          text: 'A Mata Atlântica é localizada principalmente na região litorânea do Brasil'
        }
      ]
    },
    {
      id: 2,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/jh5iGdfFnb8',
      text: 'Sobre a alimentação dos animais da Mata Atlântica (mico-leão-dourado, tamanduá-bandeira e sapo-cururu), escolha a alternativa correta:',
      correctAnswer: [4],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/P7Xdfce9yNk',
            text: 'O sapo-cururu que come cobras, enquanto o tamanduá come insetos.'
          },
          libras: 'https://youtu.be/CYdbItMoSF4',
          text: 'Somente o tamanduá come cobras'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/ok_9qUeYpd0',
            text: 'Dos três animais, somente o mico-leão-dourado come fruto.'
          },
          libras: 'https://youtu.be/pKHM_H07xHI',
          text: 'Todos os três animais comem frutos'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/ideXI6-pdNw',
            text: 'Todos os três animais comem insetos.'
          },
          libras: 'https://youtu.be/Ynnw06nKCWE',
          text: 'Somente o sapo-cururu come inseto'
        },
        {
          id: 4,
          libras: 'https://youtu.be/ideXI6-pdNw',
          text: 'Todos os três animais comem insetos'
        }
      ]
    },
    {
      id: 3,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/A8w7Dl2X8EM',
      text: 'Sobre as plantas da Mata Atlântica (cajueiro e jabuticabeira), escolha a alternativa correta:',
      correctAnswer: [3],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/qAr1DXv39qA',
            text: 'A castanha de caju é, na verdade, o fruto verdadeiro, enquanto o fruto falso é a parte carnosa e maior que dá origem ao suco.'
          },
          libras: 'https://youtu.be/Ndo8XXvUwzc',
          text: 'O pseudofruto, ou fruto falso, do caju é a parte seca e pequena chamada castanha de caju'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/3b0ZNAaet9M',
            text: 'O crescimento da jabuticabeira é lento, e as primeiras frutinhas podem demorar 10 anos para nascer.'
          },
          libras: 'https://youtu.be/bUI5NkGW5gs',
          text: 'A jabuticabeira é uma planta que cresce muito rápido'
        },
        {
          id: 3,
          libras: 'https://youtu.be/RgherdLZay0',
          text: 'A jabuticaba é uma fruta redonda e pequena, com a casca preta e o interior branco e gosmento, com uma única semente'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/liQlG9OLnL4',
            text: 'A casca do caju é amarelada, e sua semente não é a “castanha de caju”, este é o que chamamos de fruto verdadeiro e fica na parte externa do caju'
          },
          libras: 'https://youtu.be/d4MeM5gSICI',
          text: 'A casca do caju é roxa com uma semente dentro, que chamamos de “castanha de caju”'
        }
      ]
    }
  ]
}
