import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Router from './routes/Router'
import ServiceWorkerWrapper from './ServiceWorkerWrapper'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducers from './reducers'
import 'react-image-lightbox/style.css'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={createStore(reducers)}>
      <ServiceWorkerWrapper />
      <Router />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
