import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { isQuizCompleted } from '../../services/quiz'
import Info from '../Info'

export default function Tale (props) {
  const params = useParams()
  const history = useHistory()

  const { biomeId } = params

  useEffect(() => {
    async function checkLock () {
      const isUnlocked = biomeId === 'inicial' ? true : await isQuizCompleted(biomeId)
      if (!isUnlocked) history.goBack()
    }

    checkLock()
    return () => {}
  }, [])

  return <Info infoId={'tale-' + biomeId} isTale />
}
