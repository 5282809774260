export default [
  {
    id: 'pica-pau-chorao',
    name: 'Pica-pau-chorão',
    nameLibras: 'https://youtu.be/FMerbAQo68U',
    thumb: require('../../entities/pampa/pica-pau-chorao_thumb.jpg'),
    images: [{
      id: 'pica-pau-chora0-1',
      description: 'Fotografia colorida de um pica-pau-chorão agarrado ao tronco de uma árvore. O pica-pau-chorão é uma ave pequena, de corpo arredondado, que mede cerca de 15 centímetros de comprimento. Nas costas e nas asas ele tem penas pretas com pintinhas brancas e na barriga, o contrário, penas brancas com pintinhas pretas. Já no topo da cabeça, suas penas são vermelhas. Seu bico é fino e mede cerca de 2 centímetros. Seus olhos são pequenos, redondos e pretos. Na foto, o pica-pau está à esquerda, agarrado ao tronco vertical de uma árvore, que está á direita. Seus dedos finos e suas unhas compridas o ajudam a se fixar no tronco fino e marrom.',
      src: require('../../entities/pampa/pica-pau-chorao_thumb.jpg')
    }],
    audioSrc: require('../../sounds/pica-pau-chorao.mp3'),
    content: [
      {
        libras: 'https://youtu.be/waeUGQLoS9s',
        text: 'O pica-pau-chorão (nome científico: <i>Veniliornis mixtus</i>) é uma ave que possui até 15 centímetros de comprimento, com a barriga branca de pintinhas pretas e as costas e asas pretas de pintinhas brancas. Por causa da sua coloração e das suas pintinhas, o pica-pau-chorão se camufla nas árvores, confundindo os predadores.'
      },
      {
        libras: 'https://youtu.be/URHggo4P54o',
        text: 'Apenas o macho tem um topete vermelho. A ave tem o bico próprio para comer sementes e insetos, como formigas e cupins. Quando um macho e uma fêmea se apaixonam, o casal faz um ninho dentro dos troncos finos e secos das árvores, que servem como abrigo para os pais e filhotes. Eles conseguem viver na natureza até 10 anos. Mas, por conta dos predadores, como cobras e águias, pode ser que vivam menos tempo. Além disso, os humanos estão cada vez mais destruindo suas casinhas, fazendo com que seja difícil sobreviver em seu ambiente natural.'
      }
    ]
  },
  {
    id: 'sapo-barriga-vermelha',
    name: 'Sapo-de-barriga-vermelha',
    nameLibras: 'https://youtu.be/TwBaeJ4SssA',
    thumb: require('../../entities/pampa/sapo-barriga-vermelha_thumb.jpg'),
    images: [{
      id: 'sapo-barriga-vermelha-1',
      description: 'Fotografia colorida de um sapo-de-barriga-vermelha em meio à mata. O sapo-de-barriga-vermelha é um animal muito pequeno que lembra uma perereca e mede cerca de 3 centímetros de comprimento. Ele tem uma pele brilhante e bem colorida. Suas costas são verdes, com algumas protuberâncias arredondadas num tom mais claro. A parte superior de sua barriga é preta com bolinhas também verdes claras. Já a parte inferior da sua barriga e seus dedos são vermelhos. Os olhos do sapo são grandes, arredondados e marrons. Seu nariz são dois pequenos furinhos na frente de seu rosto. Suas patas são arqueadas e possuem três dedos cada. O sapo está no centro da foto, virado para a direita, sobre um terreno coberto por musgos verdes e úmidos.',
      src: require('../../entities/pampa/sapo-barriga-vermelha_thumb.jpg')
    }],
    audioSrc: require('../../sounds/sapo-barriga-vermelha.mp3'),
    content: [
      {
        libras: 'https://youtu.be/fBNbDLJ2W-8',
        text: 'O sapo-de-barriga-vermelha (nome científico: <i>Melanophryniscus admirabilis</i>) tem a barriga vermelha, como o nome já diz, e suas costas são verdes para se camuflar nas folhas. Cada sapinho tem um padrão de cor diferente, que é como nossa impressão digital, diferenciando cada indivíduo. O sapo-de-barriga-vermelha é bem pequeno, medindo aproximadamente 3 centímetros de comprimento.'
      },
      {
        libras: 'https://youtu.be/mNHtn72N5yk',
        text: 'O sapinho prefere viver em ambientes com sombra e umidade. Para chamar atenção da fêmea, os machos cantam. Os ovos ficam em poças de água e, depois de um tempo, viram girinos, que parecem uns ovinhos com caudas, e depois se tornam sapinhos. Com sua língua comprida e gosmenta, se alimentam de pequenos insetos. Eles conseguem viver na natureza durante mais ou menos 10 anos.'
      },
      {
        libras: 'https://youtu.be/ke5SPUnsqJE',
        text: 'Esse sapinho só existe no Pampa, nas margens e mata próxima aos rios, e por isso é importante preservar esse bioma. Caso contrário, esse animal pode desaparecer.'
      }
    ]
  },
  {
    id: 'tuco-tuco',
    name: 'Tuco-tuco',
    nameLibras: 'https://youtu.be/Uul9eQPc84s',
    thumb: require('../../entities/pampa/tuco-tuco_thumb.jpg'),
    images: [{
      id: 'tudo-tuco-1',
      description: 'Fotografia colorida de um tuco-tuco na margem de um rio. O tuco-tuco é um animal que lembra um ratinho e mede cerca de 25 centímetros de comprimento. Ele tem a maior parte do corpo coberto por pelos brancos e macios, com exceção da pele rosada das patinhas, do nariz e do rabo. Suas orelhas são redondas e muito pequenas e seus olhos são pequenos, arredondados e pretos. Acima do seu nariz, localizado na ponta do focinho curto e achatado, ele tem bigodes brancos, finos e longos. O tuco-tuco está no centro da fotografia, voltado para a esquerda, na areia clarinha da margem de um rio.',
      src: require('../../entities/pampa/tuco-tuco_thumb.jpg')
    }],
    audioSrc: require('../../sounds/tuco-tuco.mp3'),
    content: [
      {
        libras: 'https://youtu.be/ahJ15NHJDEo',
        text: 'Os tuco-tucos (nome científico: <i>Ctenomys flamarioni</i>) são roedores, ou seja, da família dos ratos, sendo, portanto, parecidos com eles, mas suas caudas e orelhas são menores. Medem 25 centímetros de comprimento. Seus dentes são grandes, duros e ficam para fora de sua boca. Eles têm esse nome por causa do barulho que emitem. Se alimentam de gramas e raízes. Conseguem viver mais ou menos 5 anos na natureza.'
      },
      {
        libras: 'https://youtu.be/U2rxPf5eKVU',
        text: 'Os tuco-tucos vivem sozinhos em cima e embaixo da terra, onde fazem túneis para morarem. Por viverem assim, eles estão em risco de extinção, pois com as plantações e construções feitas pelos humanos, eles não conseguem construir suas casas debaixo da terra.'
      }
    ]
  }
]
