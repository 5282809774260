export default [
  {
    id: 'boto',
    name: 'Boto-cor-de-rosa',
    nameLibras: 'https://youtu.be/d3xh9AV9Ge8',
    thumb: require('../../entities/amazonia/boto.webp'),
    images: [{
      id: 'boto-1',
      description: 'Fotografia colorida de um boto-cor-de-rosa nadando num rio. O boto é um animal que lembra um golfinho. Ele mede cerca de 2 metros de comprimento. Sua pele é rosada e lisa. A nadadeira no centro das costas tem o formato de um triângulo. Seu bico é muito longo e fino e seus olhos têm o formato de bolinhas pequenas e pretas. O boto está no centro da fotografia, virado para a direita. Sua cabeça e a nadadeira das costas estão para fora da água.',
      src: require('../../entities/amazonia/boto.webp')
    }],
    audioSrc: require('../../sounds/boto.mp3'),
    content: [
      {
        libras: 'https://youtu.be/U55Qkmvz0fQ',
        text: 'O boto-cor-de-rosa (nome científico: <i>Inia geoffrensis</i>) é da mesma família dos golfinhos, mas sua cor geralmente é mais próxima do rosa, por causa do sangue vermelho que corre logo abaixo de sua pele. Seu corpo é grande e flexível, com nadadeiras fortes que os permitem-lhes desviar dos obstáculos que aparecem no rio, como troncos caídos na água e pedras. Eles têm aproximadamente, 2 metros de comprimento, sendo que o macho possui o corpo um pouco maior do que a fêmea.'
      },
      {
        libras: 'https://youtu.be/rgmwsT5fReM',
        text: 'Os botos-cor-de-rosa moram em águas doces, como os rios, enquanto os golfinhos moram em águas salgadas, como o mar. Os botos se alimentam principalmente de peixes, mas quando não os encontram podem comer outros animais, como moluscos. Eles podem viver até cerca de 80 anos. Geralmente vivem em pares: a mãe e o filhote. Eles estão em risco de extinção, pois muitas vezes são caçados pelos seres humanos.'
      },
      {
        libras: 'https://youtu.be/DYb1F07T6Ew',
        text: 'Você sabia que a cor dos botos-cor-de-rosa varia entre machos e fêmeas e com a idade? Os machos geralmente são mais rosas do que as fêmeas, e os filhotes nascem com uma cor mais acinzentada e, conforme vão crescendo, vão adquirindo uma coloração mais rosada.'
      }
    ]
  },
  {
    id: 'onca',
    name: 'Onça-pintada',
    nameLibras: 'https://youtu.be/CUn1Xhcdr3E',
    thumb: require('../../entities/amazonia/onca_thumb.jpeg'),
    images: [{
      id: 'onca-1',
      description: 'Fotografia colorida de uma onça-pintada descansando sobre uma pedra. A onça é um animal que lembra um gato muito grande e forte. Ela mede cerca de 2 metros de comprimento. Seu pelo é curto, amarelado e coberto por pintas pretas. Ela tem os olhos redondos e dourados e o nariz triangular e marrom. Suas orelhas são triangulares e seu rabo é comprido e fino. Acima da sua boca, pelos brancos e compridos formam um bigode. A onça está deitada de lado no centro da fotografia, com sua cabeça voltada para a esquerda.',
      src: require('../../entities/amazonia/onca_thumb.jpeg')
    }],
    audioSrc: require('../../sounds/onca.mp3'),
    content: [
      {
        libras: 'https://youtu.be/VgCsN9d6oCY',
        text: 'A onça-pintada (nome científico: <i>Panthera onca</i>) é da família dos gatos, só que é bem maior e cheia de pintinhas pretas. A onça chega a medir até 2 metros de comprimento, do nariz até a cauda; e 75 centímetros de altura. Outra diferença é que as onças não miam como os gatos, e sim roncam muito alto, que é um som chamado de esturro.'
      },
      {
        libras: 'https://youtu.be/q-raG7PiM94',
        text: 'A onça se alimenta de animais silvestres, por exemplo, capivaras, jacarés, veados e tatus. Por comer esses animais grandes, a onça está no topo da cadeia alimentar e é considerada o maior felino das Américas. Elas vivem cerca de 12 a 15 anos na natureza.'
      },
      {
        libras: 'https://youtu.be/omatHjELUuU',
        text: 'A onça só mora em matas preservadas. Ou seja, sua presença no local indica que este é um bom lugar para morar. Por isso, é importante evitar o desmatamento e a caça realizada pelo ser humano, para que elas tenham sempre um lugar para viver.'
      },
      {
        libras: 'https://youtu.be/R3l78pdT38U',
        text: 'Você sabia que os filhotes de onças-pintadas nascem cegos? Somente após aproximadamente duas semanas eles abrem os olhos e conseguem enxergar. A mãe dos filhotes os ensina a caçar e eles só conseguem ser independentes após cerca de 2 anos. Então, eles se tornam bons caçadores, com a mordida mais forte do que a do leão, capaz de quebrar ossos e até mesmo cascos de tartaruga.'
      }

    ]
  },
  {
    id: 'piranha-vermelha',
    name: 'Piranha-vermelha',
    nameLibras: 'https://youtu.be/aItJPNcrLVw',
    thumb: require('../../entities/amazonia/piranha.webp'),
    images: [{
      id: 'piranha-vermelha-1',
      description: 'Fotografia colorida de várias piranhas-vermelhas nadando num rio. As piranhas-vermelhas são peixes de cerca de 30 centímetros de comprimento. Seu corpo é arredondado com a parte de cima acinzentada e a de baixo, avermelhada. Sua mandíbula, que é a parte inferior da boca, fica mais para frente que o resto do corpo. Seus olhos são redondos e escuros. Acima da boca e entre os olhos, há dois furinhos que são as narinas. Na foto, as escamas dos peixes refletem a luz do sol, dando a impressão de que estão brilhando.',
      src: require('../../entities/amazonia/piranha.webp')
    }],
    audioSrc: require('../../sounds/piranha-vermelha.mp3'),
    content: [
      {
        libras: 'https://youtu.be/H14WC_E99jg',
        text: 'A piranha-vermelha (nome científico: <i>Pygocentrus nattereri</i>) é um peixe pequeno e muito temido que mora nos rios da Amazônia. Sua coloração é avermelhada, com a cabeça e as costas acinzentadas, e suas escamas são prateadas, fazendo com que pareça um peixe brilhante. Elas chegam a medir no máximo 30 centímetros de comprimento, e vivem aproximadamente 10 anos. Seus dentes são muito afiados e triangulares possibilitando que a piranha consiga comer outros peixes, e também qualquer animal que caia na água, mesmo que seja maior do que ela.'
      },
      {
        libras: 'https://youtu.be/qoUEdRhW9zc',
        text: 'As piranhas-vermelhas nadam em bandos de cerca de 100 indivíduos, para se defender dos jacarés, botos e aves aquáticas, que comem esses peixes.'
      },
      {
        libras: 'https://youtu.be/rOff7SfbwrU',
        text: 'O ser humano não faz parte do cardápio da piranha, mas quando um deles caem na água e faz muitos movimentos ou está com algum machucado, as piranhas podem atacar. Por isso, é importante tomar cuidado! Por outro lado, as piranhas fazem parte do cardápio do ser humano, sendo um alimento típico da região amazônica.'
      }

    ]
  }
]
