export default [
  {
    id: 'mandacaru',
    name: 'Mandacaru',
    nameLibras: 'https://youtu.be/FtFVByEDpkU',
    thumb: require('../../entities/caatinga/mandacaru_thumb.jpeg'),
    images: [{
      id: 'mandacaru-1',
      description: 'Fotografia colorida de um mandacaru. O mandacaru é um tipo de cacto bem alto que mede cerca de 3 metros de altura. Ele tem diferentes tons de verde e é coberto por espinhos finos e compridos. Seu corpo é formado por ramos verticais, divididos em vários segmentos. Cada segmento é ligeiramente ovalado e tem 5 gomos salientes, lembrando o formato de uma carambola. Na porção mediana dos ramos, há dois grandes botões de flores vermelhos e ovalados. O mandacaru está no centro da fotografia, em meio a um campo aberto coberto por grama. Ao fundo, estão um morro baixo e o céu azul com poucas nuvens.',
      src: require('../../entities/caatinga/mandacaru_thumb.jpeg')
    }],
    content: [
      {
        libras: 'https://youtu.be/C06_-RXkgII',
        text: 'O mandacaru (nome científico: <i>Cereus jamacaru</i>) é da família dos cactos e é encontrado na região Nordeste do Brasil, onde o clima é parecido com o de deserto. O mandacaru pode atingir 3 metros de altura. Possui o tronco grosso e é cheio de espinhos. A presença de espinhos é uma forma do cacto não perder muita água, o que é bom, já que quase não chove na Caatinga.'
      },
      {
        libras: 'https://youtu.be/kPY_MFqggCM',
        text: 'A flor do mandacaru floresce uma única vez ao ano e geralmente dura apenas um dia. Ela é muito bonita e colorida, com as pétalas brancas e o miolo amarelo.  A flor serve de alimento para insetos como abelhas, que comem o pólen e o néctar. O mandacaru possui também um fruto que serve de alimento para muitos animais e humanos. Os frutos são redondos e têm aproximadamente 15 centímetros. Eles têm a casca de cor roxo-avermelhada e por dentro são brancos, cheios de sementes pequenas e pretas.'
      }
    ]
  },
  {
    id: 'juazeiro',
    name: 'Juazeiro',
    nameLibras: 'https://youtu.be/4d4mMOsrkQ0',
    thumb: require('../../entities/caatinga/juazeiro_thumb.jpg'),
    images: [{
      id: 'juazeiro',
      description: 'Fotografia colorida de um juazeiro. O Juazeiro é uma árvore alta, de grande porte, com cerca de 15 metros de altura. Sua copa é muito grande e densa, com folhas verde escuras, e seu tronco é baixo e marrom. O juazeiro está no centro da fotografia, no meio de um pasto com uma cerca baixa feita de galhos secos e retorcidos. Sua copa faz uma sombra grande no chão, coberto de vegetação rasteira, e contrasta com o céu azul de um dia ensolarado. À direita, uma cabra branca está pastando.',
      src: require('../../entities/caatinga/juazeiro_thumb.jpg')
    }],
    content: [
      {
        libras: 'https://youtu.be/hsedftsRtzY',
        text: 'O juazeiro (nome científico: <i>Ziziphus joazeiro</i>) é uma árvore com um tronco que contém espinhos e cresce até 15 metros de altura. Eles crescem em solos semelhantes a argilas, e suas muitas folhas ficam sempre verdes, mesmo quando passa pela estação seca. Isso acontece por causa das suas raízes profundas que procuram água até nas partes mais fundas do solo. A partir de abril, essa árvore se enche de flores, que são muito pequenas e de cor creme. Em maio, começam a nascer frutos amarelados do juazeiro, do tamanho de uma cereja, que podem ser utilizados para fazer geleia e fazem parte da alimentação do gado na época da seca.'
      }
    ]
  }
]
