import React from 'react'
import styled from 'styled-components'
import { Colors, Fonts } from '../styles'
import { useHistory } from 'react-router-dom'
import { Accessible } from '.'
import { Row, Col, setConfiguration } from 'react-grid-system'

function Clickable ({
  disabled,
  librasButtonSide,
  accessibleStyle,
  libras,
  style,
  tabindex,
  description,
  accessible,
  onClick = null,
  className,
  children,
  to
}) {
  const history = useHistory()
  if (onClick && to) window.alert('Invalid button')

  const clickable = (
    <button disabled={disabled} style={style} tabIndex={tabindex} aria-label={description} className={className} onClick={onClick || (() => history.push(to))}>
      {children}
    </button>
  )

  if (!accessible) {
    return clickable
  }

  setConfiguration({ gutterWidth: 10 })
  return (
    <>
      <Container>
        <Col xs={9} sm={10} md={11} lg={11}>
          {clickable}
        </Col>
        <Col style={{ paddingRight: 0 }} xs={3} sm={2} md={1} lg={1}>
          {accessible &&
            <AccessibleWrapper>
              <Accessible
                libras={libras}
              />
            </AccessibleWrapper>}
        </Col>
      </Container>
    </>
  )
}

export const Button = styled(Clickable)`
  display: block;
  box-shadow: 0px 10px 20px -8px rgba(0,0,0,0.45);
  text-align: center;
  font-family: ${Fonts.cool}; 
  background: ${Colors.white};
  color: ${Colors.black};
  border-radius: 20px;
  width: 200px;
  border: 10px solid rgba(255,255,255,0.3);
  border-style: groove;
  padding: 15px 25px;
  font-size: 35px;
  height: auto;
  text-decoration: none;
  cursor: pointer;
  margin: 20px ${window.innerWidth <= 1000 ? '35px' : '70px'};
  transition: 0.5s;
  &:hover{
    transform: scale(1.2);
  }
  &[disabled]:hover {
    filter: brightness(100%);
  }
  &:disabled {
    transform: scale(1) !important;
    cursor: not-allowed !important;
    background: ${Colors.gray};
  }
`

export const PrimaryButton = styled(Button)`
  background: ${Colors.white};
  color: ${Colors.blue};

  `

export const SecondaryButton = styled(Button)`
  background: ${Colors.blue};
  color: ${Colors.white};       
`

const AccessibleWrapper = styled.div`
  float: right; 
  margin-right: 0;
`

const Container = styled(Row)`
  margin: 0;
  padding: 0;
`

export const RoundedButton = styled(Button)`
  margin: 0 auto;
  padding: 8px 0px;
  display: inline-block;
  margin: 26px 10px;
  color: ${Colors.blue};
  text-align: center;
  height: 80px;
  width: 80px;
  font-size: 40px;
  border-radius: 50%;
  &:hover {
    filter: brightness(110%);
  }
`
export const LockableRoundedButton = styled(RoundedButton)`
  background-color: ${({ completed }) => completed ? Colors.green : Colors.white};
  opacity: ${({ unlocked }) => unlocked ? 1 : 0.6};
  color: ${Colors.black};
`
