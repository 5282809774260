export default [
  {
    id: 'arara-azul',
    name: 'Arara-azul',
    nameLibras: 'https://youtu.be/7x8MC9DFvZ0',
    thumb: require('../../entities/pantanal/arara-azul_thumb.jpg'),
    images: [{
      id: 'arara-azul-1',
      description: 'Fotografia colorida de duas araras-azuis num galho. As araras-azuis são aves que lembram um papagaio grande e medem cerca de 1 metro de comprimento. Seu corpo é coberto por penas azuis escuras, com exceção de uma área amarela ao redor dos olhos e de uma faixa estreita, também amarela, próxima à parte inferior do bico. Os olhos da arara são como pequenas bolinhas pretas. Seu bico é preto, grande e forte, com a parte superior bem curva, parecendo um gancho. As araras estão no centro da fotografia, empoleiradas num galho, voltadas uma para a outra. A arara da direita bica a cabeça da arara à esquerda.',
      src: require('../../entities/pantanal/arara-azul_thumb.jpg')
    }],
    audioSrc: require('../../sounds/arara-azul.mp3'),
    content: [
      {
        libras: 'https://youtu.be/eEy4eCYtRMw',
        text: 'A arara-azul (nome científico: <i>Anodorhynchus hyacinthinus</i>) é uma ave e tem esse nome porque tem quase todo seu corpo de penas azuis. Em volta do olho e perto do bico tem coloração amarela. Com seu bico em forma de gancho, elas conseguem comer os frutos da palmeira, como o acuri. Se medirmos da ponta do bico até o final de sua cauda, ela pode chegar a medir mais ou menos 1 metro de comprimento. Elas podem viver até 50 anos.'
      },
      {
        libras: 'https://youtu.be/sViT85NJg94',
        text: 'As araras vivem em casais ou bando, e quando tem filhotes, tanto a mãe quanto o pai os criam até conseguir se virar sozinho, o que acontece em pouco mais de um ano. No final da tarde, elas se reúnem em bandos para dormir nas árvores, onde fazem seus ninhos dentro de buracos nos troncos, que são forrados com cascas que as araras arrancam das árvores.'
      },
      {
        libras: 'https://youtu.be/yHkFyJ1entg',
        text: 'A arara-azul está em risco de extinção por causa do comércio ilegal de animais silvestres e da destruição de suas casas.'
      }
    ]
  },
  {
    id: 'jacare',
    name: 'Jacaré',
    nameLibras: 'https://youtu.be/2jrKAgKBcoI',
    thumb: require('../../entities/pantanal/jacare_thumb.jpg'),
    images: [{
      id: 'jacare-1',
      description: 'Fotografia colorida de um jacaré na margem de um rio. O jacaré é um animal que lembra um lagarto bem grande e forte. Ele mede cerca de 3 metros de comprimento. Sua pele é coberta por escamas duras e secas. As das costas são escuras, variando do verde ao preto. As da barriga são claras, variando do amarelo ao bege. O jacaré tem patas curtas que saem da lateral do corpo e são arqueadas, por isso ele anda rente ao chão. Sua cabeça é triangular. Seus olhos são redondos e verdes e ficam opostos um ao outro no topo da cabeça. Ele tem muitos dentes afiados, que ficam à mostra mesmo com a boca fechada. O jacaré está no centro da fotografia, voltado ligeiramente para a esquerda, tomando sol na terra próxima ao rio.',
      src: require('../../entities/pantanal/jacare_thumb.jpg')
    }],
    audioSrc: require('../../sounds/jacare.mp3'),
    content: [
      {
        libras: 'https://youtu.be/-75pmTSwN40',
        text: 'O jacaré (nome científico: <i>Caiman yacare</i>) tem cerca de 80 dentes afiados para poder caçar sua comida, como peixes, aves e pequenos animais que ficam na margem do rio. Ele é muito grande, podendo chegar até 3 metros de comprimento. Tem a pele recoberta por escamas que são duras e secas e sua coloração é escura, variando entre preto e verde. Essa cor absorve o calor do sol, deixando o animal quentinho.'
      },
      {
        libras: 'https://youtu.be/VIVV8nl79kA',
        text: 'Os jacarés ficam a maior parte do tempo na beira da água, perto dos rios ou lagos. De dia ficam tomando sol e quando sentem fome vão para água se alimentar. Eles são muito antigos e estão na Terra antes mesmo do ser humano chegar. O jacaré consegue viver na natureza cerca de 50 anos.'
      },
      {
        libras: 'https://youtu.be/N2JmXl_Ckyk',
        text: 'Por causa da caça, destruição da sua casa, e dos humanos (que querem usar sua pele para fazerem acessórios, como bolsas), eles estão com perigo de entrar em extinção.'
      },
      {
        libras: 'https://youtu.be/Q5Wi5lRaiQI',
        text: 'Você sabia que a temperatura do ninho é o que vai determinar se o filhote vai nascer macho ou fêmea? Se a temperatura for menor que 28 graus Celsius o filhote nasce fêmea e se for acima de 33 graus Celsius vai nascer um macho. Entre 28 e 33 graus podem nascer tanto machos quanto fêmeas.'
      }
    ]
  },
  {
    id: 'tuiuiu',
    name: 'Tuiuiú',
    nameLibras: 'https://youtu.be/2wt0AEBCgo0',
    thumb: require('../../entities/pantanal/tuiuiu.webp'),
    images: [{
      id: 'tuiuiu-1',
      description: 'Fotografia colorida de um tuiuiú em meio a um terreno coberto de grama. O tuiuiú é uma ave grande que mede cerca de 1 metro e meio de altura. Ele tem o bico, a cabeça, o pescoço e as pernas pretos e a parte central do corpo coberta de penas brancas. Circundando a base do pescoço, há uma faixa de penas de vermelho vivo. O tuiuiú tem a cabeça pequena e o bico grande, com cerca de 30 centímetros. Seu pescoço é longo e suas pernas são finas e compridas. O tuiuiú está de pé no centro da fotografia, voltado para a esquerda, em meio a um campo coberto de grama alta e bem verdinha.',
      src: require('../../entities/pantanal/tuiuiu.webp')
    }],
    audioSrc: require('../../sounds/tuiuiu.mp3'),
    content: [
      {
        libras: 'https://youtu.be/XiOQ7N03tSk',
        text: 'O tuiuiú (nome científico: <i>Jabiru mycteria</i>) tem o bico muito grande e forte para se alimentar de peixes, insetos e pequenos animais. Sua cabeça é preta com uma faixa vermelha no pescoço e o resto do corpo é branco. É a maior ave voadora da região e por isso pode ser considerada símbolo do Pantanal. Ela pode chegar até a 1,6 metros de altura, seu bico tem em torno de 30 centímetros de comprimento e suas asas abertas podem atingir 3 metros de largura.'
      },
      {
        libras: 'https://youtu.be/PQGaAlIWsu8',
        text: 'Esses animais vivem em casais, sendo que eles se atraem por meio de danças e de batidas de bico um no outro. Moram em lugares úmidos, perto das margens de rios, onde conseguem capturar seus alimentos, como os peixes. Conseguem viver em até mais ou menos 30 anos.'
      },
      {
        libras: 'https://youtu.be/Sjc227BfenA',
        text: 'A principal ameaça aos tuiuiús são os humanos, que destroem suas casas.'
      }
    ]
  }
]
