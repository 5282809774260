import React, { useEffect, useState } from 'react'
import { Tooltip } from './Tooltip'

export default function InstallPWA () {
  const [supportsPWA, setSupportsPWA] = useState(false)
  const [promptInstall, setPromptInstall] = useState(null)

  useEffect(() => {
    const handler = e => {
      e.preventDefault()
      setSupportsPWA(true)
      setPromptInstall(e)
    }
    window.addEventListener('beforeinstallprompt', handler)
    window.addEventListener('appinstalled', () => window.open('https://explorar.iara.app', 'blank'))

    return () => window.removeEventListener('transitionend', handler)
  }, [])

  const onClick = evt => {
    evt.preventDefault()
    if (!promptInstall) {
      return
    }
    promptInstall.prompt()
  }

  return (
    <Tooltip
      visible={supportsPWA}
      onClick={onClick}
      mobileMessage='Adicione à sua tela de início'
      desktopMessage='Adicione à sua tela de início'
      buttonText='Instalar'
    />
  )
}
