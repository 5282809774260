export default
{
  id: 'tale-pampa',
  name: 'Conto do Pampa',
  nameLibras: 'https://youtu.be/3LB_EM2ylgk',
  images: [],
  audioSrc: require('../../../sounds/tales/pampa.mp3'),
  videoSrc: 'https://www.youtube.com/embed/Xb0m0KKI1Es',
  content: [
    {
      code: 'pm-01',
      libras: 'https://youtu.be/UtKQWU8DqiQ',
      text: '- Cheguei ao Pampa, o que eu faço?<br><br>- Iara, para a semente encontrar, no caminho da música deve andar.<br><br>Estava caminhando pelos campos do Pampa e pensei que essa semente seria fácil de achar, já que não tinha muitas plantas altas por ali. Comecei a escutar uma música vindo de uma lagoa e caminhei em direção a ela. Quando cheguei perto, me escondi atrás do mato para observar. Era um sapo, cantando com um violão:'
    },
    {
      audioDescription: 'teste',
      style: {
        maxHeight: '600px',
        maxWidth: '600px'
      },
      image: 'https://iaraapp.sirv.com/iara/contos/pampa/iara-barba-de-bode.png?w=600&h=600&format=webp'
    },
    {
      code: 'pm-02',
      libras: 'https://youtu.be/XdOE47-0PgQ',
      text: 'O sapo lava sim o pé <br/>Lava porque ele quer <br/>Ele mora aqui na lagoa <br/>E lava o pé porque ele quer <br/>Sai chulé! <br/>Por isso o sapo é <br/>Cheiroso porque ele quer <br/>Ele mora aqui na lagoa <br/>E toma banho porque ele quer <br/>Mas que filé!'
    },
    {
      code: 'pm-03',
      libras: 'https://youtu.be/KmRw8WzdIks',
      text: 'Dei uma risadinha, e logo ele disse:<br><br>- Quem está aí?<br><br>- Sou eu! Desculpa te espiar assim. Achei sua música muito legal! Muito diferente da que eu já escutei.<br><br>- Deixe-me adivinhar, você escutou que eu tenho chulé e não lavo o pé, não é?<br><br>- Sim...<br><br>- Para a sua informação, eu sou muito limpinho e muito importante na natureza, diferente do que dizem por aí, tá?<br><br>- Como assim? O que você faz?'
    },
    {
      code: 'pm-04',
      libras: 'https://youtu.be/l7z-4foHvfE',
      text: '- Eu faço parte de um ecossistema, assim como você e todos os outros animais. Isso significa que todos somos importantes de alguma forma. Se você faz mal para algum animal do ecossistema, a natureza fica triste e prejudicada! Por exemplo, eu ajudo a controlar a população de insetos, até aqueles chatinhos que ninguém gosta muito, sabe? Os pernilongos e as moscas!<br><br>- Sério?<br><br>- Sim! Mas muitos humanos não sabem dessas coisas e, infelizmente, acabam achando a gente nojento e feio...'
    },
    {
      code: 'pm-05',
      libras: 'https://youtu.be/jFjml8NfLio',
      text: '- Poxa, é mesmo! Conheço algumas pessoas que têm medo de vocês. Mas agora sei que não é bem assim. Você é muito inteligente.<br><br>- Obrigado! Sou um professor aqui da lagoa dos sapos e gosto muito de explorar também. Por onde ando, coleciono alguma lembrança do lugar.<br><br>- Que legal! Eu também sou uma exploradora. Posso ver o que você já coletou?<br><br>- Claro.'
    },
    {
      audioDescription: 'dummy image',
      style: {
        maxHeight: '600px',
        maxWidth: '600px'
      },
      image: 'https://iaraapp.sirv.com/iara/contos/pampa/iara-sapo.png?w=600&h=600&format=webp'
    },
    {
      code: 'pm-06',
      libras: 'https://youtu.be/J4RvhMzFZI8',
      text: 'O sapo me levou até um tronco oco, tirou algumas folhas da frente revelando um monte de coisas. Tinha de tudo: flores, pedras, folhas, galhos, sementes.... Espera um pouco! É a semente de que eu preciso.<br><br>- Senhor sapo, essa semente brilhante é a que estou procurando na minha missão. Será que eu posso ficar com ela?<br><br>Mas eu gosto tanto dela... Você tem alguma coisa para trocar por ela?<br><br>- Hum… Eu tenho só essa pena azul aqui que eu ganhei de uma arara-azul que conheci.<br><br>- Nossa, uma arara-azul! Sempre quis ver uma, mas aqui onde moro eu nunca vi. Que pena linda, aceito a troca.<br><br>Troquei a pena pela semente e nos despedimos.'
    }
  ]
}
