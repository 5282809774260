export default
{
  id: 'tale-final',
  name: 'Conto final',
  nameLibras: 'https://vimeo.com/76287360',
  images: [],
  audioSrc: require('../../../sounds/tales/conto-final.mp3'),
  videoSrc: 'https://www.youtube.com/embed/QVu5TcQMWW0',
  content: [
    {
      code: 'ct-01',
      libras: 'https://youtu.be/Jel5HVXfn1Y',
      text: 'Os lenhadores que cortaram a nossa árvore voltaram para a cidade e tentaram logo utilizar sua magia para pedir frutos e assim, vendê-los para ganhar muito dinheiro. Porém, notaram que a árvore estava seca e sem vida.<br><br>- Nossa, será que ainda funciona?<br><br>- Não seja tolo, claro que funciona! Olha só, me dá um açaí!<br><br>A árvore não mostrou nenhum tipo de magia.<br><br>- Tem que pedir com jeitinho. Me dá um açaí, por favor?<br><br>- Espera! Acho que eles falavam alguma coisa para pedir… Acho que era assim:'
    },
    {
      code: 'ct-02',
      libras: 'https://youtu.be/SsxXQBttNTk',
      text: 'Na nossa aldeia Heni, tem a árvore mágica plantada por Rudá, <br/>Os nossos ancestrais que a habitam, estão lá para nos guiar <br/>Agora que estou diante dela pergunto: <br/>Ó grande e poderosa árvore, uma fruta pode me dar?'
    },
    {
      code: 'ct-03',
      libras: 'https://youtu.be/6VOOIJtOJiI',
      text: 'Nada aconteceu.<br><br>Bravos, os lenhadores jogaram a árvore na lixeira.<br><br>- Não existe essa coisa de magia mesmo! Foi uma perda de tempo e dinheiro.<br><br>- O que foi uma perda de tempo e dinheiro?<br><br>Os lenhadores olharam para trás e viram um homem de uniforme com braços cruzados olhando para eles.'
    },
    {
      code: 'ct-04',
      libras: 'https://youtu.be/I7_RJS2otws',
      text: '- Não é da sua conta!<br><br>- É  da minha conta sim, eu sou um fiscal ambiental. Vi que estão jogando essa árvore no lixo. Podem me explicar? Se não tiverem respeitado a lei, serão obrigados a pagar uma grande multa...<br><br>Os lenhadores tentaram se explicar para o fiscal, mas tiveram que pagar a multa mesmo assim. Afinal, estavam fazendo mal a natureza! Depois disso, aprenderam a lição e nunca mais mexeram com meio ambiente e no que não era deles.'
    },
    {
      code: 'ct-05',
      libras: 'https://youtu.be/jbk-ydpmS-M',
      text: 'Enquanto isso, na Amazônia...<br><br>Iara chegou na sua aldeia, feliz com todas as sementes que conseguiu coletar pelos biomas brasileiros. Foi recebida com muita alegria por todos os seus amigos e família. Em seguida, ela foi para onde estava o resto da árvore e colocou as seis sementes em cima do tronco cortado. Foi então que toda a aldeia foi surpreendida por uma luz muito forte vinda das sementes. As pessoas tiveram que fechar os olhos por um momento, e quando a luz se apagou, viram a árvore grande e forte como antes. Todos comemoraram a volta da magia da nossa árvore. Os espíritos dos ancestrais retornaram para a árvore, trazendo todos os ensinamentos do povo Heni de volta.'
    },
    {
      code: 'ct-06',
      libras: 'https://youtu.be/5A3Bb0faVr8',
      text: '- Viva a Iara!<br><br>- Nossa salvadora!<br><br>- Não teria conseguido se não fosse pela ajuda dos amigos que fiz durante a missão. Aprendi que todos os seres vivos dependem muito da natureza, assim como a gente depende dela. Não podemos deixá-la ser destruída, porque assim todos nós vamos perder. Por isso, devemos estar sempre unidos para defender a natureza, ajudar quando pudermos e respeitar todas as formas de vida.<br><br>Todos deram as mãos em volta da árvore mágica e juntos celebraram a vida.'
    },
    {
      audioDescription: 'teste',
      style: {
        maxHeight: '600px',
        maxWidth: '600px'
      },
      image: 'https://iaraapp.sirv.com/iara/contos/final/indios.png?w=600&h=600&format=webp'
    }
  ]
}
