export default [
  {
    id: 'acaizero',
    name: 'Açaizero',
    nameLibras: 'https://youtu.be/L6OGI-UMzq4',
    thumb: require('../../entities/amazonia/acaizero_thumb.jpeg'),
    images: [{
      id: 'acaizero-1',
      description: 'Fotografia colorida de um cacho de açaí visto de baixo para cima. Os frutos do açaizeiro crescem em cachos formados por ramos que parecem cordas compridas e marrons. Elas pendem em direção ao chão e são cobertas por frutinhas em toda a sua extensão. As frutinhas têm a aparência de jabuticabas, são pequenas, redondas e têm uma cor roxa bem escura, quase preta.',
      src: require('../../entities/amazonia/acaizero_thumb.jpeg')
    }],
    content: [
      {
        libras: 'https://youtu.be/aO32HJeipeQ',
        text: 'Os açaizeiros (nome científico: <i>Euterpe oleracea</i>) são chamados também de “palmeiras do açaí”. Eles crescem melhor em áreas com bastante sol e em solos com muita água, sem muitas plantas ao seu redor. São árvores muito altas, que podem chegar até a 12 metros de altura. O nome “açaí” vem do tupi, que é uma língua indígena, e significa “fruta que chora”, se referindo ao suco que sai do seu fruto. Suas flores (pequenas e amarronzadas) e frutos (redondos e pequenos) nascem todo o ano, mas principalmente na estação seca. A casca e a polpa do açaí são roxas e, quando estão maduras, ficam pretas.'
      },
      {
        libras: 'https://youtu.be/IXiL2jeAs0U',
        text: 'Você sabia que, quando maduros, os frutos do açaí podem ser consumidos logo após serem coletados? Na região Norte, o açaí é uma importante fonte de nutrientes na alimentação. A população consome a fruta como um prato principal, acompanhado de peixes e farinha, por exemplo. O mais comum nas outras partes do Brasil é congelar sua polpa para que possa ser transformado em sorvetes, sucos e geleias que são vendidos nos supermercados.'
      }
    ]
  },
  {
    id: 'vitoria-regia',
    name: 'Vitória-régia',
    nameLibras: 'https://youtu.be/dGyPtmYCnj8',
    thumb: require('../../entities/amazonia/vitoria-regia_thumb.jpeg'),
    images: [{
      id: 'vitoria-regia-1',
      description: 'Fotografia colorida de um rio com várias vitórias-régias flutuando sobre a água. A vitória-régia é uma planta que mede cerca de 2 metros de diâmetro e lembra uma grande bandeja arredondada com bordas mais elevadas. Sua textura tem aspecto rugoso, seu interior é verde e a parte externa da borda é avermelhada. No canto inferior esquerdo, uma das vitórias-régias está em flor. A flor da vitória-régia também flutua sobre a água. Ela é grande, branca e tem muitas pétalas finas sobrepostas em camadas.  Ao fundo, há um morro alto, coberto por vegetação verde escura, que contrasta com o céu azul claro de um dia ensolarado.',
      src: require('../../entities/amazonia/vitoria-regia_thumb.jpeg')
    }],
    content: [
      {
        libras: 'https://youtu.be/7vlZqfjcwkE',
        text: 'A vitória-régia (nome científico: <i>Victoria amazonica</i>) é símbolo da Amazônia, pois é muito abundante nessa região, e os indígenas que lá vivem as utilizam para fazer tinta e pintar o corpo. É uma planta aquática muito bonita e comum nessa região. A vitória-régia é muito grande e forte, chegando a medir 2 metros de diâmetro e a suportar até o peso de 40 quilos. É circular e com as bordas levantadas, e parece uma bandeja flutuante de cor verde-escuro.'
      },
      {
        libras: 'https://youtu.be/R7HMSy86DG0',
        text: 'A flor branca da vitória-régia nasce de noite e libera um perfume muito gostoso. Depois de um tempo, a flor fica rosa e nasce um fruto, que parece uma espiga de milho. As sementes são flutuantes e se espalham ao longo do rio.'
      },
      {
        libras: 'https://youtu.be/8Ap_T9VbhAU',
        text: 'Você sabia que, quando a folha da vitória-régia é jovem, ela tem formato de coração? A vitória-régia é uma das maiores plantas aquáticas do mundo.'
      }
    ]
  }
]
