export default
{
  id: 'tale-mata-atlantica',
  name: 'Conto da Mata Altântica',
  nameLibras: 'https://youtu.be/sYJ33aC05Hg',
  images: [],
  audioSrc: require('../../../sounds/tales/mata-atlantica.mp3'),
  videoSrc: 'https://www.youtube.com/embed/0cAQGhg6D3w',
  content: [
    {
      code: 'mt-01',
      libras: 'https://youtu.be/YqP2eEJbvkQ',
      text: '- Agora que estou na Mata Atlântica, o que eu faço?<br><br>-  Iara, para a semente encontrar, sua fruta favorita deve achar.<br><br>Fiquei animada com essa missão. Vou poder comer caju, minha fruta predileta! Dei uma olhada ao meu redor, mas eu não sabia por onde começar a procurar, a Mata Atlântica tem muitas plantas diferentes. Foi então que comecei a escutar um grupo de micos-leões-dourados se aproximando.'
    },
    {
      code: 'mt-02',
      libras: 'https://youtu.be/WXbthHeKwbs',
      text: '- Ei! Alguém aí pode me ajudar? Estou procurando um caju.<br><br>Um dos micos jogou um caju em mim.<br><br>- Ai! Precisava ter jogado?<br><br>- Ué, mas você não queria um caju?<br><br>- Queria, mas parece que esse não é o que estou procurando. Estou numa missão e preciso encontrar...<br><br>- UM CAJUEIRO? UM CAJU? DOIS CAJUS?<br><br>- Espera, deixa eu falar! Eu preciso encontrar uma semente mágica que acredito que está no cajueiro.<br><br>- Mas você está procurando a fruta ou a árvore?<br><br>- Não sei, é isso que vou descobrir. Por isso preciso ir até lá.<br><br>- Nossa, mas que missão complicada. Tá bom vai, a gente te leva até lá.'
    },
    {
      code: 'mt-03',
      libras: 'https://youtu.be/PR1f_NlcpNQ',
      text: 'Os micos começaram a pular de árvore em árvore bem rápido e eu corri para não ficar para trás.<br><br>- Chegamos, esse é o maior cajueiro que temos aqui. Os outros foram derrubados pelos lenhadores e outros humanos...<br><br>- Que pena! Nossa, os cajus estão muito altos. Não vou conseguir alcançar. Será que vocês podem me ajudar a pegar?<br><br>- Claro! Mas vai precisar de algo para conseguir carregá-los enquanto jogamos para você!'
    },
    {
      code: 'mt-04',
      libras: 'https://youtu.be/4nV9qWVv4lk',
      text: 'Olhei para os lados e achei uma cesta abandonada.<br><br>- Pronto! Podem jogar.<br><br>Os micos começaram a jogar os cajus para mim e fiquei correndo para lá e para cá tentando coletar tudo sem deixar cair. Eles eram muito brincalhões, faziam malabarismo com as frutas e ficavam tentando me enganar. Bem que eu tinha ouvido falar que os micos eram engraçadinhos assim.'
    },
    {
      audioDescription: 'teste',
      style: {
        maxHeight: '600px',
        maxWidth: '600px'
      },
      image: 'https://iaraapp.sirv.com/iara/contos/mata-atlantica/iara-caju.png?w=600&h=600&format=webp'
    },
    {
      code: 'mt-05',
      libras: 'https://youtu.be/i_yk4y5bjRs',
      text: '- Pronto, coletamos a maioria, mas precisamos deixar alguns para os outros animais comerem.<br><br>- Tudo bem! Vou ver se a semente que estou procurando está aqui.<br><br>Comecei olhar os cajus dentro da cesta em busca da semente. E também, para aproveitar, resolvi comer alguns. Os micos sentaram-se do meu lado e começaram a comer junto comigo.<br><br>- AI!!<br><br>- O que foi? Você está bem?<br><br>- Não sei, mordi alguma coisa esquisita.'
    },
    {
      code: 'mt-06',
      libras: 'https://youtu.be/_FIzRTioxrg',
      text: 'O mico tirou da boca o caju que estava comendo. Foi então que vimos a semente que eu estava procurando.<br><br>- Achamos a semente! Obrigada pela ajuda de todos vocês!<br><br>- Sem problemas, foi divertido participar da missão… Mas você tem que ir embora agora mesmo? Podia ficar mais um pouco para brincar com a gente.<br><br>- Hum, eu tenho que continuar, sim, mas posso ficar mais um pouquinho aqui.<br><br>Brincamos de subir em árvores e nos divertimos muito juntos.'
    },
    {
      audioDescription: 'dummy image',
      style: {
        maxHeight: '600px',
        maxWidth: '600px'
      },
      image: 'https://iaraapp.sirv.com/iara/contos/mata-atlantica/iara-mico.png?w=600&h=600&format=webp'
    }
  ]
}
