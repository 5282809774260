const INITIAL_STATE = {
  url: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'change_player_video':
      return { ...state, url: action.payload }
    default:
      return state
  }
}
