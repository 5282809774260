
import styled from 'styled-components'
import { Fonts, Colors } from '../../styles'
import { Row } from 'react-grid-system'
import { Button, SecondaryButton } from '../../components'

export const Text = styled.p`
line-height: 25px;
  font-family: ${Fonts.regular};
  font-size: 20px;
  color: ${Colors.black};
  padding: 15px;
  text-align: left;
`

export const TextContainer = styled.div`
    margin: 0;
    margin-top: 20px;
    height: 100%;
    margin: 0px ${({ windowDimensions }) => windowDimensions.width <= 1024 ? '1px' : 'calc(20% + 15px)'};
    margin-bottom: 50px;
  `

// TODO change hardcoded color
export const AlternativeButton = styled(Button)`
  width: 100%;
  margin: 0;
  margin-bottom: 25px;
  background: ${({ isSelected }) => isSelected ? '#c9c9c9' : ''};
  font-size: ${({ windowDimensions }) => windowDimensions.width <= 1024 ? '30px' : ''};
  text-align: right;
  &:hover{
    transform: scale(1.02);
  }
  &:disabled {
    color: ${Colors.white};
    background: ${({ isWrongTried }) => isWrongTried ? Colors.darkRed : ''};
    background: ${({ shouldShowAsCorrectAlternative }) => shouldShowAsCorrectAlternative ? Colors.green : ''};
  }
`

export const ConfirmButton = styled(SecondaryButton)`
  width: 100%;
  margin: 0;
  margin-bottom: 25px;
  &:hover{
    transform: scale(1.02);
  }
`

// TODO rethink bg color
export const NextQuestionButton = styled(SecondaryButton)`
  width: 100%;
  margin: 0;
  margin-bottom: 25px;
  font-size: ${({ windowDimensions }) => windowDimensions.width <= 1024 ? '30px' : ''};


  background: ${Colors.purple};
  
  & > svg {
    position: absolute;
    margin-top: -3px;
    margin-left: 20px;
  }
  
  &:hover{
    transform: scale(1.02);
  }
  
  &:disabled {
    cursor: not-allowed;
    background: gray;
  }
  `

export const PreviousQuestionButton = styled(SecondaryButton)`
  width: 100%;
  margin: 0;
  margin-bottom: 25px;
  font-size: ${({ windowDimensions }) => windowDimensions.width <= 1024 ? '30px' : ''};
  background: ${Colors.lightPurple};

  & > svg {
    position: absolute;
    margin-top: -3px;
    margin-left: -40px;
  }

  &:hover{
    transform: scale(1.02);
  }

  &:disabled {
    cursor: not-allowed;
    background: gray;
  }
`

export const Label = styled.span`
  float: left;
  margin-right: 10px;
  color: ${Colors.blue};
  color: ${({ isWrongTried }) => isWrongTried ? Colors.white : ''};
  color: ${({ shouldShowAsCorrectAlternative }) => shouldShowAsCorrectAlternative ? Colors.white : ''};
`

export const ButtonContainer = styled(Row)`
  margin: 0 !important;
  padding: 0px ${props => props.width <= 1024 ? '1px' : '20%'};
`

export const AlternativeDescription = styled.div`
  text-align: center;
  transition: 0.5;
  font-family: ${Fonts.regular};
  & p {
    font-size: 20px;
    width: ${props => props.windowDimensions.width <= 1024 ? '100%' : '500px'};
    margin: 30px 0;
    padding: 20px;
  }
  margin: 25px;
  `
