import React, { useEffect, useState } from 'react'
import { Card, Header, OkPopup } from '../../components'
import { CardList } from './styles'
import data from '../../assets/data/biomes'
import { Col, setConfiguration, Row } from 'react-grid-system'
import { isQuizCompleted } from '../../services/quiz'
import { downloadFile } from '../../services/fileDownloader'
import { Animations } from '../../styles'
import { useHistory } from 'react-router-dom'
import { isGeneralQuizUnlocked } from '../../services/quizLock'
import quizDrawingFile from '../../assets/quiz/quiz-desenhos-para-colorir.pdf'
import { playAudio } from '../../services/audioPlayer'
import sounds from '../../assets/data/sounds'

export default function Biomes () {
  setConfiguration({ gutterWidth: 25 })
  const [popupAnimation, setPopupAnimation] = useState(false)
  const [popupData, setPopupData] = useState(false)
  const [isPopupVisible, setPopupVisible] = useState(false)
  const [taleLock, setTaleLock] = useState([])
  const [isGeneralQuizCompleted, setGeneralQuizCompleted] = useState(false)
  const [isQuizUnlocked, setGeneralQuizLock] = useState(false)
  const history = useHistory()

  useEffect(() => {
    async function fetchTalesLock () {
      const talesLockPerBiome = await Promise.all(data.map(biome => biome.id).map(isQuizCompleted))
      setTaleLock(talesLockPerBiome)
    }

    async function fetchGeneralQuizLock () {
      const generalQuizLock = await isGeneralQuizUnlocked()
      setGeneralQuizLock(generalQuizLock)
    }

    async function fetchGeneralQuizCompleted () {
      const generalQuizCompleted = await isQuizCompleted('final')
      setGeneralQuizCompleted(generalQuizCompleted)
    }

    fetchTalesLock()
    fetchGeneralQuizLock()
    fetchGeneralQuizCompleted()

    return () => {}
  }, [])

  function handleClickTale (biome, isUnlocked) {
    if (isUnlocked) return goToTale(biome)

    playAudio(sounds.locked)
    setPopupAnimation(Animations.sad)
    setPopupData({
      texts: [{
        libras: 'https://youtu.be/v4JGwvvP-Ek',
        text: 'Você deve completar o quiz ' + biome + ' antes de ler o conto!'
      }]
    })

    setPopupVisible(true)
  }

  function goToGeneralQuiz () {
    history.push('/quiz')
  }

  function handleGeneralQuizClick () {
    if (isQuizUnlocked) return goToGeneralQuiz()

    playAudio(sounds.locked)
    setPopupAnimation(Animations.sad)
    setPopupData({
      texts: [{
        libras: 'https://youtu.be/Nn_1gMWaGqY',
        text: 'Você deve completar todos os quizzes para ir ao quiz final!'
      }]
    })

    setPopupVisible(true)
  }

  function handleGeneralQuizDownloadClick () {
    if (isGeneralQuizCompleted) return downloadFile(quizDrawingFile)

    playAudio(sounds.locked)
    setPopupAnimation(Animations.sad)
    setPopupData({
      texts: [{
        libras: 'https://youtu.be/Ag_B1PelyAs',
        text: 'Você deve completar o quiz final para liberar o download do desenho!'
      }]
    })

    setPopupVisible(true)
  }

  function goToTale (biome) {
    history.push('/conto/' + biome)
  }

  function renderCard (biome, index) {
    const url = `/bioma/${biome.id}`
    return (
      <Col
        sm={12} md={6} lg={4}
        key={biome.id}
      >
        <Card
          taleable
          unlockedTale={taleLock[index]}
          taleButtonDescription={'Ler conto do bioma ' + biome.name}
          title={biome.name}
          thumb={biome.thumb}
          redirectButtonUrl={url}
          buttonDescription={'Conhecer ' + biome.name}
          taleOnClick={() => handleClickTale(biome.id, taleLock[index])}
        />
      </Col>
    )
  }

  function closePopup () {
    setPopupVisible(false)
  }

  return (
    <>
      <Header>Biomas</Header>
      <OkPopup
        onClick={() => closePopup()}
        okButtonDescription='Fechar janela'
        isPopupVisible={isPopupVisible}
        popupAnimation={popupAnimation}
        popupData={popupData}
      />
      <CardList fluid>
        <Row>
          <Col
            sm={12} md={6} lg={6}
            key={1}
          >
            <Card
              thumb={require('../../assets/others/livros-thumbnail.webp')}
              onClick={() => goToTale('inicial')}
              title='Conto inicial'
            />
          </Col>
          <Col
            sm={12} md={6} lg={6}
            key={2}
          >
            <Card
              thumb={require('../../assets/others/bandeira-brasil-thumbnail.webp')}
              redirectButtonUrl='/info/o-que-e-um-bioma'
              title='O que é um bioma?'
            />
          </Col>
        </Row>
        <Row>
          {data.map((biome, index) => renderCard(biome, index))}
        </Row>
        <Row>
          <Col
            sm={12} md={4} lg={4}
            key={3}
          >
            <Card
              thumb={require('../../assets/others/preservacao-thumbnail.webp')}
              redirectButtonUrl='/info/preservacao'
              title='Preservação'
            />
          </Col>
          <Col
            sm={12} md={4} lg={4}
            key={4}
          >
            <Card
              isGeneralQuizCard
              unlockedDownload={isGeneralQuizCompleted}
              downloadButtonDescription='Baixar desenhos para colorir'
              thumb={require('../../assets/quiz/quiz-card-thumbnail.webp')}
              onClick={() => handleGeneralQuizClick()}
              downloadOnClick={() => handleGeneralQuizDownloadClick()}
              title='Quiz Final'
            />
          </Col>
          <Col
            sm={12} md={4} lg={4}
            key={5}
          >
            <Card
              thumb={require('../../assets/others/livros-thumbnail.webp')}
              onClick={() => handleClickTale('final', isGeneralQuizCompleted)}
              title='Conto final'
            />
          </Col>
        </Row>
      </CardList>
    </>
  )
}
