import biomes from '../assets/data/biomes'
import entities from '../assets/data/entities'

export function getBiomeById (biome) {
  return biomes.find(({ id }) => id === biome)
}

export function getEntitiesByType (type, biome) {
  if (!entities[biome]) return null
  return entities[biome][type]
}

export function getEntityById (id, type, biome) {
  if (!entities[biome]) return null
  if (!entities[biome][type]) return null
  return entities[biome][type].find(entity => entity.id === id)
}
