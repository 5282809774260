export default
{
  id: 'o-que-e-um-bioma',
  name: 'O que é um bioma?',
  nameLibras: 'https://youtu.be/V0hGqcG8dwo',
  thumb: require('../../others/bandeira-brasil-thumbnail.webp'),
  images: [{
    id: 'biomas-brasil',
    description: 'Mapa do Brasil dividido em seus seis biomas. O mapa do Brasil é como um triângulo invertido, com a parte superior mais larga e a inferior mais estreita. No alto à esquerda, em verde, está a Amazônia, o maior bioma brasileiro. Também no alto, à direita, na área que  corresponde ao interior do nordeste, em amarelo, a Caatinga, o quarto maior bioma em extensão. No centro, em laranja, o Cerrado, o segundo maior bioma do Brasil. À esquerda do Cerrado, abaixo da Amazônia, em azul escuro, está o Pantanal, o quinto maior bioma nacional. Margeando a borda lateral direita do mapa, na área que representa o litoral do Brasil, em azul claro, a Mata Atlântica, o terceiro maior bioma do país. Por fim, na parte inferior do mapa, em marrom claro, está o Pampa, o menor bioma brasileiro.',
    src: require('../../others/biomas-brasil.jpeg')
  }],
  content: [
    {
      libras: 'https://youtu.be/R0S9GAkB-10',
      text: 'Vamos entender o que é um bioma!'
    },
    {
      libras: 'https://youtu.be/_Ix6JN4n-pI',
      text: 'Um bioma é uma região grande que é definida por sua semelhança. Dentro dessa região as características físicas (como o tipo de solo e altitude), biológicas (vegetação e animais),  e climáticas (clima semiárido, tropical, umidade, entre outros) são as mesmas.'
    }
  ]
}
