// README> QUESTION IDS MUST BE SEQUENTIALS

export default {
  id: 'amazonia',
  questions: [
    {
      id: 1,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/0Y5xBX0IVu4',
      text: 'Sobre o bioma da Amazônia, escolha a alternativa correta:',
      correctAnswer: [3],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/DaVmr3MDK0w',
            text: 'Ter uma grande biodiversidade significa ter um grande número de espécies de animais e de plantas também.'
          },
          libras: 'https://youtu.be/Atj1_TdMDQ0',
          text: 'Ter uma grande biodiversidade significa ter apenas um grande número de espécies de animais'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/4IJVYVW9siM',
            text: 'Na Amazônia encontramos todos os tipos e tamanhos de plantas.'
          },
          libras: 'https://youtu.be/FR-1mtRQf1g',
          text: 'Na Amazônia só tem árvores muito grandes'
        },
        {
          id: 3,
          libras: 'https://youtu.be/UrTRmierN7I',
          text: 'Ter uma grande biodiversidade significa ter uma grande quantidade de animais e plantas'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/PdHqJWNSP_Y',
            text: 'A Amazônia é muito quente e úmida o ano inteiro.'
          },
          libras: 'https://youtu.be/8hsR0TZWR9s',
          text: 'A Amazônia é muito fria e seca o ano inteiro'
        }
      ]
    },
    {
      id: 2,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/r7nwn7PE0Ws',
      text: 'Qual desses animais do bioma da Amazônia moram no mar?',
      correctAnswer: [4],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/FhlBtOZiCJE',
            text: 'O boto-cor-de-rosa mora na água doce.'
          },
          libras: 'https://youtu.be/nzH7r1gDQ8M',
          text: 'Boto-cor-de-rosa'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/3r0ZOKRLZJI',
            text: 'A onça-pintada é um animal terrestre, e mora na mata.'
          },
          libras: 'https://youtu.be/s3pZp_oGaEU',
          text: 'Onça-pintada'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/hLTFeehTLaE',
            text: 'A piranha-vermelha mora na água doce.'
          },
          libras: 'https://youtu.be/qxgY4RGtwZE',
          text: 'Piranha-vermelha'
        },
        {
          id: 4,
          libras: 'https://youtu.be/YYmYZTXuYUM',
          text: 'Nenhum desses animais mora no mar'
        }
      ]
    },
    {
      id: 3,
      isMultipleAlternatives: false,
      libras: 'https://youtu.be/kiWYXummAd0',
      text: 'Sobre as plantas da Amazônia (vitória-régia e o açaizeiro), escolha a alternativa correta:',
      correctAnswer: [2],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/UAW2Ltvmok4',
            text: 'A vitória-régia é uma planta com formato circular e aguenta o peso de uma criança.'
          },
          libras: 'https://youtu.be/sx2WH-axCHM',
          text: 'A vitória-régia tem a forma quadrada e consegue aguentar o peso de uma onça adulta'
        },
        {
          id: 2,
          libras: 'https://youtu.be/UW5pp0wpLB4',
          text: 'A vitória-régia é uma planta aquática que flutua nos rios'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/voyDAg-8PvM',
            text: 'O açaizeiro é uma planta terrestre. A vitória-régia que é aquática, circular e com as bordas levantadas, parece uma bandeja flutuante de cor verde-escuro.'
          },
          libras: 'https://youtu.be/zZwlFDnSru4',
          text: 'O açaizeiro é aquático, circular e com as bordas levantadas, parece uma bandeja flutuante de cor verde-escuro'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/Q634BGPpv3g',
            text: 'O açaí, quando maduro, pode ser consumido, sendo fonte de nutrientes para muitas pessoas.'
          },
          libras: 'https://youtu.be/Q3wtYTxUMig',
          text: 'O açaí não serve de alimento para o ser humano'
        }
      ]
    }
  ]
}
