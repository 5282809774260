import React, { useState, useRef } from 'react'
import { BsFillPlayFill, BsFillPauseFill } from 'react-icons/bs'
import styled from 'styled-components'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { Colors } from '../styles'

export function AudioPlayer ({ audioSrc }) {
  const windowDimensions = useWindowDimensions()
  const audio = useRef(null)
  const [playingState, setPlayingState] = useState(false)

  function play () {
    audio.current.play()
    setPlayingState(true)
  }

  function pause () {
    audio.current.pause()
    setPlayingState(false)
  }

  return (
    <>
      <audio src={audioSrc} ref={audio} onEnded={() => setPlayingState(false)} />
      <IconWrapper windowDimensions={windowDimensions}>
        {!playingState && <BsFillPlayFill style={{ width: '60px', height: '60px' }} onClick={() => play()} />}
        {playingState && <BsFillPauseFill style={{ width: '60px', height: '60px' }} onClick={() => pause()} />}
      </IconWrapper>
    </>
  )
}

const IconWrapper = styled.div`
  color: ${Colors.darkBlue};
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition: 0.5s;
  border-radius: ${({ windowDimensions }) => windowDimensions.width > 1080 ? '30px' : '60px'};
  box-shadow: 0px 10px 20px -8px rgba(0,0,0,0.45);

  &:hover{
    opacity: 0.6;
    transform: scale(1.1) translateX(2%);
  }
`
