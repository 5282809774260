
import styled, { createGlobalStyle } from 'styled-components'
import { Fonts, Colors } from '../../styles'

export const Title = styled.h1`
  text-align: left;
  font-size: 40px;
  padding: 15px 20px;
  margin: 0px 10px;
  color: ${Colors.darkBlue};
  font-family: ${Fonts.cool};
  margin-bottom: 0px;
`

export const TitleDesktop = styled(Title)`
  text-align: center;
  max-width: 100%;
  overflow-wrap: break-all; 
  font-size: 70px;
  margin: 0 70px;
`

export const SubTitle = styled(Title)`
  text-align: center;
  margin: ${({ windowDimensions }) => windowDimensions.width > 1080 ? '0 70px' : '0 30px'};;
  margin-bottom: -20px;
  font-size: ${({ windowDimensions }) => windowDimensions.width > 1080 ? '40px' : '32px'};
`

export const EntityImage = styled.img`
  min-height: ${window.innerHeight <= 1000 ? (window.innerHeight - 300) + 'px' : '40vh'};
  max-height: ${window.innerHeight <= 1000 ? (window.innerHeight - 300) + 'px' : '40vh'};
  object-fit: cover;
  min-width: 100%;
  max-width: 100%;
  cursor: pointer;
  text-align: center;
  align-self: center;
`

export const EntityImageDesktop = styled(EntityImage)`
  min-width: 50%;
  max-width: 50%;
  float: right;
  right: 0;
  min-height: 100%;
  top: 0px;
  position: fixed;
  transition: 0.5s;
  border-radius: 0;
  z-index: 100;
  &:hover{
    transform: scale(1.01);
  }
`

export const TextContainer = styled.div`
  width: 50%; 
  max-width: 50%;
  margin: 0;
  float: left;
  height: 100%;
`

export const ImageInfo = styled.p`
  padding: 10px;
  opacity: 0.6;
  font-size: 12px;
  font-family: ${Fonts.regular};
`

export const BtnStyle = createGlobalStyle`
  .rndBtn {
    font-size: 55px;
    height: 90px;
    width: 90px;
  }
`

export const Text = styled.p`
  line-height: 25px;
  font-family: ${Fonts.regular};
  font-size: 20px;
  color: ${Colors.darkBlue};
  text-align: left;
  padding: 15px;
  margin: 0px ${({ windowDimensions }) => windowDimensions.width > 1024 ? '70px' : '20px'};
  margin-bottom: 30px;
`

export const Content = styled.div`
  text-align: center;
  margin-top: ${({ headerTransparent }) => headerTransparent ? '-70px' : '0px'};
  overflow: hidden;
`

export const Spacer = styled.div`
  height: 60px;
`

export const AudioPlayerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`
