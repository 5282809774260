import AsyncStorage from '@callstack/async-storage'
import { getEntitiesByType } from './index'
import { getQuizzes } from './quiz'

export const LockStatus = {
  unlocked: 'unlocked',
  locked: 'locked',
  alreadyUnlocked: 'already unlocked'
}

const historyPrefix = (biome) => {
  if (!biome) throw new Error('Biome is required')
  return `read-history:${biome}`
}

export const quizPrefix = (biome) => {
  if (!biome) throw new Error('Biome is required')
  return `quiz:${biome}`
}

export function isUnlocked (biome) {
  return AsyncStorage.getItem(quizPrefix(biome))
    .then(cache => cache === LockStatus.unlocked)
}

export function getNumOfReadEntities (biome) {
  return AsyncStorage.getItem(historyPrefix(biome))
    .then(result =>
      JSON.parse(result).length
    )
}

export function getNumOfEntities (biome) {
  const plants = getEntitiesByType('plants', biome).length
  const animals = getEntitiesByType('animals', biome).length

  return plants + animals + 1
}

export async function unlockQuiz (biome) {
  if (await isUnlocked(biome)) {
    console.log(biome, 'quiz: already unlocked')
    return LockStatus.alreadyUnlocked
  }

  const numOfEntities = getNumOfEntities(biome)
  const numOfReadEntities = await getNumOfReadEntities(biome)

  const shouldNotUnlock = numOfReadEntities !== numOfEntities
  if (shouldNotUnlock) {
    console.log(biome, 'quiz: locked')
    console.log(biome, 'quiz: More', numOfEntities - numOfReadEntities, 'to go')
    return LockStatus.locked
  }

  AsyncStorage.setItem(quizPrefix(biome), LockStatus.unlocked)
  console.log(biome, 'quiz unlocked')
  return LockStatus.unlocked
}

function addToHistory (history, biome, entity) {
  const parsedHistory = JSON.parse(history)
  const historySet = parsedHistory ? new Set(parsedHistory) : new Set()
  historySet.add(entity)
  const historyList = Array.from(historySet)
  AsyncStorage.setItem(historyPrefix(biome), JSON.stringify(historyList))
}

export async function saveAsRead (biome, entity) {
  AsyncStorage.getItem(historyPrefix(biome)).then(history => addToHistory(history, biome, entity))

  return unlockQuiz(biome)
}

export async function isGeneralQuizUnlocked () {
  const locksPromise = Object.keys(getQuizzes())
    .slice(1)
    .map(isUnlocked)

  const locks = await Promise.all(locksPromise)
  return locks.every(lock => lock)
}
