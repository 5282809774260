// README> QUESTION IDS MUST BE SEQUENTIALS

export default {
  id: 'final',
  questions: [
    {
      id: 1,
      isMultipleAlternatives: false,
      text: 'Sobre os biomas da Caatinga e do Cerrado, escolha a alternativa correta:',
      correctAnswer: [2],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/EugL1BzpgMY',
            text: 'É a Caatinga que parece com o deserto.'
          },
          text: 'O Cerrado é parecido com um deserto'
        },
        {
          id: 2,
          text: 'O nome Caatinga significa "mata branca"'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/oU9Ee0Q5dxg',
            text: 'É na Caatinga que há muitas plantas com espinhos.'
          },
          text: 'Existem muitas plantas no Cerrado com espinhos, como o mandacaru'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/jnn82flgnY0',
            text: 'Os incêndios naturais ocorrem no Cerrado.'
          },
          text: 'Na Caatinga, ocorrem incêndios naturais que são importantes para o ciclo de vida das plantas'
        }
      ]
    },
    {
      id: 2,
      isMultipleAlternatives: false,
      text: 'Sobre os biomas da Amazônia e do Pantanal, escolha a alternativa correta:',
      correctAnswer: [3],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/2ue-eugevIA',
            text: 'O Pantanal fica na Região Centro-Oeste do Brasil.'
          },
          text: 'O Pantanal fica na Região Norte do Brasil'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/3wZDKCFZLzw',
            text: 'É a Amazônia que abriga a maior diversidade do planeta.'
          },
          text: 'O Pantanal abriga a maior biodiversidade do planeta'
        },
        {
          id: 3,
          text: 'A Amazônia possui clima tropical, ou seja, é muito quente e úmido o ano todo'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/Pr5nIzjX80c',
            text: 'Essas características são do bioma do Pantanal e não da Amazônia.'
          },
          text: 'A Amazônia fica alagada uma boa parte do ano e possui uma vegetação chamada Capão'
        }
      ]
    },
    {
      id: 3,
      isMultipleAlternatives: false,
      text: 'Sobre os biomas da Mata Atlântica e do Pampa, escolha a alternativa correta:',
      correctAnswer: [4],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/A45w8IJkpdU',
            text: 'É o Pampa que possui esse tipo de clima.'
          },
          text: 'A Mata Atlântica possui clima subtropical (verões quentes e invernos frios), e no inverno pode cair neve'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/OJA4UcD3Dc4',
            text: 'O nome Mata Atlântica que possui essa origem.'
          },
          text: 'O nome Pampa tem origem da mitologia grega e refere ao deus Atlas, filho dos mares'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/dMDRW4XZ9iw',
            text: 'O Pampa fica na Região Sul do Brasil e possui apenas uma região próxima do litoral.'
          },
          text: 'O Pampa é localizado em toda a região litorânea do Brasil'
        },
        {
          id: 4,
          text: 'O Pampa possui as quatro estações do ano bem definidas, com outono, inverno, primavera e verão'
        }
      ]
    },
    {
      id: 4,
      isMultipleAlternatives: false,
      text: 'Qual desses animais não é uma ave?',
      correctAnswer: [3],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/rUnAVAlOxag',
            text: 'A arara-azul é uma ave.'
          },
          text: 'Arara-azul'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/kx_b4lsOWm0',
            text: 'A seriema é uma ave.'
          },
          text: 'Seriema'
        },
        {
          id: 3,
          text: 'Tuco-tuco'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/i6PDoAsR2Q8',
            text: 'O tuiuiú é uma ave.'
          },
          text: 'Tuiuiú'
        }
      ]
    },
    {
      id: 5,
      isMultipleAlternatives: false,
      text: 'Qual desses animais é herbívoro, ou seja, só come folhas, frutos e sementes?',
      correctAnswer: [1],
      alternatives: [
        {
          id: 1,
          text: 'Veado-catingueiro'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/tdKR2euv7m0',
            text: 'Os botos-cor-de-rosa se alimentam de peixes.'
          },
          text: 'Boto-cor-de-rosa'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/h-O9_r42UBI',
            text: 'O jacaré se alimenta de peixes, aves e pequenos animais.'
          },
          text: 'Jacaré'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/JR_0OWIuev4',
            text: 'O lobo-guará come pequenos animais e frutos.'
          },
          text: 'Lobo-guará'
        }
      ]
    },
    {
      id: 6,
      isMultipleAlternatives: false,
      text: 'Qual dessas afirmativas está correta?',
      correctAnswer: [4],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/mDKB1zW9oLA',
            text: 'Nenhum desses animais cavam túneis. O tamanduá-bandeira e a anta moram no meio do mato ou da floresta.'
          },
          text: 'O tamanduá-bandeira e as antas cavam túneis na terra para se proteger dos predadores'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/-T2CQEDE-0Q',
            text: 'A onça-pintada mora em florestas preservadas e é encontrada principalmente na Amazônia. O tatu-peba é típico da Caatinga e vive no meio do mato, podendo cavar buracos na terra para fugir dos predadores.'
          },
          text: 'A onça-pintada e o tatu-peba são típicos do Pampa e gostam de viver longe das florestas'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/t9SMSkUU_6A',
            text: 'O mico-leão-dourado passa a maior parte do tempo na floresta, pendurado nas árvores para comer, brincar e descansar. Os tuco-tucos vivem na maioria das vezes em túneis que eles cavam na terra, e não em rios.'
          },
          text: 'O mico-leão-dourado e os tuco-tucos são bons nadadores e passam a maior parte do tempo em rios'
        },
        {
          id: 4,
          text: 'O sapo-cururu e o sapo-de-barriga-vermelha preferem os ambientes com sombra e umidade'
        }
      ]
    },
    {
      id: 7,
      isMultipleAlternatives: false,
      text: 'Qual das plantas abaixo não faz parte da alimentação do ser humano?',
      correctAnswer: [1],
      alternatives: [
        {
          id: 1,
          text: 'Piúva'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/IkXGs9ii5ho',
            text: 'O caju faz parte da alimentação do ser humano.'
          },
          text: 'Caju'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/qJuX_YuzSDM',
            text: 'A jabuticaba faz parte da alimentação do ser humano.'
          },
          text: 'Jabuticaba'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/2b5IDW2ZkDI',
            text: 'O açaí faz parte da alimentação do ser humano.'
          },
          text: 'Açaí'
        }
      ]
    },
    {
      id: 8,
      isMultipleAlternatives: false,
      text: 'Qual dessas plantas é símbolo da Amazônia?',
      correctAnswer: [4],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/3dflpU-RfQc',
            text: 'A palmeira acuri é mais comum no Pantanal e não na Amazônia.'
          },
          text: 'Palmeira acuri'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/8Vx-HybQkGk',
            text: 'O algarrobo é mais comum no Pampa e não na Amazônia.'
          },
          text: 'Algarrobo'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/7DDKni-L6fY',
            text: 'O açaizeiro é comum na Amazônia, mas não é considerado o símbolo dela.'
          },
          text: 'Açaizeiro'
        },
        {
          id: 4,
          text: 'Vitória-régia'
        }
      ]
    },
    {
      id: 9,
      isMultipleAlternatives: false,
      text: 'Qual dessas alternativas está correta?',
      correctAnswer: [3],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/3iarXrVKOFI',
            text: 'A barba-de-bode é da família das gramas e não possui flores nem frutos.'
          },
          text: 'A barba-de-bode é uma árvore alta com muitos frutos vermelhos'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/udjPXvRtIZc',
            text: 'O mandacaru é típico da Caatinga e é adaptado ao ambiente seco.'
          },
          text: 'O mandacaru é uma planta adaptada aos ambientes alagados'
        },
        {
          id: 3,
          text: 'No início da primavera as folhas do ipê-amarelo caem e só ficam as flores na árvore'
        },
        {
          id: 4,
          reason: {
            libras: 'https://youtu.be/hPNTJaMTX5o',
            text: 'O acuri faz parte da alimentação da arara-azul e não do lobo-guará.'
          },
          text: 'Somente o lobo-guará consegue se alimentar do fruto do acuri'
        }
      ]
    },
    {
      id: 10,
      isMultipleAlternatives: false,
      text: 'Qual alternativa abaixo é falsa?',
      correctAnswer: [4],
      alternatives: [
        {
          id: 1,
          reason: {
            libras: 'https://youtu.be/ViuMjXunpLs',
            text: 'O desmatamento prejudica muito o meio ambiente, e todos os anos leva muitos animais e plantas à extinção.'
          },
          text: 'O desmatamento prejudica o meio ambiente, podendo levar animais e plantas à extinção'
        },
        {
          id: 2,
          reason: {
            libras: 'https://youtu.be/Jphx-pYVZws',
            text: 'Reciclar é muito importante. Fazendo isso, o local em que vivemos fica mais limpo.'
          },
          text: 'Reciclar é importante para diminuir a quantidade de lixo que produzimos'
        },
        {
          id: 3,
          reason: {
            libras: 'https://youtu.be/21bUiMRFdyQ',
            text: '[Não devemos nunca jogar lixo no chão. Podemos guardar o lixo até acharmos uma lixeira mais próxima.'
          },
          text: 'Não devemos jogar lixo no chão para não poluir o meio ambiente'
        },
        {
          id: 4,
          text: 'Não precisamos mudar as nossas atitudes, pois a natureza não é prejudicada pelo ser humano'
        }
      ]
    }
  ]
}
