import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Header, RoundedButton, LibrasAccessible } from '../../components'
import { connect } from 'react-redux'
import Question from './question'
import { ButtonContainer } from './styles'
import { Col } from 'react-grid-system'
import { TiChevronLeft, TiChevronRight } from 'react-icons/ti'
import { getQuestionById, getQuizById } from '../../services/quiz'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { isGeneralQuizUnlocked, isUnlocked } from '../../services/quizLock'

function Quiz (props) {
  const params = useParams()
  const isQuizGeneral = params.quizId === undefined
  const quizId = isQuizGeneral ? 'final' : params.quizId.toString()
  const windowDimensions = useWindowDimensions()
  const history = useHistory()
  const [quizData, setQuizData] = useState(null)

  const [currentQuestion, setCurrentQuestion] = useState(null)

  useEffect(() => {
    isUnlocked(quizId)
      .then(isUnlocked => {
        if (isQuizGeneral) {
          isGeneralQuizUnlocked().then(isUnlocked => {
            if (!isUnlocked) {
              window.location = '/'
            }
          })
        }

        if (!isUnlocked && !isQuizGeneral) {
          window.location = '/'
          return
        }
        try {
          const data = getQuizById(quizId)
          setQuizData(data)

          const defaultQuestion = getQuestionById(data, 1)
          setCurrentQuestion(defaultQuestion)
        } catch (e) {
          console.error(e)
          window.location = '/'
        }
      })
  }, [])

  // Wait to useEffect complete to continue rendering
  if (!quizData || !currentQuestion) {
    return (<></>)
  }

  const minQuestion = 1
  const maxQuestion = quizData.questions.length

  function goToNextQuestion () {
    if (!(currentQuestion.id + 1 > maxQuestion)) {
      const nextQuestion = getQuestionById(quizData, currentQuestion.id + 1)
      setCurrentQuestion(nextQuestion)
    }
  }

  function goToPreviousQuestion () {
    if (!(currentQuestion.id - 1 < minQuestion)) {
      const previousQuestion = getQuestionById(quizData, currentQuestion.id - 1)
      setCurrentQuestion(previousQuestion)
    }
  }

  const buttonTypeEnum = {
    previous: 0, next: 1
  }

  function isButtonDisabled (type) {
    if (type === buttonTypeEnum.previous) {
      return currentQuestion.id === minQuestion
    }
    if (type === buttonTypeEnum.next) {
      return currentQuestion.id === maxQuestion
    }
  }

  function goBackToEntityPath () {
    try {
      const currentPath = history.location.pathname
      const newPath = currentPath.split('/')

      if (newPath[1] !== 'quiz' && newPath[2] !== null) {
        history.replace('/')
      }
      newPath[1] = 'bioma'
      history.replace(newPath.join('/'))
    } catch (e) {
      console.log(e)
      history.replace('/')
    }
  }

  function renderNextPreviousQuestionButton () {
    return (
      <>
        <ButtonContainer width={windowDimensions.width}>
          <Col xs={6} style={{ textAlign: 'right', margin: 0 }}>
            <RoundedButton
              disabled={isButtonDisabled(buttonTypeEnum.previous)}
              onClick={() => goToPreviousQuestion()}
              description='Ir para questão anterior'
            >
              <TiChevronLeft />
            </RoundedButton>
          </Col>
          <Col xs={6} style={{ textAlign: 'left', margin: 0 }}>
            <RoundedButton
              disabled={isButtonDisabled(buttonTypeEnum.next)}
              onClick={() => goToNextQuestion()}
              description='Ir para próxima questão'
            >
              <TiChevronRight />
            </RoundedButton>
          </Col>
        </ButtonContainer>

      </>
    )
  }

  return (
    <>
      <Header onClick={() => goBackToEntityPath()}>Quiz</Header>
      <LibrasAccessible />
      <Question
        quizId={quizData.id}
        correctAnswer={currentQuestion.correctAnswer}
        isMultipleAlternatives={currentQuestion.isMultipleAlternatives}
        question={currentQuestion}
        onCorrectAnswerGoToNextQuestion={() => goToNextQuestion()}
      />
      {renderNextPreviousQuestionButton()}
    </>
  )
}

const mapStateToProps = state => ({
  accessibility: state.AccessibilityReducer,
  librasPlayerProps: state.LibrasPlayerReducer
})

export default connect(mapStateToProps, null)(Quiz)
